/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import plusIco from "../../Assets/Images/icons/+.png";
import plusIcon from "../../Assets/Images/icons/Icon feather-plus.png";
import plusIconClose from "../../Assets/Images/icons/Icon feather-plus.png";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import API from "../../Api/Api";
// import io from "socket.io-client";
import baseApi from "../../Api/config";
import moment from "moment";
import { formatDateTime } from "../Common/Functions";
import AdminRoute from "../../Route/RouteDetails";
// import userImage from "../../Assets/Images/userimg.png";
import UserImage from "../../Assets/Images/icons/Icon awesome-user-circle.png";
import {
  get15MinutesBefore,
  get1HourFuture,
  combineDateTime,
} from "../Common/Functions";
import Modal from "react-modal";
import Dropdown from "react-bootstrap/Dropdown";
import config from "../../agora-manager/config";
import { socket } from "../../socket";
import infoIcon from "../../Assets/Images/SVG ICON/community_icon.svg";
import DefaultImage from "../../Assets/Images/icons/Icon awesome-user-circle.png";
import deleteIcon from "../../Assets/Images/SVG ICON/delete.svg";
import Swal from "sweetalert2";

// const socket = io(process.env.REACT_APP_BASE_URL_SOCKET, {
//   path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
// });

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "95%",
    maxHeight: "100%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgb(211,205,197, 50%)",
    borderRadius: "20px",
    paddingTop: "70px",
    width: "90%",
    maxWidth: "500px",
    border: "none",
    overflowX: "hidden",
    backdropFilter: "blur(10px)",
  },
};

const TimeSlotS = [
  "Start Time",
  "12:00 AM",
  "01:00 AM",
  "02:00 AM",
  "03:00 AM",
  "04:00 AM",
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM",
];

const TimeSlotE = [
  "12:00 AM",
  "01:00 AM",
  "02:00 AM",
  "03:00 AM",
  "04:00 AM",
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM",
];

const Groupchat = () => {
  // const location = useLocation();
  // const socket =
  //   location.pathname.includes("Groupchat") &&
  //   io(process.env.REACT_APP_BASE_URL_SOCKET, {
  //     path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
  //   });
  const [room] = useState("chatmessage");
  const [currMessage, setCurrMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [rows, setRows] = useState(1);
  const [allResourceData, setAllResourceData] = useState([]);
  const [allActivityData, setAllActivityData] = useState([]);
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const [loadingSubmitAns, setLoadingSubmitAns] = useState(false);
  const navigate = useNavigate();
  const [GimageName, setGimageName] = useState("");
  const [GroupId, setGroupId] = useState("");
  const [OrganizationID, setOrganizationID] = useState("");
  const chatHistoryRef = useRef(null);

  // ****** Org list ****************

  const [MyorgList, setMyorgList] = useState([]);
  const [LoadingMyorgList, setLoadingMyorgList] = useState(false);
  // const [OrgId, setOrgId] = useState("");
  // const [OrgName, setOrgName] = useState("");
  const [orgDetails, setorgDetails] = useState();

  const GetOrgData = (e) => {
    // console.log("======>", e.target.id);
    // console.log("======> ", e.target);
    setorgDetails(JSON.parse(e.target.value));
    // setOrgId(value);
    // setOrgName(value?.org_name);
  };

  // useEffect(() => {
  //   console.log("selectedOption", selectedOption);
  // }, [selectedOption]);

  useEffect(() => {
    MyorgListdata();
  }, []);

  const MyorgListdata = () => {
    setLoadingMyorgList(true);
    try {
      API.Auth.MyOrg().then((response) => {
        if (response.data.status === 1) {
          // console.log("myorg", response?.data?.data_all);
          setMyorgList(response?.data?.data_all);
        }
        setLoadingMyorgList(false);
      });
    } catch (error) {
      setLoadingMyorgList(false);
      console.log(error);
    }
  };

  //   ************** 6 march code ********************************************
  const [modalIsOpenCreateGroup, setIsOpenCreateGroup] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");
  const [CheckGroupType, setCheckGroupType] = useState("");
  const [is_loading, setis_loading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [loadingGroupList, setloadingGroupList] = useState(true);
  const [tab, setTab] = useState("Focused");
  const [tab2, setTab2] = useState("home");
  //  ***** Initial Functions ****

  function openModalCreateGroup() {
    setIsOpenCreateGroup(true);
  }

  function afterOpenModalCreateGroup() {
    setSpinner(false);
    setProfileImage("");
    // references are now sync'd and can be accessed.
  }

  function closeModalCreateGroup() {
    setIsOpenCreateGroup(false);
  }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [createPollmodalIsOpen, setPollModel] = useState(false);
  const [createQuizmodalIsOpen, setQuizModel] = useState(false);

  function openModal() {
    // setMenuVisible(false);
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeModal() {
    setIsOpen(false);
  }

  function openPollModal() {
    // setMenuVisible(false);
    setPollModel(true);
  }
  function afterPollOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closePollModal() {
    setPollModel(false);
  }

  function openQuizModal() {
    // setMenuVisible(false);
    setQuizModel(true);
  }
  function afterQuizOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeQuizModal() {
    setQuizModel(false);
  }

  const handleSelect = (key) => {
    if (key === "Focussed") {
      getGroupList("Focused");
      setTab(key);
    }
    if (key === "Support") {
      getGroupList("Support");
      setTab(key);
    }
  };

  const handleSelectGroupDetails = (key) => {
    if (key === "home") {
      getGropAllChats();
      setTab2(key);
    }
    if (key === "activity") {
      getAllEventData();
      setTab2(key);
    }
    if (key === "activity") {
      getGropuMedia();
      setTab2(key);
    }
  };

  // ========== Create Group Modal =========

  const initialValues = {
    GName: "",
    GDescription: "",
    GTags: "",
    GMembers: "",
    GStartDate: "",
    GEndDate: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [FocusList, setFocusList] = useState([]);
  const [SupportList, setSupportList] = useState([]);


  const handleUpload = (e) => {
    // console.log(e.target.files[0]);
    setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setSpinner(false)
        );
    } catch (err) {
      console.log(err.res);
      setSpinner(false);
    }
  };

  const addImage = (image) => {
    setProfileImage(`${baseApi.baseurl}images/${image}`);
    setGimageName(image);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const Validate = (values, img) => {
    // console.log("===> values", values);
    // console.log("===> CheckGroupType", CheckGroupType);
    const errors = {};
    if (!img) {
      errors.img = "Group image is required !";
    }
    if (!values?.GName) {
      errors.GName = "Name is required !";
    }
    if (!values?.GDescription) {
      errors.GDescription = "Description is required !";
    }
    if (!values?.GTags) {
      errors.GTags = "Tag is required !";
    }
    if (!values?.GMembers && CheckGroupType === "focused") {
      errors.GMembers = "No Of Members required !";
    }
    if (!values?.GStartDate && CheckGroupType === "focused") {
      errors.GStartDate = "Start Date is required !";
    }
    if (!values?.GEndDate && CheckGroupType === "focused") {
      errors.GEndDate = "End Date is required !";
    }
    if (!values?.Group) {
      errors.Group = "Group Type is required !";
    }
    return errors;
  };



  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log("formData", formData);

      // console.log("=========>", {
      //   name: formData?.GName,
      //   type: formData?.Group,
      //   logo: GimageName,
      //   description: formData?.GDescription,
      //   members: formData?.GMembers,
      //   startdate: formData?.GStartDate,
      //   enddate: formData?.GEndDate,
      //   created_by: UserData?.userdata?._id,
      //   org_id: OrgId,
      // });

      // console.log("OrgId", orgDetails?._id);

      setLoading(true);
      try {
        API.Group.CreateGroup({
          data: {
            name: formData?.GName,
            type: formData?.Group,
            logo: GimageName,
            description: formData?.GDescription,
            members: formData?.GMembers,
            startdate: formData?.GStartDate,
            enddate: formData?.GEndDate,
            created_by: UserData?.userdata?._id,
            org_id: orgDetails?._id,
          },
        }).then((response) => {
          // console.log("====>", response?.data?.data?.type);
          if (response?.data?.status === 1) {
            if (response?.data?.data?.type === "support") {
              getGroupList("Support");
              SupportGroupListFun();
              setIsOpenCreateGroup(false);
              navigate(
                `../${AdminRoute?.Common?.MembersSelection}/${response?.data?.data?._id
                }/${btoa(orgDetails?.org_name)}`
              );
            }
            if (response?.data?.data?.type === "focused") {
              getGroupList("Focused");
              FocusGroupListFun();
              setIsOpenCreateGroup(false);
              navigate(
                `../${AdminRoute?.Common?.MembersSelection}/${response?.data?.data?._id
                }/${btoa(orgDetails?.org_name)}`
              );
            }
          }
          closeModalCreateGroup();
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
        // toast.error(error.message);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(Validate(formData, ProfileImage));
    setIsSubmit(true);
  };

  const getGroupList = (GroupType) => {
    if (GroupType === "Focused") {
      // setActiveTab(0);
      setloadingGroupList(true);
      try {
        API.Group.GroupList({
          Grouptype: "focused",
        }).then((response) => {
          console.log("console", response?.data?.data_all);
          // setGroupList(response?.data?.data_all);
          setloadingGroupList(false);
        });
      } catch (error) {
        console.log(error);
        setloadingGroupList(false);
      }
    }
    if (GroupType === "Support") {
      // setActiveTab(1);
      setloadingGroupList(true);
      try {
        API.Group.GroupList({
          Grouptype: "support",
        }).then((response) => {
          // console.log("console", response?.data?.data_all);
          // setGroupList(response?.data?.data_all);
          setloadingGroupList(false);
        });
      } catch (error) {
        console.log(error);
        setLoadingList(false);
      }
    }
  };

  const FocusGroupListFun = () => {
    setloadingGroupList(true);
    try {
      API.Group.GroupList({
        Grouptype: "focused",
      }).then((response) => {
        // console.log("setFocusList", response?.data?.data_all);
        setFocusList(response?.data?.data_all);
        setloadingGroupList(false);
      });
    } catch (error) {
      console.log(error);
      setloadingGroupList(false);
    }
  };



  const SupportGroupListFun = () => {
    setloadingGroupList(true);
    try {
      API.Group.GroupList({
        Grouptype: "support",
      }).then((response) => {
        // console.log("setSupportList", response?.data?.data_all);

        setSupportList(response?.data?.data_all);
        setloadingGroupList(false);
      });
    } catch (error) {
      setloadingGroupList(false);
      console.log(error);
    }
  };

  useEffect(() => {
    FocusGroupListFun();
    SupportGroupListFun();
  }, []);

  //   ************** 6 march code end ********************************************

  const getGroup_info = (e, val) => {
    // console.log("=======> Group info", val);
    setMessageList([]);
    setSelectedGroup(val);
    HandleMsgCount(val);
    getGropAllChats(val);
    getAllEventData(val);
    getGropuMedia(val);
    setGroupId(val?._id);
    setOrganizationID(val?.org_id);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      setRows(1);
      sendMessage(e);
      e.preventDefault();
    } else if (e.key === "Enter" && e.shiftKey) {
      if (rows < 6) {
        setRows(rows + 1);
      }
      const cursorPosition = e.target.selectionStart;
      const newInputValue =
        currMessage.substring(0, cursorPosition) +
        "\n" +
        currMessage.substring(cursorPosition);
      setCurrMessage(newInputValue);
      e.preventDefault();
    }
  };

  const HandleMsgCount = (val) => {
    API.Group.Groupcount({
      data: {
        groupid: val?._id && val?._id,
        count: val?.msgunread ? val?.msgunread : 0,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          setLoadingSubmitAns(false);
          getAllEventData(val?.group[0]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingSubmitAns(false);
      });
  };

  const getGropAllChats = (val) => {
    setLoadingList(true);
    API.Group.GroupsChatList({
      userId: UserData?.userdata?._id,
      group_id: val === undefined ? GroupId : val?._id,
    })
      .then((response) => {
        if (response?.data?.status === 1) {
          // console.log("Message list===>", response?.data?.data_all);
          setMessageList(response?.data?.data_all);
          setLoadingList(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingList(false);
      });
  };

  const getAllEventData = (val) => {
    // console.log("====> Group Id", GroupId);
    try {
      setLoadingList(true);
      API.Group.EventList({
        group_id: val === undefined ? GroupId : val?._id,
      }).then((response) => {
        // console.log("setAllActivityData", response.data);
        if (response.data.status === 1) {
          setAllActivityData(response?.data?.data_all);
        }
        setLoadingList(false);
      });
    } catch (error) {
      setLoadingList(false);
    }
  };

  const getGropuMedia = (val) => {
    setLoadingList(true);
    try {
      API.Group.Medialist({
        group_id: val === undefined ? GroupId : val?._id,
      }).then((response) => {
        if (response?.data?.status === 1) {
          setAllResourceData(response?.data?.data_all);
        }
        setLoadingList(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingList(false);
    }
  };

  //socket .io
  const sendMessage = async () => {
    setLoading(true);
    if (currMessage !== "") {
      const messageData = {
        room_name: room,
        author: UserData?.userdata?.fullname,
        user_id: UserData?.userdata?._id,
        group_id: selectedGroup._id,
        message: currMessage,
        time: new Date(),
        // time:
        //   new Date(Date.now()).getHours() +
        //   ":" +
        //   new Date(Date.now()).getMinutes(),
      };
      await socket.emit(room, messageData);
    }
    setCurrMessage("");
    setLoading(false);
  };

  // useEffect(() => {
  //   socket.on(room, (data) => {
  //     if (selectedGroup?._id === data?.group_id) {
  //       setMessageList((list) => [...list, data]);
  //     }
  //   });
  //   return () => {
  //     // socket.removeListener(room);
  //     socket.off(room); // Remove the listener when the component unmounts or the effect is re-run
  //   };
  // }, [socket, selectedGroup]);

  useEffect(() => {
    if (!socket || !room) return;
    socket.connect();

    const handleData = (data) => {
      if (selectedGroup && selectedGroup._id === data.group_id) {
        setMessageList((list) => [...list, data]);
      }
    };

    socket.on(room, handleData);

    return () => {
      socket.off(room, handleData); // Specify the same handler to properly remove it
      socket.disconnect();
    };
  }, [socket, room, selectedGroup]);



  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messageList]); // Trigger effect when messageList changes

  // **************** Create Event ***************

  const initialValuesEvent = {
    EAskQuestion: "",
    EDescription: "",
    ESTime: "",
    EETime: "",
    ESDate: "",
    // EEDate: "",
  };

  const [formDataEvent, setFormDataEvent] = useState(initialValuesEvent);
  const [formEventErrors, setFormEventErrors] = useState({});
  const [isEventSubmit, setIsEventSubmit] = useState(false);

  const handleChangeEvent = (e) => {
    const { name, value } = e.target;
    setFormDataEvent({ ...formDataEvent, [name]: value });
  };

  const EventValidate = (values) => {
    // console.log("===> ok", values);
    const errors = {};

    if (!values?.EAskQuestion) {
      errors.EAskQuestion = "Question is required";
    }
    if (!values?.EDescription) {
      errors.EDescription = "Description is required";
    }
    if (!values?.ESTime) {
      errors.ESTime = "Start Time is required";
    }
    if (!values?.EETime) {
      errors.EETime = "End Time is required";
    }
    if (!values?.ESDate) {
      errors.ESDate = "Start Date is required";
    }
    // if (!values?.EEDate) {
    //   errors.EEDate = "End Date is required";
    // }

    return errors;
  };

  useEffect(() => {
    if (Object.keys(formEventErrors).length === 0 && isEventSubmit) {
      setis_loading(true);
      try {
        API.Group.Event.CreateEvent({
          data: {
            question: formDataEvent?.EAskQuestion,
            description: formDataEvent?.EDescription,
            startTime: formDataEvent?.ESTime,
            endTime: formDataEvent?.EETime,
            startDate: formDataEvent?.ESDate,
            endDate: formDataEvent?.ESDate,
            created_by: UserData?.userdata?._id,
            group_id: GroupId,
            org_id: OrganizationID,
            type: "Event",
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            closeModal();
            // EventListFun();
            getAllEventData();
          }
          setis_loading(false);
        });
      } catch (error) {
        console.log(error);
        setis_loading(false);
      }
      // console.log("formDataEvent", formDataEvent);
    }
  }, [formEventErrors]);

  const handleSubmitEvent = (e) => {
    e.preventDefault();
    setFormEventErrors(EventValidate(formDataEvent));
    setIsEventSubmit(true);
  };

  // *************** Create Event End here ***************

  // *************** Create Poll ***************

  const [is_loadingPoll, setis_loadingPoll] = useState(false);

  const initialValuesPoll = {
    PollQuestion: "",
    PollOption1: "",
    PollOption2: "",
    PollOption3: "",
  };

  const [formDataPoll, setFormDataPoll] = useState(initialValuesPoll);
  const [formPollErrors, setFormPollErrors] = useState({});
  const [isPollSubmit, setIsPollSubmit] = useState(false);

  const handleChangePoll = (e) => {
    const { name, value } = e.target;
    setFormDataPoll({ ...formDataPoll, [name]: value });
  };

  const PollValidate = (values) => {
    // console.log("===> Poll values", values);
    const pollerrors = {};

    if (!values?.PollQuestion) {
      pollerrors.PollQuestion = "PollQuestion is required!";
    }
    if (!values?.PollOption1) {
      pollerrors.PollOption1 = "PollOption 1 is required!";
    }
    if (!values?.PollOption2) {
      pollerrors.PollOption2 = "PollOption 2 is required!";
    }
    if (!values?.PollOption3) {
      pollerrors.PollOption3 = "PollOption 3 is required!";
    }

    return pollerrors;
  };

  useEffect(() => {
    if (Object.keys(formPollErrors).length === 0 && isPollSubmit) {
      setis_loadingPoll(true);
      try {
        API.Group.Event.CreateEvent({
          data: {
            question: formDataPoll?.PollQuestion,
            option1: formDataPoll?.PollOption1,
            option2: formDataPoll?.PollOption2,
            option3: formDataPoll?.PollOption3,
            created_by: UserData?.userdata?._id,
            group_id: GroupId,
            org_id: OrganizationID,
            type: "Poll",
          },
        }).then((response) => {
          // console.log("Poll", response?.data);
          if (response?.data?.status === 1) {
            getAllEventData();
            closePollModal();
          }
          setis_loadingPoll(false);
        });
      } catch (error) {
        console.log(error);
        setis_loadingPoll(false);
      }
    }
  }, [formPollErrors]);

  const handleSubmitPoll = (e) => {
    e.preventDefault();
    setFormPollErrors(PollValidate(formDataPoll));
    setIsPollSubmit(true);
  };

  // ************* Create Poll Ends **********************

  //  *********** Create Quiz *****************

  const initialValuesQuiz = {
    QuizQuestion: "",
    QuizOption1: "",
    QuizOption2: "",
    QuizOption3: "",
    QuizAnswer: "",
  };

  const [formDataQuiz, setFormDataQuiz] = useState(initialValuesQuiz);
  const [formQuizErrors, setFormQuizErrors] = useState({});
  const [isQuizSubmit, setIsQuizSubmit] = useState(false);

  const handleChangeQuiz = (e) => {
    const { name, value } = e.target;
    setFormDataQuiz({ ...formDataQuiz, [name]: value });
  };

  const QuizValidate = (values) => {
    // console.log("===>  values", values);

    const Quizerrors = {};

    if (!values?.QuizQuestion) {
      Quizerrors.QuizQuestion = "Quiz Question is required!";
    }
    if (!values?.QuizOption1) {
      Quizerrors.QuizOption1 = "Quiz Option1 is required!";
    }
    if (!values?.QuizOption2) {
      Quizerrors.QuizOption2 = "Quiz Option2 is required!";
    }
    if (!values?.QuizOption3) {
      Quizerrors.QuizOption3 = "Quiz Option3 is required!";
    }

    // =====================================

    if (!values?.QuizAnswer) {
      Quizerrors.QuizAnswer = "Quiz Answer is required!";
    } else if (
      values?.QuizOption1 !== values?.QuizAnswer &&
      values?.QuizOption2 !== values?.QuizAnswer &&
      values?.QuizOption3 !== values?.QuizAnswer
    ) {
      Quizerrors.QuizAnswer = "Answer should be match with options!";
    }

    return Quizerrors;
  };

  useEffect(() => {
    if (Object.keys(formQuizErrors).length === 0 && isQuizSubmit) {
      setis_loading(true);
      try {
        API.Group.Event.CreateEvent({
          data: {
            question: formDataQuiz?.QuizQuestion,
            option1: formDataQuiz?.QuizOption1,
            option2: formDataQuiz?.QuizOption2,
            option3: formDataQuiz?.QuizOption3,
            answer: formDataQuiz?.QuizAnswer,
            created_by: UserData?.userdata?._id,
            group_id: GroupId,
            org_id: OrganizationID,
            type: "Quiz",
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            closeQuizModal();
            // EventListFun();
            getAllEventData();
          }
          setis_loading(false);
        });
      } catch (error) {
        console.log(error);
        setis_loading(false);
      }
      // console.log("formDataQuiz", formDataQuiz);
    }
  }, [formQuizErrors]);

  const handleSubmitQuiz = (e) => {
    e.preventDefault();
    setFormQuizErrors(QuizValidate(formDataQuiz));
    setIsQuizSubmit(true);
  };

  // *********************** Create Quiz Ends ******************

  // ************** Create Resourses *****************************
  const handleUploadResourses = async (e) => {
    try {
      var formdata = new FormData();
      await formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => CreateMediafun(JSON.parse(result).data)
          // setSpinner(false)
        );
    } catch (err) {
      console.log(err.res);
      // setSpinner(false);
    }
  };
  const CreateMediafun = async (media) => {
    // console.log("Create media function", media);
    // console.log("type", mediaType);
    console.log("media", media?.filename);
    // console.log(media?.filename.split(".").pop());
    let mediaType = media?.filename.split(".").pop();

    try {
      await API.Group.Event.CreateMedia({
        data: {
          mediafile: media?.filename,
          type: mediaType === "pdf" ? "pdf" : "mediafile",
          group_id: GroupId,
          originalfilename: media?.originalname,
        },
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          // MediaListFun();
          getGropuMedia();
          ToggleF();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ================================================

  // **************** Video call **************************

  const renderActionButton = (SessionId, meetingtoken, mettingname) => {
    // return joined ? (
    //     <button onClick={handleLeaveClick}>Leave</button>
    // ) : (
    //     <button onClick={handleJoinClick}>Join</button>
    // );
    console.log(meetingtoken);
    console.log(mettingname);
    console.log(SessionId);
    try {
      API.JoinSession.Joinmeeting({
        data: {
          role: "Therapist",
          meetingId: mettingname,
        },
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          // navigate(
          //   `../${AdminRoute.Common.VideoCall}/${SessionId}?id=${btoa(
          //     meetingtoken
          //   )}&name=${mettingname}`
          // );

          const videoCallUrl = `../#${AdminRoute.Common.VideoCall}/${SessionId}?id=${btoa(
            meetingtoken
          )}&name=${mettingname}`;
          window.open(videoCallUrl, '_blank');
        }
      });
    } catch (error) {
      console.log(error);
    }

    // -------------------------------------------------------------------------
  };
  config.selectedProduct = "rtc";

  // ******************************************************

  const [StateToggle, setStateToggle] = useState(false);

  const ToggleF = () => {
    setStateToggle(!StateToggle);
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");

    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  //members info dropdown
  const [infoDropdownOpen, setInfoDropdownOpen] = useState(false);

  const toggleInfoDropdown = () => {
    setInfoDropdownOpen(!infoDropdownOpen);
  };

  //Add member model

  const [addMembersOpen, setAddMembersOpen] = useState(false);

  const [ClientList, setClientList] = useState([]);

  const [addMembersList, setAddMembersList] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [selectedValues, setSelectedValues] = useState([]);


  function afterAddMembersOpen() {
    setLoading(true);
    const organisationName = selectedGroup.organization[0].org_name;
    API.Group.SelectGroupMemberList({ orgname: organisationName })
      .then((response) => {
        setClientList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (ClientList.length > 0) {
      updateAddMembersList();
    }
  }, [ClientList]);

  const updateAddMembersList = () => {
    setLoading(true);


    const alreadyPresentEmployee = selectedGroup?.group_member || [];
    const simplifiedClientList = ClientList.map(({ _id, fullname }) => ({
      _id,
      fullname
    }));

    const alreadyPresentSet = new Set(alreadyPresentEmployee.map(({ userId, name }) => `${userId}-${name}`));
    const clientSet = new Set(simplifiedClientList.map(({ _id, fullname }) => `${_id}-${fullname}`));

    const uniqueToClientList = simplifiedClientList.filter(({ _id, fullname }) => !alreadyPresentSet.has(`${_id}-${fullname}`));
    const uniqueToAlreadyPresent = alreadyPresentEmployee.filter(({ userId, name }) => !clientSet.has(`${userId}-${name}`));

    const uniqueArray = [...uniqueToClientList, ...uniqueToAlreadyPresent];

    uniqueArray.sort((a, b) => a.fullname.localeCompare(b.fullname));

    setAddMembersList(uniqueArray);
    setLoading(false);
  };

  function onClose() {
    setAddMembersList([]);
    setSelectedValues([]);
    setAddMembersOpen(false);
  }

  // Filtered members based on search query
  const filteredMembers = addMembersList.filter(member =>
    member.fullname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCheckboxChange = (event, value) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedValues((prevValues) => [...prevValues, value]);
    } else {
      setSelectedValues((prevValues) =>
        prevValues.filter((item) => item._id !== value._id)
      );
    }
  };




  const handleAddMembers = async () => {
    const selectedGroupId = selectedGroup._id;
    console.log(selectedGroupId);

    try {
      const responses = await Promise.all(
        selectedValues.map((value) =>
          API.Group.SelectGroupMembers({
            data: {
              userId: value?._id,
              groupId: selectedGroupId,
              userName: value?.fullname,
            },
          })
        )
      );

      responses.forEach((response) => {
        FocusGroupListFun();
        SupportGroupListFun();
        setSelectedValues([]);
        setSelectedGroup({});
        onClose();
      });

    } catch (error) {
      console.log(error);
    }
  }
  const [focusSearchQuery, setFocusSearchQuery] = useState("");
  const [supportSearchQuery, setSupportSearchQuery] = useState("");

  //list search 
  const handleSearchTextFocusedGroup = (val) => {
    setFocusSearchQuery(val);
  }

  const handleSearchTextSupportGroup = (val) => {
    setSupportSearchQuery(val);
  }


  const filteredFocusGroup = FocusList.filter(group =>
    group.name.toLowerCase().includes(focusSearchQuery.toLowerCase())
  );

  const filteredSupportGroup = SupportList.filter(group =>
    group.name.toLowerCase().includes(supportSearchQuery.toLowerCase())
  );


  const deleteMembers = async (memberInfo, groupId) => {
    const result = await Swal.fire({
      html: `<h6>Are you sure you want to remove <b>${memberInfo.name}</b> from this group?</h6>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Delete",
      customClass: {
        confirmButton: 'custom-confirm-button',
      },
    });

    if (result.isConfirmed) {
      try {
        const response = await API.Group.RemoveGroupMembers({
          data: {
            userId: memberInfo.userId,
            groupId: groupId,
          },
        });

        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            text: `${memberInfo.name} has been removed from the group.`,
            timer: 2500
          });
          FocusGroupListFun();
          SupportGroupListFun();
          setSelectedValues([]);
          setSelectedGroup({});
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Something went wrong. Please try again later.',
            timer: 2500
          });
          FocusGroupListFun();
          SupportGroupListFun();
          setSelectedValues([]);
          setSelectedGroup({});
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while removing the member.',
          timer: 2500
        });
        console.error('Error removing member:', error);
      }
    }
  };

  //group delete
  const handleGroupDelete = async (groupId, groupName) => {

    const result = await Swal.fire({
      html: `<h6>Are you sure you want to delete <b>${groupName}</b> group?</h6>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Delete",
      customClass: {
        confirmButton: 'custom-confirm-button',
      },
    });

    if (result.isConfirmed) {
      try {
        const response = await API.Group.RemoveGroup({
          data: {
            groupId: groupId,
          },
        });
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            text: `${groupName} has been removed from the groups.`,
            customClass: {
              confirmButton: 'custom-success-confirm-button',
            },
            timer: 2500
          });
          FocusGroupListFun();
          SupportGroupListFun();
          setSelectedValues([]);
          setSelectedGroup({});
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Something went wrong. Please try again later.',
            customClass: {
              confirmButton: 'custom-confirm-button',
            },
            timer: 2500
          });
          FocusGroupListFun();
          SupportGroupListFun();
          setSelectedValues([]);
          setSelectedGroup({});
        }
      } catch (error) {

      }
    }
  }

  return (
    <div className="d-flex h-dashboard conversation-page list">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className="w-100 h-head">
                  <h2 className="heading mt-1 mb-1 pt-1">My Groups</h2>
                  {/* <Button
                    type="submit"
                    className="create-new_notes button_common notes-btn transparent-btn"
                  >
                    <NavLink to={AdminRoute?.Common?.Join_Group}>
                      Join Groups{" "}
                    </NavLink>
                  </Button> */}
                </div>
                <Header />
              </nav>
              <div>
                <p className="mb-2 gray-text">
                  Here is list of all your focused and support groups.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-5 col-sm-5 mb-2 list-item">
              <div className="dashboard-inner chat-left-div bg-pink shadow-pink">
                <div className="sessions-tabs chat-tabs mt-2">
                  <Tabs
                    defaultActiveKey="Focussed"
                    id="uncontrolled-tab-example"
                    className="mb-3 p-0 flex-nowrap"
                    onSelect={handleSelect}
                  >
                    <Tab
                      eventKey="Focussed"
                      title="Focused"
                    // onClick={() => FocusGroupListFun()}
                    >
                      <div className="p-0 clearfix row sessions-text">
                        <div className="people-list" id="people-list">
                          <div className="search position-relative">
                            <input
                              type="text"
                              placeholder="Search"
                              onChange={(e) => handleSearchTextFocusedGroup(e.target.value)}
                            />
                            <i className="fa fa-search position-absolute"></i>
                          </div>
                          <ul className="people-members-list">
                            {loadingGroupList ? (
                              <div className="loader_orange"></div>
                            ) : filteredFocusGroup?.length === 0 ? (
                              <div
                                className="alert alert-warning my-3"
                                role="alert"
                              >
                                Data not found
                              </div>
                            ) : (
                              filteredFocusGroup?.map((val) => (
                                <li
                                  className="clearfix cursor-pointer"
                                  key={val?._id}
                                  onClick={(e) => getGroup_info(e, val)}
                                >
                                  <img
                                    className="icon_group"
                                    src={`${baseApi.baseurl}/images/${val?.logo}`}
                                    alt="avatar"
                                  />
                                  <div className="about">
                                    <div className="name fw-bold">
                                      {val?.name}
                                    </div>
                                    <span className="members">
                                      Members: {val?.currentmember}/
                                      {val?.members}
                                    </span>
                                  </div>
                                  <div className="del" onClick={() => handleGroupDelete(val._id, val.name)}>
                                    <img
                                      className="delIcon"
                                      src={deleteIcon}
                                      alt="delete"
                                    />
                                  </div>
                                  <div className="status">
                                    <div className="day">
                                      {val?.createdAt &&
                                        formatDateTime(val?.createdAt)}
                                    </div>
                                    {/* {val?.msgunread !== 0 ? (
                                      <span className="circle">
                                        {val?.msgunread}
                                      </span>
                                    ) : null} */}
                                  </div>
                                </li>
                              ))
                            )}
                          </ul>
                        </div>
                      </div>
                    </Tab>
                    {/* end Focussed-tab */}
                    <Tab
                      eventKey="Support"
                      title="Support"
                    // onClick={() => SupportGroupListFun()}
                    >
                      <div className="row sessions-text p-0 clearfix">
                        <div className="people-list" id="people-list">
                          <div className="search position-relative">
                            <input
                              type="text"
                              placeholder="Search"
                              onChange={(e) => handleSearchTextSupportGroup(e.target.value)}
                            />
                            <i className="fa fa-search position-absolute"></i>
                          </div>
                          <ul className="people-members-list">
                            {loadingGroupList ? (
                              <div className="loader_orange"></div>
                            ) : filteredSupportGroup?.length === 0 ? (
                              <div
                                className="alert alert-warning my-3"
                                role="alert"
                              >
                                Data not found
                              </div>
                            ) : (
                              filteredSupportGroup?.map((val) => (
                                <li
                                  className="clearfix cursor-pointer"
                                  key={val?._id}
                                  onClick={(e) => getGroup_info(e, val)}
                                >
                                  <img
                                    className="icon_group"
                                    src={`${baseApi.baseurl}/images/${val?.logo}`}
                                    alt="avatar"
                                  />
                                  <div className="about">
                                    <div className="name">{val?.name}</div>
                                    {/* <span className="members">
                                      Members: {val?.currentmember}/
                                      {val?.members}
                                    </span> */}
                                    <span className="members">
                                      Members: {val?.currentmember}
                                    </span>
                                  </div>
                                  <div className="del" onClick={() => handleGroupDelete(val._id, val.name)}>
                                    <img
                                      className="delIcon"
                                      src={deleteIcon}
                                      alt="delete"
                                    />
                                  </div>
                                  <div className="status">
                                    <div className="day">
                                      {val?.createdAt &&
                                        formatDateTime(val?.createdAt)}
                                    </div>
                                    {val?.msgunread !== 0 ? (
                                      <span className="circle">
                                        {val?.msgunread}
                                      </span>
                                    ) : null}
                                  </div>
                                </li>
                              ))
                            )}
                          </ul>
                        </div>
                      </div>
                    </Tab>
                    {/* end Support-tab */}
                  </Tabs>
                </div>

                <div className="add-notes-btn position-absolute cursor-pointer">
                  <span>
                    <p
                      className="text-white fw-bold h2 text-center bg-default-blue"
                      onClick={() => openModalCreateGroup()}
                    >
                      +
                    </p>
                  </span>
                </div>
              </div>
            </div>
            {/* end col-lg-3  */}
            <div className="col-lg-9 col-md-7 col-sm-7 mb-2 list-item">
              {selectedGroup && Object.keys(selectedGroup).length > 0 ? (
                <div className="dashboard-inner">
                  <div className="chat">
                    <div className="chat-header bg-pink clearfix rounded-pill">
                      <div>
                        <img
                          className="icon_group"
                          src={`${baseApi.baseurl}/images/${selectedGroup?.logo}`}
                          alt="avatar"
                        />
                        <div className="chat-about">
                          <div className="chat-with secheading">
                            {selectedGroup?.name}
                          </div>
                          {selectedGroup?.type === "focused" ? (
                            <div className="chat-num-messages">
                              This group expires on{" "}
                              {moment(selectedGroup?.enddate).format(
                                "Do MMMM YYYY"
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {selectedGroup.group_member.length > 0 ? (
                        <div className="chat-members-info cursor-pointer" onClick={toggleInfoDropdown}>
                          <img
                            className="infoIcon"
                            src={infoIcon}
                            alt="info"
                          />
                          {infoDropdownOpen && (
                            <div className="infoDropdown">
                              <h6 className="d-center"><b>Group Members</b></h6>
                              <div className="infoDropdownLength">
                                {selectedGroup.group_member.length > 0 ? (
                                  selectedGroup.group_member
                                    .slice()
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((member, index) => (
                                      <div className="d-flex" onClick={() => deleteMembers(member, selectedGroup._id)} key={index}>
                                        <h6>{member.name}</h6>
                                        <span className="deleteIcon">
                                          <img
                                            className="delIcon"
                                            src={deleteIcon}
                                            alt="delete"
                                          />
                                        </span>
                                      </div>
                                    ))
                                ) : (
                                  'Usernames Cannot be displayed'
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : ''}
                      <div className="addmembers">
                        <button className="button-5" role="button" onClick={() => setAddMembersOpen(true)}>Add Members</button>
                      </div>
                      {/*
                                 <div className="button">
                                 <Button type="submit" className="button_common m-0 p-2">
                                    <div className="button"><Button type="submit" onClick={() => renderActionButton()} className="button_common m-0 p-2">Join Group Session</Button></div>
                                 </Button>
                              </div>
                              */}
                    </div>
                  </div>
                  <div className="col-md-12  mb-2 list-item">
                    <div className="sessions-tabs">
                      <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        onSelect={handleSelectGroupDetails}
                      >
                        <Tab eventKey="home" title="Discussion">
                          <div className="d-flex justify-content-around">
                            <div className="chat">
                              <div
                                className="chat-history chat-history-group"
                                ref={chatHistoryRef}
                              // style={{ overflow: "auto" }}
                              >
                                {/* <div className="text-center"><span className="badge-text mb-3">Today</span></div>*/}
                                <ul>
                                  {/*
                                          <li className="receive-message">
                                             <div className="message my-message">
                                                <h6><span> <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" />
                                                   </span><span className="ml-piv">Uma Joshi</span>
                                                </h6>
                                                <p>Lorem ipsum dolor sit exercitation ullamco</p>
                                                <div className="message-data-time message-data align-right">10:10 AM, Today</div>
                                             </div>
                                          </li>
                                          <li className="send-message">
                                             <div className="message my-message">
                                                <h6><span> <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" />
                                                   </span><span className="ml-piv">Uma Joshi</span>
                                                </h6>
                                                <p>Lorem ipsum dolor sit exercitation ullamco</p>
                                                <div className="message-data-time message-data align-right">10:10 AM, Today</div>
                                             </div>
                                          </li>
                                          */}
                                  {loadingList ? (
                                    <div className="loader_orange"></div>
                                  ) : (
                                    messageList &&
                                    messageList?.map((messageContent, i) => {
                                      return (
                                        <li
                                          key={messageContent?._id}
                                          className={
                                            UserData?.userdata?._id ===
                                              messageContent.user_id
                                              ? "send-message "
                                              : "receive-message"
                                          }
                                        >
                                          <div className="message other-message">
                                            <h6>
                                              <div className="userimg">
                                                <img
                                                  style={{
                                                    height: "40px",
                                                    marginRight: "10px",
                                                  }}
                                                  className="icon_group"
                                                  src={
                                                    messageContent?.user?.length
                                                      ? messageContent?.user[0].image
                                                        ? messageContent?.user[0]?.image
                                                        : UserImage
                                                      : UserImage
                                                  }
                                                  alt="avatar"
                                                />
                                                <span className="ml-piv ">
                                                  {messageContent.author
                                                    ? messageContent.author
                                                    : messageContent?.user[0]
                                                      ?.fullname}
                                                </span>
                                              </div>
                                            </h6>
                                            <p>{messageContent.message}</p>
                                            <div className="message-data-time message-data align-right">
                                              {/* {formatDateTime(
                                                messageContent.createdAt
                                              )} */}
                                              {messageContent?.createdAt
                                                ? formatDateTime(
                                                  messageContent?.createdAt
                                                )
                                                : formatDateTime(new Date())}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })
                                  )}
                                </ul>
                              </div>
                              {/* end chat-history */}
                              <div className="send-box">
                                <textarea
                                  // type="text"
                                  className="form-control bg-pink rounded-pill me-2"
                                  placeholder="Write a message..."
                                  tabIndex="0"
                                  rows={rows}
                                  cols={50}
                                  // autoComplete="off"
                                  value={currMessage}
                                  onChange={(event) =>
                                    setCurrMessage(event.target.value)
                                  }
                                  onKeyDown={handleKeyPress}
                                />
                                <div className="send-box-icon bg-orange">
                                  <Button
                                    className="btn btn--send"
                                    title="Send message"
                                    tabIndex="0"
                                    onClick={sendMessage}
                                  >
                                    <svg
                                      className="btn__icon"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <line
                                        x1="22"
                                        y1="2"
                                        x2="11"
                                        y2="13"
                                      ></line>
                                      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                    </svg>
                                  </Button>
                                </div>
                              </div>{" "}
                              {/* end chat-message */}
                            </div>
                          </div>
                        </Tab>
                        {/* end Discussion-tab */}
                        <Tab eventKey="activity" title="Activity">
                          <div className="d-flex flex-end">
                            <div className="chat activity-tab">
                              <div className="chat-history activity-chat-history-group">
                                {/* <div className="text-center"><span className="badge-text mb-3">Today</span></div>*/}
                                <ul>
                                  {loadingList ? (
                                    <div className="loader_orange"></div>
                                  ) : allActivityData.length === 0 ? (
                                    <div
                                      className="alert alert-warning my-3"
                                      role="alert"
                                    >
                                      Data not found
                                    </div>
                                  ) : (
                                    allActivityData?.map((val) => {
                                      const date =
                                        val?.data?.startDate &&
                                          val?.data?.startTime
                                          ? combineDateTime(
                                            val?.data?.startDate,
                                            val?.data?.startTime
                                          )
                                          : new Date(); // shedual time zone
                                      const currdate = new Date();
                                      const beforeminute = date
                                        ? new Date(get15MinutesBefore(date))
                                        : new Date(); // get 15 min before time
                                      const endDate =
                                        date && new Date(get1HourFuture(date));
                                      const startDateResult =
                                        currdate > beforeminute; // true so sesstion btn show
                                      const endDateResult = currdate < endDate; // true seesion btn show
                                      return (
                                        <div key={val?.data?._id}>
                                          {val?.data?.type?.toLowerCase() ===
                                            "poll" ? (
                                            <li className="clearfix">
                                              <div className="message other-message">
                                                <div className="activity-width">
                                                  <h6>
                                                    <span>
                                                      {" "}
                                                      <img
                                                        className="userimg"
                                                        src={
                                                          val?.data?.user[0]
                                                            ?.image
                                                            ? val?.data?.user[0]
                                                              ?.image
                                                            : UserImage
                                                        }
                                                        style={{
                                                          height: "40px",
                                                          marginRight: "10px",
                                                        }}
                                                        alt="avatar"
                                                      />
                                                    </span>
                                                    <span className="ml-piv">
                                                      {
                                                        UserData?.userdata
                                                          ?.fullname
                                                      }{" "}
                                                    </span>
                                                  </h6>
                                                  <h4>{val?.data?.question}</h4>
                                                  <p>
                                                    {val?.data?.description}
                                                  </p>
                                                  {/* <div className="message-data-time message-data">
                                                    {moment(
                                                      val?.data?.startDate
                                                    ).format("LL")}{" "}
                                                    {val?.data?.startTime}
                                                  </div> */}

                                                  {val?.isresSubmited ? (
                                                    <>
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className="progress-bar"
                                                          role="progressbar"
                                                          // style={{
                                                          //   width: `${
                                                          //     val?.isresSubmited
                                                          //       ? ((val?.score
                                                          //           ?.option1 ||
                                                          //           0) /
                                                          //           (val
                                                          //             ?.totaloption
                                                          //             ?.total ||
                                                          //             1)) *
                                                          //         100
                                                          //       : "0"
                                                          //   }%`,
                                                          // }}

                                                          style={{
                                                            width: `${((val?.score
                                                              ?.option1 ||
                                                              0) /
                                                              (val
                                                                ?.totaloption
                                                                ?.total ||
                                                                1)) *
                                                              100
                                                              }%`,
                                                          }}
                                                        // aria-valuenow="25"
                                                        // aria-valuemin="0"
                                                        // aria-valuemax="100"
                                                        >
                                                          <p className="ps-3">
                                                            Answer
                                                          </p>
                                                          <p className="pe-3 position-absolute progress-percent">
                                                            {parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option1) /
                                                              val?.totaloption
                                                                ?.total
                                                            )}
                                                            %
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className="progress-bar"
                                                          role="progressbar"
                                                          // style={{
                                                          //   width: `${
                                                          //     ((val?.score
                                                          //       ?.option2 ||
                                                          //       0) /
                                                          //       (val
                                                          //         ?.totaloption
                                                          //         ?.total ||
                                                          //         1)) *
                                                          //     100
                                                          //   }%`,
                                                          // }}

                                                          style={{
                                                            width: `${((val?.score
                                                              ?.option2 ||
                                                              0) /
                                                              (val
                                                                ?.totaloption
                                                                ?.total ||
                                                                1)) *
                                                              100
                                                              }%`,
                                                          }}
                                                        // aria-valuenow="25"
                                                        // aria-valuemin="0"
                                                        // aria-valuemax="100"
                                                        >
                                                          <p className="ps-3">
                                                            Answer
                                                          </p>
                                                          <p className="pe-3 position-absolute progress-percent">
                                                            {parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option2) /
                                                              val?.totaloption
                                                                ?.total
                                                            )}
                                                            %
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className="progress-bar"
                                                          role="progressbar"
                                                          // style={{
                                                          //   width: `${
                                                          //     ((val?.score
                                                          //       ?.option3 ||
                                                          //       0) /
                                                          //       (val
                                                          //         ?.totaloption
                                                          //         ?.total ||
                                                          //         1)) *
                                                          //     100
                                                          //   }%`,
                                                          // }}

                                                          style={{
                                                            width: `${((val?.score
                                                              ?.option3 ||
                                                              0) /
                                                              (val
                                                                ?.totaloption
                                                                ?.total ||
                                                                1)) *
                                                              100
                                                              }%`,
                                                          }}
                                                        // aria-valuenow="25"
                                                        // aria-valuemin="0"
                                                        // aria-valuemax="100"
                                                        >
                                                          <p className="ps-3">
                                                            Answer
                                                          </p>
                                                          <p className="pe-3 position-absolute progress-percent">
                                                            {parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option3) /
                                                              val?.totaloption
                                                                ?.total
                                                            )}
                                                            %
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {/* <div
                                                        className="chat-header shadow-pink mb-3 clearfix cursor_pointer"
                                                        // onClick={() =>
                                                        //   handleSubmitAns(
                                                        //     val?.data,
                                                        //     "option1"
                                                        //   )
                                                        // }
                                                      >
                                                        {val?.data?.option1}
                                                      </div>
                                                      <div
                                                        className="chat-header shadow-pink clearfix cursor_pointer"
                                                        // onClick={() =>
                                                        //   handleSubmitAns(
                                                        //     val?.data,
                                                        //     "option2"
                                                        //   )
                                                        // }
                                                      >
                                                        {val?.data?.option2}
                                                      </div>
                                                      <div
                                                        className="chat-header shadow-pink clearfix cursor_pointer"
                                                        // onClick={() =>
                                                        //   handleSubmitAns(
                                                        //     val?.data,
                                                        //     "option3"
                                                        //   )
                                                        // }
                                                      >
                                                        {val?.data?.option3}
                                                      </div> */}
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className="progress-bar"
                                                          role="progressbar"
                                                          style={{
                                                            width: `${((val?.score
                                                              ?.option1 ||
                                                              0) /
                                                              (val
                                                                ?.totaloption
                                                                ?.total ||
                                                                1)) *
                                                              100
                                                              }%`,
                                                          }}
                                                        // aria-valuenow="25"
                                                        // aria-valuemin="0"
                                                        // aria-valuemax="100"
                                                        >
                                                          <p className="ps-3 position-absolute">
                                                            {val?.data?.option1}
                                                          </p>
                                                          {val?.score ? (
                                                            <p className="pe-3 position-absolute progress-percent">
                                                              {parseInt(
                                                                (100 *
                                                                  val?.score
                                                                    ?.option1) /
                                                                val
                                                                  ?.totaloption
                                                                  ?.total
                                                              )}
                                                              %
                                                            </p>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className="progress-bar"
                                                          role="progressbar"
                                                          style={{
                                                            width: `${((val?.score
                                                              ?.option2 ||
                                                              0) /
                                                              (val
                                                                ?.totaloption
                                                                ?.total ||
                                                                1)) *
                                                              100
                                                              }%`,
                                                          }}
                                                        // aria-valuenow="25"
                                                        // aria-valuemin="0"
                                                        // aria-valuemax="100"
                                                        >
                                                          <p className="ps-3 position-absolute">
                                                            {val?.data?.option2}
                                                          </p>
                                                          {val?.score ? (
                                                            <p className="pe-3 position-absolute progress-percent">
                                                              {parseInt(
                                                                (100 *
                                                                  val?.score
                                                                    ?.option2) /
                                                                val
                                                                  ?.totaloption
                                                                  ?.total
                                                              )}
                                                              %
                                                            </p>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className="progress-bar"
                                                          role="progressbar"
                                                          style={{
                                                            width: `${((val?.score
                                                              ?.option3 ||
                                                              0) /
                                                              (val
                                                                ?.totaloption
                                                                ?.total ||
                                                                1)) *
                                                              100
                                                              }%`,
                                                          }}
                                                        // aria-valuenow="25"
                                                        // aria-valuemin="0"
                                                        // aria-valuemax="100"
                                                        >
                                                          <p className="ps-3 position-absolute">
                                                            {val?.data?.option3}
                                                          </p>
                                                          {val?.score ? (
                                                            <p className="pe-3 position-absolute progress-percent">
                                                              {parseInt(
                                                                (100 *
                                                                  val?.score
                                                                    ?.option3) /
                                                                val
                                                                  ?.totaloption
                                                                  ?.total
                                                              )}
                                                              %
                                                            </p>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                                  <div className="mb-3 mt-3 note-msg-text">
                                                    {/* <span className="message-data-time message-data  align-left">
                                                      Note: You can only vote
                                                      until 20-7-2023
                                                    </span> */}
                                                    <span className="message-data-time message-data align-right">
                                                      {moment(
                                                        val?.data?.startDate
                                                      ).format("LL")}{" "}
                                                      {val?.data?.startTime}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          ) : val?.data?.type?.toLowerCase() ===
                                            "quiz" ? (
                                            <li className="clearfix">
                                              <div className="message other-message">
                                                <div className="activity-width">
                                                  <h6>
                                                    <span>
                                                      {" "}
                                                      <img
                                                        className="userimg"
                                                        src={
                                                          val?.data?.user[0]
                                                            ?.image
                                                            ? val?.data?.user[0]
                                                              ?.image
                                                            : UserImage
                                                        }
                                                        style={{
                                                          height: "40px",
                                                          marginRight: "10px",
                                                        }}
                                                        alt="avatar"
                                                      />
                                                    </span>
                                                    <span className="ml-piv">
                                                      {
                                                        UserData?.userdata
                                                          ?.fullname
                                                      }
                                                    </span>
                                                  </h6>
                                                  <h4>{val?.data?.question}</h4>
                                                  <p>
                                                    {val?.data?.description}
                                                  </p>
                                                  {/* <div className="message-data-time message-data">
                                                    {moment(
                                                      val?.data?.startDate
                                                    ).format("LL")}{" "}
                                                    {val?.data?.startTime}
                                                  </div> */}

                                                  {!val?.isresSubmited ? (
                                                    <>
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className={`progress-bar ${val?.data?.option1 === val?.data?.answer ? 'Quiz-bg' : ''}`}
                                                          role="progressbar"
                                                          style={{
                                                            width: `${((val?.score
                                                              ?.option1 ||
                                                              0) /
                                                              (val
                                                                ?.totaloption
                                                                ?.total ||
                                                                1)) *
                                                              100
                                                              }%`,
                                                          }}

                                                        // style={{
                                                        //   width: `${
                                                        //     val?.data
                                                        //       ?.option1 ===
                                                        //     val?.data?.answer
                                                        //       ? "100%"
                                                        //       : "0%"
                                                        //   }`,
                                                        // }}

                                                        // style={{
                                                        //   width: `10%`,
                                                        // }}

                                                        // aria-valuenow="25"
                                                        // aria-valuemin="0"
                                                        // aria-valuemax="100"
                                                        >
                                                          <p className="ps-3 position-absolute">
                                                            {val?.data?.option1}
                                                          </p>
                                                          <p className="pe-3 position-absolute progress-percent">
                                                            {/* {parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option1) /
                                                                val?.totaloption
                                                                  ?.total
                                                            )}
                                                            % */}
                                                            {val?.score ? `${parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option1) /
                                                              val?.totaloption
                                                                ?.total
                                                            )}%` : ''}
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className={`progress-bar ${val?.data?.option2 === val?.data?.answer ? 'Quiz-bg' : ''}`}
                                                          role="progressbar"
                                                          style={{
                                                            width: `${((val?.score
                                                              ?.option2 ||
                                                              0) /
                                                              (val
                                                                ?.totaloption
                                                                ?.total ||
                                                                1)) *
                                                              100
                                                              }%`
                                                          }}

                                                        // style={{
                                                        //   width: `${
                                                        //     val?.data
                                                        //       ?.option2 ===
                                                        //     val?.data?.answer
                                                        //       ? "100%"
                                                        //       : "0%"
                                                        //   }`,
                                                        // }}
                                                        // aria-valuenow="25"
                                                        // aria-valuemin="0"
                                                        // aria-valuemax="100"
                                                        >
                                                          <p className="ps-3 position-absolute">
                                                            {val?.data?.option2}
                                                          </p>
                                                          <p className="pe-3 position-absolute progress-percent">
                                                            {/* {parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option2) /
                                                                val?.totaloption
                                                                  ?.total
                                                            )}
                                                            % */}
                                                            {val?.score ? `${parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option2) /
                                                              val?.totaloption
                                                                ?.total
                                                            )}%` : ''}
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                        <div
                                                          className={`progress-bar ${val?.data?.option3 === val?.data?.answer ? 'Quiz-bg' : ''}`}
                                                          role="progressbar"
                                                          style={{
                                                            width: `${((val?.score
                                                              ?.option3 ||
                                                              0) /
                                                              (val
                                                                ?.totaloption
                                                                ?.total ||
                                                                1)) *
                                                              100
                                                              }%`,
                                                          }}

                                                        // style={{
                                                        //   width: `${
                                                        //     val?.data
                                                        //       ?.option3 ===
                                                        //     val?.data?.answer
                                                        //       ? "100%"
                                                        //       : "0%"
                                                        //   }`,
                                                        // }}
                                                        // aria-valuenow="25"
                                                        // aria-valuemin="0"
                                                        // aria-valuemax="100"
                                                        >
                                                          <p className="ps-3 position-absolute">
                                                            {val?.data?.option3}
                                                          </p>
                                                          <p className="pe-3 position-absolute progress-percent">
                                                            {/* {
                                                            parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option3) /
                                                                val?.totaloption
                                                                  ?.total
                                                            )}
                                                            % */}
                                                            {val?.score ? `${parseInt(
                                                              (100 *
                                                                val?.score
                                                                  ?.option3) /
                                                              val?.totaloption
                                                                ?.total
                                                            )}%` : ''}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div
                                                        className="chat-header shadow-pink mb-3 clearfix cursor_pointer"
                                                      // onClick={() =>
                                                      //   handleSubmitAns(
                                                      //     val?.data,
                                                      //     "option1"
                                                      //   )
                                                      // }
                                                      >
                                                        {val?.data?.option1}
                                                      </div>
                                                      <div
                                                        className="chat-header shadow-pink clearfix cursor_pointer"
                                                      // onClick={() =>
                                                      //   handleSubmitAns(
                                                      //     val?.data,
                                                      //     "option2"
                                                      //   )
                                                      // }
                                                      >
                                                        {val?.data?.option2}
                                                      </div>
                                                      <div
                                                        className="chat-header shadow-pink clearfix cursor_pointer"
                                                      // onClick={() =>
                                                      //   handleSubmitAns(
                                                      //     val?.data,
                                                      //     "option3"
                                                      //   )
                                                      // }
                                                      >
                                                        {val?.data?.option3}
                                                      </div>
                                                    </>
                                                  )}
                                                  <div className="mb-3 mt-3 note-msg-text">
                                                    {/* <span className="message-data-time message-data  align-left">
                                                      Note: You can only vote
                                                      until 20-7-2023
                                                    </span> */}
                                                    <span className="message-data-time message-data align-right">
                                                      {moment(
                                                        val?.data?.startDate
                                                      ).format("LL")}{" "}
                                                      {val?.data?.startTime}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          ) : (
                                            <>
                                              {val?.data?.type?.toLowerCase() ===
                                                "event" ? (
                                                <li>
                                                  <div className="message other-message overflow-hidden event-box">
                                                    {/* {(startDateResult && endDateResult) ? */}
                                                    {/* <div className="button">
                                                      <a
                                                        type="submit"
                                                        className="button_common m-0 p-2"
                                                      > */}
                                                    <div className="button">
                                                      <Button
                                                        type="button"
                                                        onClick={() =>
                                                          renderActionButton(
                                                            1,
                                                            val?.data
                                                              .meetingtoken,
                                                            val?.data
                                                              .mettingname
                                                          )
                                                        }
                                                        className="button_common m-0 p-1"
                                                      >
                                                        <h4 className="pt-2">Join Group Session</h4>
                                                      </Button>
                                                    </div>
                                                    {/* </a>
                                                    </div> */}
                                                    {/* // : null */}
                                                    <h4 className="pt-3">
                                                      Event Title :{" "}
                                                      {val?.data?.question}{" "}
                                                    </h4>
                                                    <div className="mb-2">
                                                      Description :{" "}
                                                      {val?.data?.description}{" "}
                                                    </div>
                                                    <div className="message-data-time p-0">
                                                      Date :{" "}
                                                      {moment(
                                                        val?.data?.startDate
                                                      ).format("LL")}
                                                    </div>
                                                    <div className="message-data-time p-0">
                                                      Start Time :{" "}
                                                      {val?.data?.startTime}
                                                    </div>
                                                    <div className="message-data-time p-0">
                                                      End Time :{" "}
                                                      {val?.data?.endTime}
                                                    </div>
                                                  </div>
                                                </li>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      );
                                    })
                                  )}
                                  {/* 
                                          <li>
                                             <div className="message my-message">
                                                <div className="activity-width">
                                                   <h6><span> <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" />
                                                      </span><span className="ml-piv">Discussion Joshi</span>
                                                   </h6>
                                                   <h4>Which of the following method do you prefer?</h4>
                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eius tempor incididunt ut labore et dolore magna aliqua</p>
                                                   <div className="message-data-time message-data">Started 12-Jun-23 10:00 am</div>
                                                   <div className="chat-header shadow-pink mb-3 bg-white clearfix">Journal Writing</div>
                                                   <div className="chat-header shadow-pink bg-white clearfix">One on One Discussion</div>
                                                   <div className='mb-3 mt-3 note-msg-text'>
                                                      <span className="message-data-time message-data  align-left">Note: You can only vote until 20-7-2023</span>
                                                      <span className="message-data-time message-data align-right">Started 12-Jun-23 10:00 am</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </li>
                                          */}
                                </ul>
                              </div>
                              {/* end chat-history */}
                              {/*
                                          <div className="send-box">
                                                <input
                                                   type="text"
                                                   className="send-box-input bg-pink"
                                                   placeholder="Write a message..."
                                                   tabIndex="0" autoComplete="off" />
                                                <div className="send-box-icon send-box-icon-orange">
                                                   <Button className="btn btn--send" title="Send message" tabIndex="0">
                                                      <svg className="btn__icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                         <line x1="22" y1="2" x2="11" y2="13"></line>
                                                         <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                                      </svg>
                                                   </Button>
                                                </div>
                                             </div>
                                       */}
                              {/* end chat-message */}

                              <Dropdown className="float-end">
                                {/* */}{" "}
                                <Dropdown.Menu>
                                  <p
                                    className="mb-2 mt-1 cursor-pointer"
                                    onClick={() => openModal()}
                                  >
                                    <i className="fa fa-plus me-2"></i>
                                    Create Events
                                  </p>

                                  <p
                                    className="mb-2 cursor-pointer"
                                    onClick={() => openPollModal()}
                                  >
                                    <i className="fa fa-plus me-2"></i>
                                    Create Poll
                                  </p>

                                  <p
                                    className="mb-0 cursor-pointer"
                                    onClick={() => openQuizModal()}
                                  >
                                    <i className="fa fa-plus me-2"></i>
                                    Create Quiz
                                  </p>
                                </Dropdown.Menu>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                  <div className="icon-div me-0">
                                    <i className="fa fa-plus"></i>
                                  </div>
                                </Dropdown.Toggle>
                              </Dropdown>
                            </div>
                          </div>
                        </Tab>
                        {/* end Activity-tab */}
                        <Tab
                          eventKey="resource"
                          title="Resource"
                          className="tab-content"
                        >
                          <div className="d-flex chat justify-content-around resource">
                            <div className="chat activity-tab">
                              <div className="chat-history">
                                {/* <div className="text-center"><span className="badge-text mb-3">Today</span></div>*/}
                                <div className="row row-flex row-flex-wrap px-4">
                                  {loadingList ? (
                                    <div className="loader-wrap">
                                      <div className="loader_orange"></div>
                                    </div>
                                  ) : allResourceData?.length === 0 ? (
                                    <div
                                      className="alert alert-warning my-3"
                                      role="alert"
                                    >
                                      Data not found
                                    </div>
                                  ) : (
                                    allResourceData?.map((val) => (
                                      <div
                                        className="col-xl-3 col-lg-6 col-md-6 mb-3"
                                        key={val?._id}
                                      >
                                        <div className="panel panel-default flex-col h-100">
                                          <div className="drop_box">
                                            <a
                                              href={`${baseApi.baseurl}images/${val?.mediafile}`}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <div
                                                className="d-flex pdf-div cursor_pointer"
                                              // onClick={() => handleDownload(val?.mediafile)}
                                              >
                                                <span
                                                  className={
                                                    val?.type === "pdf"
                                                      ? "pdf-icon"
                                                      : "media-icon"
                                                  }
                                                ></span>
                                                <span className="pdf-text">
                                                  {" "}
                                                  Download
                                                </span>
                                              </div>
                                            </a>

                                            {val?.type === "pdf" ? (
                                              <h6>
                                                {val?.mediafile?.substr(34)}
                                              </h6>
                                            ) : (
                                              <h6>{val?.mediafile}</h6>
                                            )}
                                            <p>Media Type: {val?.type}</p>
                                            <p>
                                              Media Name:{" "}
                                              {val?.originalfilename}
                                            </p>
                                            <p>
                                              {moment(val?.createdAt).format(
                                                "LL"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  )}
                                </div>
                              </div>
                              {/* end chat-history */}
                              {/*
                                            <div className="send-box">
                                            <input
                                               type="text"
                                               className="send-box-input bg-pink"
                                               placeholder="Write a message..."
                                               tabIndex="0"
                                               autoComplete="off"
                                            />
                                            <div className="send-box-icon send-box-icon-orange">
                                               <Button
                                                  className="btn btn--send"
                                                  title="Send message"
                                                  tabIndex="0"
                                               >
                                                  <svg
                                                     className="btn__icon"
                                                     viewBox="0 0 24 24"
                                                     fill="none"
                                                     stroke="currentColor"
                                                     strokeWidth="2"
                                                     strokeLinecap="round"
                                                     strokeLinejoin="round"
                                                  >
                                                     <line x1="22" y1="2" x2="11" y2="13"></line>
                                                     <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                                  </svg>
                                               </Button>
                                            </div>
                                         </div>
                                          */}{" "}
                              {/* end chat-message */}
                              <Dropdown
                                className="float-end"
                                show={StateToggle}
                              >
                                <Dropdown.Menu>
                                  <p className="mb-2">
                                    <i className="fa fa-plus"></i>
                                    <input
                                      type="file"
                                      id="uploadpdf"
                                      accept="application/pdf"
                                      className="custom-file-input cursor-pointer"
                                      title=" "
                                      onChange={(e) => handleUploadResourses(e)}
                                    />
                                    <label htmlFor="uploadpdf">
                                      Upload pdf
                                    </label>
                                  </p>

                                  <p className="mb-0">
                                    <i className="fa fa-plus"></i>
                                    <input
                                      type="file"
                                      id="uploadmedia"
                                      className="custom-file-input cursor-pointer"
                                      accept="image/png, image/gif, image/jpeg, image/jpg"
                                      title=" "
                                      onChange={(e) => handleUploadResourses(e)}
                                    />
                                    <label htmlFor="uploadmedia">
                                      Upload Multimedia
                                    </label>
                                  </p>
                                </Dropdown.Menu>

                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                  <div
                                    className="icon-div me-0"
                                    // onClick={() => alert("ok")}
                                    onClick={() => ToggleF()}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </div>
                                </Dropdown.Toggle>
                              </Dropdown>
                            </div>
                          </div>
                        </Tab>
                        {/* end Resource-tab */}
                      </Tabs>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="dashboard-inner">
                  <div className="chat">
                    <div className="chat-header bg-pink clearfix">
                      <div className="chat-about">
                        <div className="chat-with secheading text-danger">
                          No group selected ...
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* end col-lg-9 */}
          </div>
          <Footer />

          {/* Create Group modal  */}

          <Modal
            isOpen={modalIsOpenCreateGroup}
            onAfterOpen={afterOpenModalCreateGroup}
            onRequestClose={closeModalCreateGroup}
            style={customStyles}
            contentLabel="Example Modal"
            backdrop="static"
          >
            <button
              onClick={closeModalCreateGroup}
              className="select-group close-btn"
            >
              <img src={plusIco} />{" "}
            </button>
            <div>
              <div className="d-flex justify-content-center ">
                {spinner ? (
                  <>
                    <div className="loader_orange"></div>
                    {/* <span className="custom-drag-box">
                      <div className="m-3">
                        <div className="container_loader "></div>
                      </div>
                      <input
                        type="file"
                        id="uploadFile"
                        accept="image/png, image/gif, image/jpeg"
                        tabIndex="1"
                        title=" "
                        onChange={(e) => handleUpload(e)}
                      />
                    </span> */}
                  </>
                ) : ProfileImage ? (
                  <>
                    <span className="custom-drag-box">
                      <div className="d-flex flex-column align-items-center w-100 h-100">
                        <i
                          className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                          aria-hidden="true"
                        ></i>
                        <div
                          className="form-upload-photo-preview2"
                          style={{
                            backgroundImage: `url(${ProfileImage})`,
                          }}
                        ></div>
                        <span className="custom-drag-box-text d-none">
                          Image Uploaded
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                          tabIndex="1"
                          title=" "
                          onChange={(e) => handleUpload(e)}
                        />
                      </div>
                    </span>
                  </>
                ) : (
                  <>
                    <span className="custom-drag-box">
                      <p style={{ textAlign: "Center" }}>Select Group Icon</p>
                      <img className="group-img" src={plusIcon} />
                      <input
                        type="file"
                        id="uploadFile"
                        accept="image/png, image/gif, image/jpeg"
                        tabIndex="1"
                        title=" "
                        onChange={(e) => handleUpload(e)}
                      />
                    </span>
                  </>
                )}
              </div>
              <p className="text-danger">{formErrors?.img}</p>

              <p>
                {" "}
                <span className="text-danger">* </span>Group Name
              </p>
              <input
                type="text"
                placeholder="Group Name"
                className="mb-3"
                name="GName"
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <p className="text-danger">{formErrors?.GName}</p>
              <p>
                {" "}
                <span className="text-danger">* </span>Group Description
              </p>
              <input
                type="text"
                placeholder="Group Description"
                className="mb-3"
                name="GDescription"
                autoComplete="off"
                onChange={(e) => handleChange(e)}
              />
              <p className="text-danger">{formErrors?.GDescription}</p>

              <p>
                <span className="text-danger">* </span>Tags
              </p>
              <input
                type="text"
                placeholder="Tags"
                className="mb-3"
                name="GTags"
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <p className="text-danger">{formErrors?.GTags}</p>
              <p>
                {" "}
                <span className="text-danger">* </span>Select Organization
              </p>

              <select
                className="custom-select mb-3"
                // value={selectedOption}
                onChange={(e) => GetOrgData(e)}
              >
                <option selected>Select Organization</option>
                {MyorgList?.map((val, i) => {
                  return (
                    <option
                      // onChange={() => GetOrgData()}
                      key={i}
                      id={val?._id}
                      // value={val?.org_name + "@" + val?._id}
                      value={JSON.stringify(val)}
                    >
                      {val?.org_name}
                    </option>
                  );
                })}
              </select>

              <p>
                {" "}
                <span className="text-danger">* </span>Select Group Type
              </p>
              <p className="mb-3 mt-3">
                <input
                  type="radio"
                  className="mb-3"
                  name="Group"
                  value="focused"
                  onChange={(e) => handleChange(e)}
                  onClick={(e) => setCheckGroupType("focused")}
                />{" "}
                <label className="form-in">Focused Group</label>
              </p>

              <p className="mb-3">
                <input
                  type="radio"
                  className="mb-3"
                  name="Group"
                  value="support"
                  onChange={(e) => handleChange(e)}
                  onClick={(e) => setCheckGroupType("support")}
                />{" "}
                <label className="form-in">Support Group</label>
              </p>

              <p className="text-danger">{formErrors?.Group}</p>
              <div
                style={{
                  display: CheckGroupType === "support" ? "none" : "block",
                  // color: CheckGroupType === 0 ? "white" : "black",
                }}
              >
                <p>
                  {" "}
                  <span className="text-danger">* </span>Select No. of members
                </p>
                <input
                  type="number"
                  id="myNumberInput"
                  placeholder="Enter No."
                  className="mb-3"
                  name="GMembers"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                />
                <p className="text-danger">{formErrors?.GMembers}</p>

                {/* <Dropdown>
                  <Dropdown.Toggle variant="success" id="drNewopdown-basic">
                    <i className="fa fa-ellipsis-v"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="previouschat-dropdown chat-drop-menu">
                    {MyorgList?.map((item) => (
                      <Dropdown.Item
                        key={item?._id}
                        onClick={() => GetOrgData(item)}
                      >
                        <div className="preview-item-content py-1 ">
                          <p className="font-weight-medium small-text my-0">
                            {item?.org_name}
                          </p>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown> */}
                {/* <p>
                  {" "}
                  <span className="text-danger">* </span>Select Organization
                </p> 
                <select className="custom-select mb-3">
                  <option selected>Select Organization</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <p className="text-danger">{formErrors?.GStartDate}</p> */}

                <p>
                  {" "}
                  <span className="text-danger">* </span>Start Date
                </p>
                <input
                  type="date"
                  placeholder="Start date"
                  className="mb-3"
                  name="GStartDate"
                  onChange={(e) => handleChange(e)}
                  min={disablePastDate()}
                />
                <p className="text-danger">{formErrors?.GStartDate}</p>

                <p>
                  {" "}
                  <span className="text-danger">* </span>End Date
                </p>
                <input
                  type="date"
                  placeholder="End Date"
                  className="mb-3"
                  name="GEndDate"
                  onChange={(e) => handleChange(e)}
                  min={disablePastDate()}
                />
                <p className="text-danger">{formErrors?.GEndDate}</p>
              </div>
            </div>
            <button
              type="submit"
              className="button_common w-100 mt-3 mb-3 text-center "
              role="button"
              onClick={(e) => handleSubmit(e)}
            >
              {/* {CheckGroupType === "support" ? "Submit" : "Select Members"} */}
              Select Members
            </button>
            {loading ? <div className="loader_orange"></div> : <></>}
          </Modal>
          {/* ========================== Event Model =============== */}
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="pop-up-com-header ">
              <label>Create Event</label>
              <button
                onClick={closeModal}
                className="close-btn close-btn-white"
              >
                <img src={plusIconClose} />{" "}
              </button>
            </div>

            <div>
              <p className="commmon-heading mt-2">Event Title</p>
              <input
                type="text"
                placeholder="Enter event title"
                className="mb-3"
                name="EAskQuestion"
                onChange={(e) => handleChangeEvent(e)}
              />
              <p className="text-danger">{formEventErrors?.EAskQuestion}</p>
              <p className="commmon-heading"> Enter event description</p>
              <textarea
                rows="10"
                name="EDescription"
                className="form-input-text-area"
                placeholder="Event Description"
                onChange={(e) => handleChangeEvent(e)}
              ></textarea>
              <p className="text-danger">{formEventErrors?.EDescription}</p>
              <div className="d-flex justify-content-around">
                <div>
                  <p className="commmon-heading">* Start Time</p>
                  <select
                    name="ESTime"
                    className="time-select"
                    style={{ width: "130px" }}
                    onChange={(e) => handleChangeEvent(e)}
                  >
                    {TimeSlotS.map((ele, i) => {
                      return (
                        <option key={i} value={ele}>
                          {ele}
                        </option>
                      );
                    })}
                  </select>
                  <p className="text-danger">{formEventErrors?.ESTime}</p>
                </div>

                <div>
                  <p className="commmon-heading">* End Time</p>

                  <select
                    className="time-select"
                    name="EETime"
                    style={{ width: "130px" }}
                    onChange={(e) => handleChangeEvent(e)}
                  >
                    {TimeSlotE.map((ele, i) => {
                      return (
                        <>
                          <option key={i} value={ele}>
                            {ele}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <p className="text-danger">{formEventErrors?.EETime}</p>
                </div>
              </div>

              <div className="d-flex justify-content-around">
                <div>
                  <p className="commmon-heading">Start Date</p>
                  <input
                    type="date"
                    placeholder="My Task"
                    className="mb-3"
                    style={{ width: "130px", fontSize: "14px" }}
                    name="ESDate"
                    onChange={(e) => handleChangeEvent(e)}
                    min={disablePastDate()}
                  />
                  <p className="text-danger">{formEventErrors?.ESDate}</p>
                </div>
                {/* <div>
                  <p className="commmon-heading">End Date</p>
                  <input
                    type="date"
                    placeholder="My Task"
                    style={{ width: "130px", fontSize: "14px" }}
                    className="mb-3"
                    name="EEDate"
                    onChange={(e) => handleChangeEvent(e)}
                     min={disablePastDate()}
                  />
                  <p className="text-danger">{formEventErrors?.EEDate}</p>
                </div> */}
              </div>
            </div>
            <button
              type="submit"
              className="button_common w-100 mt-3 mb-3 text-center"
              role="button"
              onClick={(e) => handleSubmitEvent(e)}
            >
              Publish
            </button>
            {is_loading ? <div className="loader_orange"></div> : <></>}
          </Modal>
          {/* ==================== Poll Model =================== */}
          <Modal
            isOpen={createPollmodalIsOpen}
            onAfterOpen={afterPollOpenModal}
            onRequestClose={closePollModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="pop-up-com-header">
              <label>Create Poll</label>
              <button
                onClick={closePollModal}
                className="close-btn close-btn-white"
              >
                <img src={plusIconClose} />
                {""}
              </button>
            </div>
            <p className="commmon-heading mt-2">Ask Question</p>
            <input
              type="text"
              placeholder="Enter question"
              className="mb-3"
              name="PollQuestion"
              onChange={(e) => handleChangePoll(e)}
            />
            <p className="text-danger">{formPollErrors?.PollQuestion}</p>
            <p className="commmon-heading">Options</p>
            <input
              type="text"
              placeholder="Enter Option 1"
              className="mb-3"
              name="PollOption1"
              onChange={(e) => handleChangePoll(e)}
            />
            <p className="text-danger">{formPollErrors?.PollOption1}</p>
            <input
              type="text"
              placeholder="Enter Option 2"
              className="mb-3"
              name="PollOption2"
              onChange={(e) => handleChangePoll(e)}
            />
            <p className="text-danger">{formPollErrors?.PollOption2}</p>
            <input
              type="text"
              placeholder="Enter Option 3"
              className="mb-3"
              name="PollOption3"
              onChange={(e) => handleChangePoll(e)}
            />
            <p className="text-danger">{formPollErrors?.PollOption3}</p>

            <button
              type="submit"
              className="button_common w-100 mt-3 mb-3 text-center"
              role="button"
              onClick={(e) => handleSubmitPoll(e)}
            // onClick={() => alert("ok")}
            >
              Publish
            </button>
            {is_loadingPoll ? <div className="loader_orange"></div> : <></>}
          </Modal>
          {/* ============== Quiz Model ===================== */}
          <Modal
            isOpen={createQuizmodalIsOpen}
            onAfterOpen={afterQuizOpenModal}
            onRequestClose={closeQuizModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="pop-up-com-header ">
              <label>Create Quiz</label>
              <button
                onClick={closeQuizModal}
                className="close-btn close-btn-white"
              >
                <img src={plusIconClose} />{" "}
              </button>
            </div>
            <p className="commmon-heading mt-2">Ask Question</p>
            <input
              type="text"
              placeholder="Enter question"
              className="mb-3"
              name="QuizQuestion"
              onChange={(e) => handleChangeQuiz(e)}
            />
            <p className="text-danger">{formQuizErrors?.QuizQuestion}</p>
            <p className="commmon-heading">Options</p>
            <input
              type="text"
              placeholder="Enter Option 1"
              className="mb-3"
              name="QuizOption1"
              onChange={(e) => handleChangeQuiz(e)}
            />
            <p className="text-danger">{formQuizErrors?.QuizOption1}</p>
            <input
              type="text"
              placeholder="Enter Option 2"
              className="mb-3"
              name="QuizOption2"
              onChange={(e) => handleChangeQuiz(e)}
            />
            <p className="text-danger">{formQuizErrors?.QuizOption2}</p>
            <input
              type="text"
              placeholder="Enter Option 3"
              className="mb-3"
              name="QuizOption3"
              onChange={(e) => handleChangeQuiz(e)}
            />
            <p className="text-danger">{formQuizErrors?.QuizOption3}</p>
            <p className="commmon-heading">Correct Answer</p>
            <input
              type="text"
              placeholder="Enter currect answer"
              className="mb-3"
              name="QuizAnswer"
              onChange={(e) => handleChangeQuiz(e)}
            />
            <p className="text-danger">{formQuizErrors?.QuizAnswer}</p>

            <button
              type="submit"
              className="button_common w-100 mt-3 mb-3 text-center"
              role="button"
              onClick={(e) => handleSubmitQuiz(e)}
            >
              Publish
            </button>
            {is_loading ? <div className="loader_orange"></div> : <></>}
          </Modal>
          {/* ============== Add Members Model ===================== */}
          <Modal
            isOpen={addMembersOpen}
            onAfterOpen={afterAddMembersOpen}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Example Modal"
          ><div className="pop-up-com-header">
              <h5>Add New Members</h5>
              <button onClick={onClose} className="close-btn close-btn-white">
                <img src={plusIconClose} alt="Close" />
              </button>
            </div>
            {selectedGroup.type === "focused" && selectedGroup.currentmember >= selectedGroup.members ? (
              <h5 style={{ paddingTop: '40px' }}>Maximum number of participants reached</h5>
            ) : (
              <>
                <div className="col-md-12 mb-2 list-item member-selection-v-height">
                  <div className="sessions-tabs mt-2 my-checklist-wrap">
                    <input
                      type="text"
                      placeholder="Search members..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="form-control mb-3"
                    />
                    <div className="employee-list">
                      <div className="row mt-2 gx-3">
                        {loading ? (
                          <div className="loader-wrap">
                            <div className="loader_orange"></div>
                          </div>
                        ) : filteredMembers?.length === 0 ? (
                          <div className="alert alert-warning my-3" role="alert">
                            Data not found
                          </div>
                        ) : (
                          filteredMembers.map((val) => (
                            <div className="col-12 col-md-12" key={val._id}>
                              <div className="member-selection-card cursor-pointer p-3 d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <p className="group-member-icon d-flex justify-content-center align-items-center">
                                    <img className="member-icon" src={DefaultImage} alt="Avatar" />
                                  </p>
                                  <p className="group-member-name ms-3">
                                    {val.fullname}
                                  </p>
                                </div>
                                <div>
                                  <label className="check_container position-relative">
                                    <input
                                      type="checkbox"
                                      name={val.fullname}
                                      value={val._id}
                                      onChange={(e) => handleCheckboxChange(e, val)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="button_common w-100 mt-3 mb-3 text-center"
                  role="button"
                  onClick={handleAddMembers}
                >
                  Add Members
                </button>
              </>)}
            {is_loading ? <div className="loader_orange"></div> : <></>}
          </Modal>
        </div>
        {/* end conversation-page */}
      </div>
    </div>
  );
};
export default Groupchat;
