import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [25, 55, 85, 111, 125, 25, 55, 85, 111, 125],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: [29, 53, 35, 50, 15, 29, 53, 35, 50, 15],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    // {
    //   label: "Dataset 3",
    //   data: [12, 74, 75, 42, 57.12, 74, 75, 42, 44],
    //   borderColor: "rgb(53, 162, 2)",
    //   backgroundColor: "rgba(53, 162, 2, 0.5)",
    // },
    // {
    //   label: "Dataset 4",
    //   data: [52, 75, 35, 22, 77, 52, 75, 35, 22, 77],
    //   borderColor: "rgb(22, 2, 544)",
    //   backgroundColor: "rgba(22, 2, 544, 0.5)",
    // },
  ],
};

export default function Chart() {
  return (
    <div>
      <div
        style={{
          height: "300px",
          width: "100%",
          backgroundColor: "transparent",
          margin: "20px 0px",
        }}
      >
        <Line
          width={"30%"}
          // options={{ maintainAspectRatio: false }}
          options={options}
          data={data}
        />
      </div>
    </div>
  );
}
