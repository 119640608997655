// App.js
import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import AdminRoute from "../../Route/RouteDetails"
import WellBeingtable from "../Common/WellBeingtable";
import API from "../../Api/Api";
function LineChart() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [allResourceData, setAllResourceData] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [loadingList, setLoadingList] = useState(false);

    useEffect(() => {
        getsuravayDetails();
    }, []);

    const getsuravayDetails = () => {
        setLoadingList(true)
        try {
            API.Survey.Surveylist({
                title: "line",
            }).then((response) => {
                if (response.data.status === 1) {
                    setAllResourceData(response?.data?.data_all[0]);
                    const trueAnswerIdAndValue = {};
                    response?.data?.data_all[0].subsurvay?.forEach((question) => {
                        const trueAnswer = question.answers.find((answer) => Object.values(answer)[0] === true);

                        if (trueAnswer) {
                            trueAnswerIdAndValue[question._id] = Object.keys(trueAnswer)[0];
                        }
                    });
                    setSelectedAnswers(trueAnswerIdAndValue)
                }
                setLoadingList(false)
            });
        } catch (error) {
            console.log(error);
            setLoadingList(false)
        }
    };

    const handleSave = () => {
        const newData = allResourceData.subsurvay.map(({ _id, ...rest }) => rest);
        API.Survey.SubmitSurvay({
            data: {
                data: newData,
                isupdate: allResourceData?.isupdate
            }
        })
            .then((response) => {
                if (response.data.status === 1) {
                    if (response.data.status === 1) {
                        handleShow()
                        setTimeout(() => {
                            handleClose()
                            navigate(`..${AdminRoute.Common.Profile}`);
                        }, 2000)
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className="d-flex h-dashboard profile">
            <Sidebar />
            <div className="toggleIcon">
                <div className="container-fluid">
                    <div className="row">
                        <div className="row">
                            <nav className="navbar navbar-expand-lg header-navbar p-0">
                                <h2 className="heading  mt-1 mb-1" onClick={() => navigate(-1)}>
                                    <span className="arrow arrow-left"></span>
                                    Line Chart
                                </h2>
                                <Header />
                            </nav>
                        </div>
                        <div className="col-12">
                            <p>Here is an overview of your session.</p>
                            <p>
                                Please answer the following questions, which are about how have
                                you been feeling for the past month.
                            </p>
                            <p>
                                Place a check mark on the box that best represents how often
                                have you experienced or felt the following:
                            </p>
                            <div className="welltest-table mt-4 mb-4">
                                {/* <Table responsive>
                                <thead>
                                    <tr>
                                        <th className="f-th b-0 b-l">During the past month,how often did you feel...</th>
                                        <th className="b-l">Never</th>
                                        <th>Once or twice </th>
                                        <th>About once a week</th>
                                        <th>About 2 or 3 times a week</th>
                                        <th>Almost Everyday</th>
                                        <th>Everyday</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="bold b-l">1. Happy</td>
                                        <td className="custom-control custom-checkbox-table b-l">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bold f-th b-0 b-l">2. Interested in Life</td>
                                        <td className="custom-control custom-checkbox-table b-l">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bold f-th b-0 b-l">3. Satisfied with Life</td>
                                        <td className="custom-control custom-checkbox-table  b-l">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                        <td className="custom-control custom-checkbox-table">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table> */}
                                <WellBeingtable
                                    allResourceData={allResourceData}
                                    setAllResourceData={setAllResourceData}
                                    selectedAnswers={selectedAnswers}
                                    setSelectedAnswers={setSelectedAnswers}
                                    loadingList={loadingList}
                                />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Button
                                    variant="primary"
                                    // onClick={handleShow}
                                    onClick={() => handleSave()}
                                    className="mb-4 button_common m-0 mb-2"
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
            <Modal show={show} onHide={handleClose} className="payment-model">
                <Modal.Body>
                    <div className="item m-auto">
                        <div className="tick-mark-div"> </div>
                        <h2 className="heading text-center mb-1">
                            Response recorded successfully
                        </h2>
                        <p className="text-center">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's{" "}
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default LineChart;
