// App.js
import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import AdminRoute from "../../Route/RouteDetails";
import PersonalityTests from "../../Assets/Images/personality-tests.jpg";
import WellBeingTest from "../../Assets/Images/well-being-test.jpg";
import LineChart from "../../Assets/Images/line-chart.jpg";
import DefaultUserImg from "../../Assets/Images/icons/Icon awesome-user-circle.png";
import RadarChart from "../Common/RadarChart";
import API from "../../Api/Api";
import Dropdown from "react-bootstrap/Dropdown";
import baseApi from "../../Api/config";

const Profiledit = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const [spinner, setSpinner] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");
  const [UserInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateofbirth, setdateofbirth] = useState("");

  useEffect(() => {
    setLoading(true);
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          console.log("UserInfo", response?.data?.data);
          setdateofbirth(
            moment(response?.data?.data?.dob).format("YYYY-MM-DD")
          );
          setUserInfo(response?.data?.data);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const initialValues = {
    Name: UserInfo?.fullname
      ? UserInfo?.fullname
      : UserData?.userdata?.fullname,
    Email: UserInfo?.email ? UserInfo?.email : UserData?.userdata?.email,
    PhoneNumber: UserInfo?.phone ? UserInfo?.phone : UserData?.userdata?.phone,
    // DOB: dateofbirth ? dateofbirth : UserData?.userdata?.dob,
    Gender: UserInfo?.gender ? UserInfo?.gender : UserData?.userdata?.gender,
    YearOfExp: UserInfo?.experience
      ? UserInfo?.experience
      : UserData?.userdata?.experience,
    Expertise: UserInfo.experties
      ? UserInfo.experties
      : UserData?.userdata?.experties,
    Quote: UserInfo?.qote ? UserInfo?.qote : UserData?.userdata?.qote,
    Image: UserInfo?.image ? UserInfo?.image : UserData?.userdata?.image,
  };

  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleUpload = (e) => {
    setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setSpinner(false)
        );
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const addImage = (image) => {
    // console.log("Images", `${baseApi.baseurl}images/${image}`);
    setProfileImage(`${baseApi.baseurl}images/${image}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const RegisterValidate = (values) => {
    const errors = {};
    // console.log("values", values);
    if (!values?.Name) {
      errors.Name = "Full Name is required";
    }
    if (!dateofbirth) {
      errors.dateofbirth = "DOB is required";
    }
    // if (!values?.DOB) {
    //   errors.DOB = "DOB is required";
    // }
    if (!values?.Gender) {
      errors.Gender = "Gender is required";
    }
    if (!values?.YearOfExp) {
      errors.YearOfExp = "Experience is required";
    }
    if (!values?.Expertise) {
      errors.Expertise = "Expertise is required";
    }
    if (!values?.Quote) {
      errors.Quote = "Quote is required";
    }
    return errors;
  };

  useEffect(() => {
    // console.log("formErrors", formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("========>", formData?.Gender);
      try {
        API.Profile.UpdateProfileAPI({
          data: {
            fullname: formData?.Name ? formData?.Name : UserInfo?.fullname,
            email: formData?.Email ? formData?.Email : UserInfo?.email,
            phone: formData?.PhoneNumber
              ? formData?.PhoneNumber
              : UserInfo?.phone,
            dob: dateofbirth,
            gender: formData?.Gender ? formData?.Gender : UserInfo?.gender,
            experience: formData?.YearOfExp
              ? formData?.YearOfExp
              : UserInfo?.experience,
            experties: formData?.Expertise
              ? formData?.Expertise
              : UserInfo?.experties,
            qote: formData?.Quote ? formData?.Quote : UserInfo?.qote,
            image: ProfileImage
              ? ProfileImage
              : UserInfo?.image
              ? UserInfo?.image
              : formData?.Image,
          },
          UserId: UserInfo?._id,
        }).then((response) => {
          // console.log(response?.data);
          // alert("update successfull");
          navigate(`../${AdminRoute?.Common?.Dashboard}`);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
    console.log("formData", formData);
  };

  useEffect(() => {
    setLoading(true);
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          console.log("UserInfo", response?.data?.data);
          setdateofbirth(
            moment(response?.data?.data?.dob).format("YYYY-MM-DD")
          );
          setFormData({ ...formData, Gender: response?.data?.data?.gender });
          setUserInfo(response?.data?.data);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  // useEffect(() => {
  //   console.log(dateofbirth);
  // }, []);

  //jsx
  return (
    <div className="d-flex h-dashboard profile">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <h2 className="heading  mt-1 mb-1">Edit Profile</h2>
                <Header />
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12 b-0 pe-0">
                <div className="card-body payment-card-body p-0 profile-wrap">
                  <div className="row">
                    <div className="col-12">
                      <div className="profile-header-box">
                        <div className="col px-0 employee-dropdown setting-dropdown">
                          <Dropdown className="float-end mt-2">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <div className="icon-div me-0 bg-transparent">
                                <i className="fa fa-cog"></i>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <NavLink
                                to={`../${AdminRoute.Common.DisplayCalender}`}
                              >
                                My Calender
                              </NavLink>
                              <br></br>
                              <NavLink to={AdminRoute.Common.Payment}>
                                Edit Max. No of Employees
                              </NavLink>
                              <br></br>
                              <NavLink to={AdminRoute.Common.Payment}>
                                My Referrals
                              </NavLink>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex justify-content-center position-relative edit-profile-image">
                        {spinner ? (
                          <>
                            <div className="edit-profile-page custom-drag-box">
                              <div
                                className="spinner-grow text-warning mx-auto"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                              <input
                                type="file"
                                id="uploadFile"
                                accept="image/png, image/gif, image/jpeg"
                                tabIndex="1"
                                title=" "
                                onChange={(e) => handleUpload(e)}
                              />
                            </div>
                          </>
                        ) : ProfileImage ? (
                          <>
                            <div className="edit-profile-page custom-drag-box ms-4">
                              <div className="d-flex flex-column align-items-center">
                                <i
                                  className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                                  aria-hidden="true"
                                ></i>
                                <div
                                  className="form-upload-photo-preview2"
                                  style={{
                                    backgroundImage: `url(${
                                      ProfileImage
                                        ? ProfileImage
                                        : UserInfo?.image
                                    })`,
                                  }}
                                ></div>
                                <span className="custom-drag-box-text d-none">
                                  Image Uploaded
                                </span>
                                <input
                                  type="file"
                                  id="uploadFile"
                                  accept="image/png, image/gif, image/jpeg, image/jpg"
                                  tabIndex="1"
                                  title=" "
                                  onChange={(e) => handleUpload(e)}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <p className="edit-icon position-absolute">
                              <i className="fa fa-edit"></i>
                            </p>
                            <div className="edit-profile-page custom-drag-box ms-4 d-flex justify-content-center align-items-center">
                              <img
                                className={
                                  UserInfo?.image
                                    ? "reg-user-img"
                                    : "reg-user-img profile-user-default-image"
                                }
                                src={
                                  UserInfo?.image
                                    ? UserInfo?.image
                                    : DefaultUserImg
                                }
                              />
                              <input
                                type="file"
                                id="uploadFile"
                                accept="image/png, image/gif, image/jpeg"
                                tabIndex="1"
                                title=" "
                                onChange={(e) => handleUpload(e)}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2 p-3">
                    <div className="col-12">
                      <h6 className="mb-2">Personal Details</h6>
                      <hr />
                    </div>
                    <form>
                      <div className="row">
                        <div className="col-7">
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="Full Name"
                                  className="form-label"
                                >
                                  Full Name
                                </label>
                                {/* <input
                                  type="text"
                                  name="Name"
                                  className="form-control"
                                  placeholder="Full name"
                                  aria-describedby="Full Name"
                                  defaultValue={UserInfo?.fullname}
                                  onChange={(e) => handleChange(e)}
                                /> */}
                                <input
                                  type="text"
                                  name="Name"
                                  defaultValue={UserInfo?.fullname}
                                  placeholder="Enter your full Name"
                                  required
                                  onChange={(e) => handleChange(e)}
                                />
                                <p className="text-danger mb-0">
                                  {formErrors?.Name}
                                </p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="mb-3">
                                <label htmlFor="Email" className="form-label">
                                  Email
                                </label>
                                {/* <input
                                  type="email"
                                  name="Email"
                                  className="form-control"
                                  placeholder="Enter your E-Mail ID"
                                  aria-describedby="Email"
                                  readOnly
                                  defaultValue={UserInfo?.email}
                                /> */}

                                <input
                                  type="email"
                                  name="Email"
                                  defaultValue={UserInfo?.email}
                                  placeholder="Enter your E-Mail ID"
                                  readOnly
                                />
                              </div>
                              <p className="text-danger mb-0">
                                {formErrors?.Email}
                              </p>
                            </div>
                            <div className="col-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="Contact No"
                                  className="form-label"
                                >
                                  Contact No.
                                </label>
                                {/* <input
                                  type="number"
                                  name="PhoneNumber"
                                  className="form-control"
                                  readOnly
                                  placeholder="Contact No."
                                  aria-describedby="Contact No"
                                  defaultValue={UserInfo?.phone}
                                /> */}

                                <input
                                  type="number"
                                  name="PhoneNumber"
                                  defaultValue={UserInfo?.phone}
                                  placeholder="Enter your contact number"
                                  readOnly
                                />
                              </div>
                              <p className="text-danger mb-0">
                                {formErrors?.PhoneNumber}
                              </p>
                            </div>
                            <div className="col-6">
                              <div className="mb-3">
                                <label htmlFor="Age" className="form-label">
                                  DOB
                                </label>
                                {/* <input
                                  type="number"
                                  name="Age"
                                  className="form-control"
                                  placeholder="Age"
                                  aria-describedby="Age"
                                  defaultValue={UserInfo?.age}
                                  onChange={(e) => handleChange(e)}
                                  required
                                /> */}

                                {/* <input
                                  type="date"
                                  name="DOB"
                                  value={moment(UserInfo?.dob).format(
                                    "DD/MM/YYYY"
                                  )}
                                  required
                                  onChange={(e) => handleChange(e)}
                                /> */}

                                <input
                                  type="date"
                                  name="dateofbirth"
                                  value={dateofbirth}
                                  required
                                  onChange={(e) =>
                                    setdateofbirth(e.target.value)
                                  }
                                />
                                <p className="text-danger mb-0">
                                  {formErrors?.dateofbirth}
                                </p>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label htmlFor="Gender" className="form-label">
                                  Gender
                                </label>

                                {/* <input
                                  type="text"
                                  name="Gender"
                                  defaultValue={UserInfo?.gender}
                                  placeholder="Enter your gender"
                                  required
                                  onChange={(e) => handleChange(e)}
                                /> */}

                                <select
                                  name="Gender"
                                  className="gender-select"
                                  value={formData?.Gender}
                                  onChange={(e) => handleChange(e)}
                                >
                                  <option value="">Select Your Gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Other</option>
                                </select>
                                <p className="text-danger mb-0">
                                  {formErrors?.Gender}
                                </p>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="Years of experience"
                                  className="form-label"
                                >
                                  Years of experience
                                </label>
                                {/* <input
                                  type="number"
                                  name="YearOfExp"
                                  className="form-control"
                                  placeholder="Years of experience"
                                  aria-describedby="Years of experience"
                                  defaultValue={UserInfo?.experience}
                                  required
                                  onChange={(e) => handleChange(e)}
                                /> */}
                                <input
                                  type="text"
                                  name="YearOfExp"
                                  defaultValue={UserInfo?.experience}
                                  placeholder="Enter your years of experince"
                                  required
                                  onChange={(e) => handleChange(e)}
                                />
                                <p className="text-danger mb-0">
                                  {formErrors?.YearOfExp}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="Expertise"
                                  className="form-label"
                                >
                                  Expertise:
                                </label>
                                {/* <textarea
                                  className="form-control"
                                  name="Expertise"
                                  placeholder="Leave a comment here"
                                  rows="9"
                                  id="floatingTextarea"
                                  onChange={(e) => handleChange(e)}
                                  defaultValue={UserInfo?.experties}
                                ></textarea> */}
                                <textarea
                                  type="text"
                                  name="Expertise"
                                  defaultValue={UserInfo?.experties}
                                  rows={8}
                                  className="form-input-text-area"
                                  placeholder="Enter your expertise"
                                  required
                                  onChange={(e) => handleChange(e)}
                                ></textarea>
                                <p className="text-danger mb-0">
                                  {formErrors?.Expertise}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-el col-12 mb-3">
                          <label className="pb-2">
                            Quote you resonate with
                          </label>
                          <input
                            type="text"
                            defaultValue={UserInfo?.qote}
                            name="Quote"
                            placeholder="Enter quote you resonate with"
                            required
                            onChange={(e) => handleChange(e)}
                          />
                          <p className="text-danger mb-0">
                            {formErrors?.Quote}
                          </p>
                        </div>

                        <div className="col-12">
                          <Button
                            type="submit"
                            onClick={(e) => handleSubmit(e)}
                            className="create-new_notes button_common notes-btn transparent-btn mt-2 mt-0"
                          >
                            Save Changes{" "}
                          </Button>
                          {spinner ? (
                            <div className="loader_orange"></div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </form>

                    {/* <div className="col-md-6 left-profile-div pl-3">

                      <div className="col-sm-12 mb-3">
                        <span className="">First Name: </span><span className="col-sm-9 fw-bold"> {UserData?.userdata?.name} </span>
                      </div>
                      <div className="col-sm-12 mb-3">
                        <span className="">Last Name: </span><span className="col-sm-9 fw-bold"> {UserData?.userdata?.last_name} </span>
                      </div>

                      <div className="col-sm-12 mb-3">
                        <span className="">Date of birth: </span><span className="col-sm-9 fw-bold">{moment(UserData?.userdata?.dob).format("LL")}</span>
                      </div>
                    </div>

                    <div className="col-md-6 right-profile-div">
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <span className="">E-Mail Id:  </span><span className="col-sm-y fw-bold"> {UserData?.userdata?.email}</span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <span className="">Mobile No.: </span><span className="col-sm-9 fw-bold"> {UserData?.userdata?.phone} </span>
                        </div>
                      </div>

                      <div className="row d-flex justify-content-end">
                        <div className="col-sm-12 w-50">
                          <Button type="submit" className="transparent-border-btn mb-0.">
                            <NavLink to={`${AdminRoute?.Common?.UserProfile}`}> Edit Profile </NavLink>
                          </Button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Profiledit;
