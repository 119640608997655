/* eslint-disable */

import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import moment from "moment";
import commoan from "../../environment/Config";

function DigitalNotes() {
  const [DigitalNotesList, setDigitalNotesList] = useState([]);
  const [DigitalNotesdetails, setDigitalNotesdetails] = useState({});
  const [loadingList, setLoadingList] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(false);


  //api call for digital notes list

  const getDigitalNoteList = () => {
    setLoadingList(true)
    try {
      API.Auth.DigitalNotesList().then((response) => {
        if (response.status === 200) {
          setDigitalNotesList(response?.data?.data_all);
          if (response.data.data_all[0]?._id) {
            // console.log(response.data.data_all[0]?._id)
            getNotesdetailsbyits_id(response.data.data_all[0]?._id)
          }
          setLoadingList(false)
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingList(false)
    }
  };

  useEffect(() => {
    getDigitalNoteList();
  }, []);

  // api call for details digital notes by its id

  const getNotesdetailsbyits_id = (id) => {
    setLoadingNotes(true)
    try {
      API.Auth.DigitalNotesDetails({
        notes_id: id
      }).then((response) => {
        if (response.status === 200) {
          setDigitalNotesdetails(response?.data?.data_all[0]);
          // console.log(response?.data?.data_all);
          // updateNoteRead(id)
          setLoadingNotes(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingNotes(false);
    }
  };

  const getNotesdetails = (val) => {
    getNotesdetailsbyits_id(val?._id);
    NotesReadMag(val?._id)
  };

  const NotesReadMag = (id) => {
    API.Notes.UpdateNotes(
      {
        update_id: id,
        data: {
          is_seen: 1
        }
      }
    ).then((response) => {
      if (response.status === 200) {
        // setDigitalNotesList(response.data.data)
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  //jsx
  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className="w-100 h-head">
                  <h2 className="heading  mt-1 mb-1">Digital Notes</h2>
                  <Button
                    type="submit"
                    className="button_common notes-btn create-new_notes"
                  >
                    <NavLink to={AdminRoute?.Common?.CreateNotes}>
                      Create New Notes
                    </NavLink>
                  </Button>
                </div>
                <Header />
              </nav>
            </div>
            <div className="col-lg-3 col-md-12  mb-2 list-item">
              <div className="dashboard-inner v-height-digital-notes-page">
                <div className="v-height-digital-notes-page-notes">
                  {loadingList ?
                    <div className="container_loader"></div>
                    :
                    DigitalNotesList.length === 0 ?
                      <div className="alert alert-warning mt-2" role="alert">
                        Data Not Found
                      </div>
                      :
                      DigitalNotesList &&
                      DigitalNotesList.map((val) => {
                        return (
                          <div
                            className="dashboard-text-div notes-left-div mb-4 cursor_pointer"
                            onClick={(e) => getNotesdetails(val)}
                            key={val._id}
                          >
                            <div className="row d-flex align-items-center">
                              <div className="col-lg-10">
                                <h3 className="secheading">{val?.title}</h3>
                              </div>
                              <div className="col-lg-2">
                                {(!val?.is_seen && val.response) ?
                                  <i
                                    className="fa fa-star text-warning"
                                    aria-hidden="true"
                                  ></i>
                                  : null
                                }
                              </div>
                            </div>
                            <p className="date-text">
                              {moment(val?.createdAt).format("LL")}
                            </p>
                            <p className="mt-0 notes-text">
                              {val?.description.length > 40 ? `${val?.description.substring(0, 40)}....` : val?.description}
                            </p>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-12 mb-2">
              <div style={{
                backgroundImage: `url(${commoan.baseUrl}images/${DigitalNotesdetails?.theme && DigitalNotesdetails?.theme[0]?.backgroundimagename})`,
                backgroundRepeat: "no-repeat"
              }} className="dashboard-inner notes-right-div">
                {loadingNotes ?
                  <span >
                    Loading...
                  </span>
                  :
                  DigitalNotesList.length === 0 ?
                    <div className="alert alert-warning" role="alert">
                      Data Not Found
                    </div>
                    :
                    <div
                      className="dashboard-text-div"
                      value={DigitalNotesList?.length - 1}
                    >
                      <h3 className="secheading mt-3">
                        {DigitalNotesdetails?.title}
                      </h3>
                      <p>{DigitalNotesdetails?.description}</p>
                    </div>
                }
              </div>
              {DigitalNotesdetails?.response && DigitalNotesdetails?.response[0]?.responce ?
                < div className="dashboard-text-div  notes-right-last-div mt-4">
                  <h6>Response</h6>
                  <h3 className="secheading mt-3">{DigitalNotesdetails?.response?.theropistname}</h3>
                  <p>
                    {DigitalNotesdetails?.response[0]?.responce}
                  </p>
                </div>
                :
                null
              }
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div >
  );
}

export default DigitalNotes;
