import React, { useState, useEffect } from "react";
// import { Pagination, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { Navbar, Nav } from "react-bootstrap";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import defaultimg from "../../Assets/Images/default.png";

const Myorglist = () => {
  const [MyorgList, setMyorgList] = useState([]);
  const [LoadingMyorgList, setLoadingMyorgList] = useState(false);

  useEffect(() => {
    MyorgListdata();
  }, []);

  const MyorgListdata = () => {
    setLoadingMyorgList(true);
    try {
      API.Auth.MyOrg().then((response) => {
        if (response.data.status === 1) {
          // console.log("myorg", response?.data?.data_all);
          setMyorgList(response?.data?.data_all);
        }
        setLoadingMyorgList(false);
      });
    } catch (error) {
      setLoadingMyorgList(false);
      console.log(error);
    }
  };

  return (
    <div className="d-flex h-dashboard playground">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list dashboard therapist">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className="w-100 h-head">
                  <h2 className="heading p-0">My Organization</h2>
                </div>
                <Header />
              </nav>
            </div>

            <div className="col-md-12">
              <h4 className="secheading mb-2">Organization Details</h4>
              <p className="mb-3">
                Here is a detail list of all your Organization.
              </p>
              <div className="row">
                <div className="myorg-v-height my-organization">
                  <div className="row gx-3">
                    {LoadingMyorgList ? (
                      <div className="container_loader"></div>
                    ) : MyorgList.length === 0 ? (
                      <span>Data not found</span>
                    ) : (
                      MyorgList?.map((val) => (
                        <div
                          className="col-12 col-sm-4 col-lg-3 col-md-3 col-xxl-2 mb-3"
                          key={val?._id}
                        >
                          <div className="card-body h-100">
                            <div className="playground-img-div mb-2">
                              <img
                                src={val?.image ? val?.image : defaultimg}
                                alt="theripist img"
                                height={50}
                                className="img-fluid select-note-theme-img"
                              />
                            </div>
                            <div className="support-div-text px-3">
                              <h4 className="card-title mb-1 text-center">
                                {" "}
                                {val?.org_name}
                              </h4>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default Myorglist;
