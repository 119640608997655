import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import sessions from "../../Assets/Images/sessions.png";
import { useParams } from "react-router-dom";

const MySession = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [sumarySessionList, setSumarySessionList] = useState([]);
  const [upcomingSessionList, setUpcomingSessionList] = useState([]);
  const [loadSumaryList, setLoadSumaryList] = useState(false);
  const [loadUpcomingList, setLoadUpcomingList] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const [Sessiondetails, setSessiondetails] = useState({});
  const [loadingSession, setloadingSession] = useState(false);
  const [SelectedSummaryId, setSelectedSummaryId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSumarySessionList();
    getUpcomingSessionList();
  }, []);

  // api call for upcomming sesssion

  const getUpcomingSessionList = () => {
    setLoadUpcomingList(true);
    try {
      API.Session.upcomingSessionList({
        UserId: UserData?.userdata?._id,
      }).then((response) => {
        if (response.status === 200) {
          setUpcomingSessionList(response?.data?.data_all);
          setLoadUpcomingList(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadUpcomingList(false);
    }
  };

  // get summary list
  const getSumarySessionList = () => {
    setLoadSumaryList(true);
    try {
      API.Session.sumarySessionList({ UserId: UserData?.userdata?._id }).then(
        (response) => {
          if (response.status === 200) {
            // console.log("Summary", response?.data?.data_all);
            setSumarySessionList(response?.data?.data_all?.reverse());
            setLoadSumaryList(false);
          }
        }
      );
    } catch (error) {
      console.log(error);
      setLoadSumaryList(false);
    }
  };

  //api call for session summary details
  const getSessiondetailsbyits_id = (id) => {
    // console.log(id);
    setloadingSession(true);
    try {
      API.Session.SessionDetails({
        SessionId: id,
      }).then((response) => {
        if (response.status === 200) {
          setSessiondetails(response?.data?.data_all);
          console.log(
            "=====setSessiondetails==========>",
            response?.data?.data_all[0]
          );
          // updateNoteRead(id)
          setloadingSession(false);
        }
      });
    } catch (error) {
      console.log(error);
      setloadingSession(false);
    }
  };
  const getSeesiondetails = (val) => {
    setSelectedSummaryId(val?._id);
    // console.log("=Get Session summary========>", val?._id);
    getSessiondetailsbyits_id(val?._id);
  };

  // =======================================

  const initialValues = {
    SessionSummary: "",
    Observations: "",
    Interventions: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitPublishsummary, setIsSubmitPublishsummary] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const RegisterValidate = (values) => {
    const errors = {};

    if (!values?.SessionSummary) {
      errors.SessionSummary = "Session Summary is required !";
    }
    if (!values?.Observations) {
      errors.Observations = "Observations is required !";
    }
    if (!values?.Interventions) {
      errors.Interventions = "Interventions is required !";
    }
    return errors;
  };

  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmitPublishsummary) {
      setLoading(true);
      try {
        API.Session.UpdateSession({
          data: {
            summary: formData?.SessionSummary,
            observation: formData?.Observations,
            intervention: formData?.Interventions,
            SummaryStatus: "Publish",
          },
          clientId: SelectedSummaryId,
        }).then((response) => {
          // console.log("Session", response?.data);
          if (response?.data?.status === 1) {
            getSumarySessionList();
            // navigate(`../${AdminRoute.Common.MySession}`);
          }
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }, [formErrors]);

  const handleSubmitPublish = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    // setSummaryStatus("Publish");
    setIsSubmitPublishsummary(true);
  };
  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className="w-100 h-head">
                  <h2 className="heading mt-1 mb-1 pt-0">My Sessions</h2>
                  {/* <Button
                    type="submit"
                    className="create-new_notes button_common notes-btn transparent-btn"
                  >
                    <NavLink to={`../${AdminRoute?.Common?.DisplayCalender}/1`}>
                      My Calender{" "}
                    </NavLink>
                  </Button> */}
                </div>
                <Header />
              </nav>
            </div>
            <div className="col-md-12 mb-2 list-item my-sessions-v-height my-sessions">
              <div className="sessions-tabs">
                <Tabs
                  defaultActiveKey="home"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="home" title="Upcoming Sessions">
                    <p className="row sessions-text">
                      Here is a detail list of all your upcoming and past
                      sessions.
                    </p>
                    {/* <div className='d-flex justify-content-around'><span className='badge-text'>June 2023</span>  </div> */}
                    <div className="d-flex justify-content-around">
                      <ul className="auto-grid">
                        {loadUpcomingList ? (
                          <div className="container_loader"></div>
                        ) : upcomingSessionList.length === 0 ? (
                          <span>Data not found</span>
                        ) : (
                          upcomingSessionList?.map((val, i) => {
                            return (
                              <li key={i}>
                                <div className="slot-container inner-div">
                                  <div className="slot-time">
                                    <div className="slot">{val?.timestart}</div>
                                    <div className="text-div-sessions">
                                      <div className="slot-div-date">
                                        {moment(val?.date).format("DD-MM-YYYY")}
                                      </div>
                                      <div className="slot-div-name">
                                        {val?.client[0]?.fullname}
                                      </div>
                                    </div>
                                    <div className="clock">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        width="100"
                                        height="100"
                                        viewBox="0 0 50 50"
                                      >
                                        <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })
                        )}
                      </ul>
                    </div>

                    {/* <div className='p-session'>
                      <div className='d-flex justify-content-around'><span className='badge-text'>June 2023</span>  </div>

                      <div className="d-flex justify-content-around">
                        <ul className="auto-grid px-0 mt-3">
                          <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>
                          <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>  <li>
                            <div className="slot-container inner-div">
                              <div className="slot-time">
                                <div className="slot">9:00 | AM</div>
                                <div className='text-div-sessions'>
                                  <div className="slot-div-date"> 04 March 2024</div>
                                  <div className="slot-div-name">Jaywardhan Mane</div>
                                </div>
                                <div className="clock">
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
                                  </svg>
                                </div>
                              </div>

                            </div>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </Tab>
                  <Tab eventKey="profile" title="Sessions Summary">
                    <div className="session-history-box row">
                      <div className="col-md-5 col-lg-4">
                        <div className="session-history-tab-box">
                          <div className="session-history-tab-box-inner p-3">
                            <div className="session-history-inner">
                              {loadSumaryList ? (
                                <div className="container_loader"></div>
                              ) : sumarySessionList.length === 0 ? (
                                <span>Data not found</span>
                              ) : (
                                sumarySessionList?.map((val) => {
                                  return (
                                    <div
                                      className="session-history-box-inner my-session-history p-2 mb-3"
                                      onClick={() => getSeesiondetails(val)}
                                      key={val?._id}
                                    >
                                      <div className="px-0 d-flex justify-content-between">
                                        <div className="slot-div-date small-text">
                                          {moment(val?.date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </div>
                                        <div className="slot-div-name text-dark fw-normal d-flex">
                                          {val?.summary &&
                                          val?.SummaryStatus === "Publish"
                                            ? "Published"
                                            : "Inprogress"}

                                          {val?.summary &&
                                          val?.SummaryStatus === "Publish" ? (
                                            <p className="green-dot ms-2"></p>
                                          ) : (
                                            <p className="orange-dot ms-2"></p>
                                          )}
                                        </div>
                                      </div>

                                      <div className="slot-time px-0 pt-2 bg-transparent">
                                        <div className="slot">
                                          {val?.timestart}
                                        </div>
                                        <div className="text-div-sessions">
                                          <div className="slot-div-name">
                                            {val?.client[0]?.fullname}{" "}
                                            {/* <span className="fw-normal">
                                              {val?.client[0]?.last_name}
                                            </span> */}
                                          </div>
                                          <div className="slot-div-name">
                                            {val?.client[0]?.org_name}{" "}
                                            {/* <span className="fw-normal">
                                              {val?.client[0]?.last_name}
                                            </span> */}
                                          </div>
                                        </div>
                                        <div className="clock">
                                          <i className="fa fa-chevron-right ms-5"></i>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7 col-lg-8">
                        <div className="session-history-details-box p-3">
                          <div className="session-history-details-wrap">
                            {loadingSession ? (
                              <figure>
                                <div className="dot white"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                              </figure>
                            ) : sumarySessionList.length === 0 ? (
                              <div className="alert alert-warning" role="alert">
                                Data Not Found
                              </div>
                            ) : (
                              <div className="row">
                                <div className="col-12 d-flex">
                                  <div className="userimg">
                                    <img
                                      style={{
                                        height: "40px",
                                        marginRight: "10px",
                                      }}
                                      src={sessions}
                                    />
                                  </div>
                                  <div className="user-details">
                                    <h6 className="fw-bold mb-0 gloock-font d-color">
                                      {moment(Sessiondetails[0]?.date).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                      | {Sessiondetails[0]?.timestart}{" "}
                                    </h6>
                                    <p className="mt-1">
                                      Here are all the session details
                                    </p>
                                  </div>
                                </div>
                                <div className="col-12 mt-3">
                                  <h6 className="d-color fw-bold">
                                    Session Summary:
                                  </h6>
                                  <p>{Sessiondetails[0]?.summary}</p>

                                  {Sessiondetails[0]?.summary &&
                                  Sessiondetails[0]?.SummaryStatus ===
                                    "Inprogress" ? (
                                    <>
                                      {" "}
                                      <textarea
                                        name="SessionSummary"
                                        className="form-control mb-3"
                                        placeholder="Edit Session Summary"
                                        rows={5}
                                        onChange={(e) => handleChange(e)}
                                      ></textarea>
                                      <p className="text-danger">
                                        {formErrors?.SessionSummary}
                                      </p>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <h6 className="d-color fw-bold">
                                    Observations:
                                  </h6>
                                  <p className="mb-3">
                                    {Sessiondetails[0]?.observation}
                                  </p>

                                  {Sessiondetails[0]?.observation &&
                                  Sessiondetails[0]?.SummaryStatus ===
                                    "Inprogress" ? (
                                    <>
                                      {" "}
                                      <textarea
                                        name="Observations"
                                        className="form-control mb-3"
                                        placeholder="Edit Observations"
                                        onChange={(e) => handleChange(e)}
                                        rows={5}
                                      ></textarea>{" "}
                                      <p className="text-danger">
                                        {formErrors?.Observations}
                                      </p>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <h6 className="d-color fw-bold">
                                    Interventions:
                                  </h6>
                                  <p className="mb-3">
                                    {Sessiondetails[0]?.intervention}
                                  </p>
                                  {Sessiondetails[0]?.intervention &&
                                  Sessiondetails[0]?.SummaryStatus ===
                                    "Inprogress" ? (
                                    <>
                                      {" "}
                                      <textarea
                                        name="Interventions"
                                        className="form-control mb-3"
                                        placeholder="Edit Interventions"
                                        onChange={(e) => handleChange(e)}
                                        rows={5}
                                      ></textarea>
                                      <p className="text-danger">
                                        {formErrors?.Interventions}
                                      </p>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="col-12">
                                  {Sessiondetails[0]?.summary &&
                                  Sessiondetails[0]?.SummaryStatus ===
                                    "Inprogress" ? (
                                    <>
                                      <Button
                                        type="submit"
                                        className="create-new_notes button_common notes-btn transparent-btn bg-orange text-white mb-2"
                                        onClick={(e) => handleSubmitPublish(e)}
                                      >
                                        Publish
                                      </Button>
                                      {loading ? (
                                        <div className="loader_orange"></div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {/* <Button
                                    type="submit"
                                    className="create-new_notes button_common notes-btn transparent-btn mb-2"
                                  >
                                    Save as In Progress
                                  </Button> */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MySession;
