// App.js
import React, { useEffect, useState, useContext } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import AiTherapist from "../../Assets/Images/ai-therapist.jpg";
import PlayGround from "../../Assets/Images/playground.jpg";
import Support from "../../Assets/Images/support.jpg";
import FollowUp from "../../Assets/Images/followUp.jpg";
import Groups from "../../Assets/Images/groups.jpg";
import UserImage from "../../Assets/Images/icons/Icon awesome-user-circle.png";
import BookSession from "../../Assets/Images/book-session.jpg";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import { checkDate } from "../Common/Functions";
import { FeatrureList } from "../../App";
import JoinSession from "../JoinSession";
import baseApi from "../../Api/config";
import config from "../../agora-manager/config";
import Chart from "../Dashboard/Chart";

// import * as React from 'react';
// import { LineChart } from '@mui/x-charts/LineChart';

function Dashboard() {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));

  // **************** Old Code Starts *********************************

  const featureListContext = useContext(FeatrureList);
  const [DigitalNotesList, setDigitalNotesList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [CurrentPlan, setCurrentPlan] = useState([]);
  const [moodTraker, setMoodTraker] = useState({
    Joy: "50",
    drive: "50",
    stress: "50",
  });
  const [lifeStyleTraker, setLifeStyleTraker] = useState({
    fodIntake: "50",
    sleep: "50",
    activity: "50",
  });
  const [featurePrice, setFeaturePrice] = useState([]);
  const [currentSession, setCurrentSession] = useState({});
  const [ActivityCount, setActivityCount] = useState(0);
  const [MessagecntCount, setMessagesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [FocussedData, setFocussedData] = useState([]);

  useEffect(() => {
    getDigitalNoteList();
    MyPlanlfeatures();
    FeaturePriceList();
    getCurrentSession();
    getFocussedData();
  }, []);

  const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
  const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
  const xLabels = [
    "Page A",
    "Page B",
    "Page C",
    "Page D",
    "Page E",
    "Page F",
    "Page G",
  ];

  const getDigitalNoteList = () => {
    setLoadingList(true);
    try {
      API.Auth.DigitalNotesList().then((response) => {
        if (response.status === 200) {
          setDigitalNotesList(response?.data?.data_all);
          setLoadingList(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingList(false);
    }
  };

  const MyPlanlfeatures = () => {
    setLoading(true);
    try {
      API.Payment.MyPlanlfeatures().then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setCurrentPlan(response.data.data);
          featureListContext.setCurrentPlan(response.data.data);
          if (response.data.welnessdata.length) {
            setMoodTraker({
              ...moodTraker,
              drive: response.data.welnessdata[0].drive,
              Joy: response.data.welnessdata[0].joy,
              stress: response.data.welnessdata[0].stress,
            });
            setLifeStyleTraker({
              ...lifeStyleTraker,
              fodIntake: response.data.welnessdata[0].foodIntake,
              sleep: response.data.welnessdata[0].sleep,
              activity: response.data.welnessdata[0].physicalactivity,
            });
          }
        }
      });
    } catch (error) {
      false(false);
      console.log(error);
    }
  };

  const FeaturePriceList = () => {
    try {
      API.Payment.FeaturePriceList().then((response) => {
        if (response.status === 200) {
          setFeaturePrice(response.data.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlewellness = (val, value) => {
    API.Survey.Wellness({
      data: {
        joy: val === "Joy" ? value : moodTraker.Joy,
        foodIntake: val === "fodIntake" ? value : lifeStyleTraker.fodIntake,
        drive: val === "drive" ? value : moodTraker.drive,
        sleep: val === "sleep" ? value : lifeStyleTraker.sleep,
        stress: val === "stress" ? value : moodTraker.stress,
        physicalactivity: val === "activity" ? value : lifeStyleTraker.activity,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurrentSession = () => {
    try {
      API.Session.currentSessionList().then((response) => {
        if (response.status === 200) {
          setCurrentSession(response.data.data_all[0]);
          setActivityCount(response.data.activitycount);
          setMessagesCount(response.data.messagecntcount);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayment = (featureName, fetaure) => {
    if (featurePrice.length) {
      const Feature = featurePrice.find(
        (feature) => feature.feature_name === featureName
      );
      navigate(
        `../${AdminRoute.Common.MakePayment}/${
          fetaure.features
        }/?planID=${JSON.stringify(fetaure._id)}&amount=${JSON.stringify(
          btoa(Feature.feature_price)
        )}`
      );
    }
  };

  const getFocussedData = (info) => {
    API.Auth.Support_API({
      support: "focused",
    })
      .then((response) => {
        console.log("Group data", response.data);
        if (response.data.status === 1) {
          setFocussedData(response?.data?.data_all);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // **************** Old Code Ends *********************************

  const [is_loading, setis_Loading] = useState(false);
  const [UpcommingList, setUpcommingList] = useState([]);

  const upcomingListfun = () => {
    setis_Loading(true);
    try {
      API.EmployeeTab.UpcomingListdashboard({}).then((response) => {
        // console.log("Sessions====>", response?.data?.data_all);
        if (response?.data?.status === 1) {
          setUpcommingList(response?.data?.data_all[0]);

          // setClientName(
          //   `${response?.data?.data_all[0]?.client[0]?.name} ${response?.data?.data_all[0]?.client[0]?.last_name}`
          // );
        }
        setis_Loading(false);
      });
    } catch (error) {
      setis_Loading(false);
      // console.log(error);
    }
  };

  useEffect(() => {
    upcomingListfun();
  }, []);

  const renderActionButton = (SessionId) => {
    // return joined ? (
    //     <button onClick={handleLeaveClick}>Leave</button>
    // ) : (
    //     <button onClick={handleJoinClick}>Join</button>
    // );
    try {
      API.JoinSession.Joinmeeting({
        data: {
          role: "Therapist",
          meetingId: UpcommingList?.mettingname,
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          // navigate(
          //   `${AdminRoute.Common.VideoCall}/${SessionId}?id=${btoa(
          //     UpcommingList?.meetingtoken
          //   )}&name=${UpcommingList?.mettingname}`
          // );

          const videoCallUrl = `../#${AdminRoute.Common.VideoCall}/${SessionId}?id=${btoa(
            UpcommingList?.meetingtoken
          )}&name=${UpcommingList?.mettingname}`;
          window.open(videoCallUrl, '_blank');         
        }
      });
    } catch (error) {
      console.log(error);
    }

    // ---------------------------------------------------------
  };
  config.selectedProduct = "rtc";

  // ******** Employee Listing ********************

  const [ActiveEmployeeList, setActiveEmployeeList] = useState([]);

  const getActiveEmployeeList = () => {
    // setLoading(true);
    // try {
    //   API.EmployeeTab.ActiveEmployee({ UserId: UserData?.userdata?._id }).then(
    //     (response) => {
    //       // console.log("setActiveEmployeeList", response?.data?.data_all);
    //       setActiveEmployeeList(response?.data?.data_all);
    //       setLoading(false);
    //     }
    //   );
    // } catch (error) {
    //   console.log(error);
    //   setLoading(false);
    // }

    setLoading(true);
    try {
      API.Leaders.LeaderEmployee({
        UserId: UserData?.userdata?._id,
        isLead: "no",
      }).then((response) => {
        if (response.data.status === 1) {
          console.log("Employee", response?.data?.data_all);
          // setLeaderListData(response?.data?.data_all);
          setActiveEmployeeList(response?.data?.data_all);
        }
        // setLoadingMyorgList(false);
        setLoading(false);
      });
    } catch (error) {
      // setLoadingMyorgList(false);
      console.log(error);
      setLoading(false);
    }
  };

  // ************ Leaders or organization list ****************

  // const [MyorgList, setMyorgList] = useState([]);

  // useEffect(() => {
  //   MyorgListdata();
  // }, []);
  // const MyorgListdata = () => {
  //   // setLoadingMyorgList(true);
  //   try {
  //     API.Auth.MyOrg().then((response) => {
  //       if (response.data.status === 1) {
  //         // console.log("myorg", response?.data?.data_all);
  //         setMyorgList(response?.data?.data_all);
  //       }
  //       // setLoadingMyorgList(false);
  //     });
  //   } catch (error) {
  //     // setLoadingMyorgList(false);
  //     console.log(error);
  //   }
  // };

  const [LeaderListData, setLeaderListData] = useState([]);

  const MyLeaderList = () => {
    //  setLoadingMyorgList(true);
    try {
      API.Leaders.LeaderEmployee({
        UserId: UserData?.userdata?._id,
        isLead: "yes",
      }).then((response) => {
        if (response.data.status === 1) {
          // console.log("myorg", response?.data?.data_all);
          setLeaderListData(response?.data?.data_all);
        }
        // setLoadingMyorgList(false);
      });
    } catch (error) {
      // setLoadingMyorgList(false);
      console.log(error);
    }
  };

  useEffect(() => {
    MyLeaderList();
  }, []);

  // ************* Common useEffect **************

  useEffect(() => {
    getActiveEmployeeList();
  }, []);

  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      {loading ? (
        <figure>
          <div className="dot white"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </figure>
      ) : (
        <div className="toggleIcon">
          <div className="container-fluid">
            <div className="row list gx-3">
              <div className="">
                <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                  <h2 className="heading">
                    Welcome
                    <span className="secheading">
                      {" "}
                      {UserData?.userdata?.fullname}!
                    </span>
                  </h2>
                  <Header />
                </nav>
              </div>{" "}
              {/* Header*/}
              <div className="col-lg-12 col-md-12 mb-2 dashborad-wrap">
                <div className="row">
                  {CurrentPlan.find((item) => item.features === "Session")
                    ?.is_available !== 1 &&
                  checkDate(
                    new Date(
                      CurrentPlan.find(
                        (item) => item.features === "Session"
                      )?.endDate
                    )
                  ) ? (
                    <div className="col-lg-6 mb-2 col-md-12 list-item">
                      <div className="dashboard-inner p-3 h-100">
                        <div className="row h-100">
                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <h3 className="secheading">Sessions</h3>
                              <p className="mb-3 text-muted">
                                Here is an overview of your session’s latest
                                activity.
                              </p>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="join-session-box p-3 py-2 pb-3">
                              {/* <p className="mt-0">Today</p> */}

                              <p className="mt-0">
                                Date:{" "}
                                {moment(
                                  UpcommingList && UpcommingList?.date
                                ).format("LL")}{" "}
                                -{UpcommingList?.day}
                              </p>
                              {is_loading ? (
                                <div className="loader_orange"></div>
                              ) : UpcommingList?.client ? (
                                <div className="session-inner-box p-3 d-flex justify-content-between align-items-center">
                                  <div className="session-time-name d-flex align-items-center">
                                    <div className="session-time px-3 py-2">
                                      <span className="fw-bold">
                                        <p>{UpcommingList?.timestart}</p>
                                      </span>
                                    </div>
                                    <div className="session-name ms-3">
                                      <p className="my-0">
                                        <span className="fw-bold">
                                          {" "}
                                          {`${
                                            UpcommingList?.client
                                              ? UpcommingList?.client[0]
                                                  ?.fullname
                                              : ""
                                          } `}
                                        </span>
                                      </p>
                                      {/* <p className="my-0">
                                        {UpcommingList?.client
                                          ? UpcommingList?.client[0]?.last_name
                                          : ""}
                                      </p> */}
                                    </div>
                                  </div>
                                  <div className="join-session-btn">
                                    <Button
                                      className="button_common px-3 py-2 my-0"
                                      onClick={() =>
                                        renderActionButton(UpcommingList?._id)
                                      }
                                    >
                                      Join
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <h6>No Sessions Available</h6>
                              )}
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="row mt-3">
                              <div className="col-md-12 col-lg-12 d-flex align-items-end justify-content-between">
                                <div className="left-heading">
                                  <h3 className="secheading d-flex">
                                    Leaders{" "}
                                    <p className="count-box ms-2 text-center my-0">
                                      {LeaderListData?.length}
                                    </p>
                                  </h3>
                                  <p className="text-muted">
                                    Here is an overview of all the details.
                                  </p>
                                </div>
                                <p
                                  className="view-all-btn cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `../${AdminRoute.Common.Employee}/yes`
                                    )
                                  }
                                >
                                  View All
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="row mt-2 gx-2">
                              {LeaderListData?.length === 0 ? (
                                <div
                                  className="alert alert-warning digital-notes-alert"
                                  role="alert"
                                >
                                  Data Not Found
                                </div>
                              ) : (
                                LeaderListData?.length &&
                                LeaderListData.slice(0, 4)?.map((val, i) => {
                                  return (
                                    <div
                                      className="col-md-6 col-lg-4 col-xl-3"
                                      key={i}
                                    >
                                      <div className="leader-box bg-white p-2 d-flex align-items-center justify-content-around h-100">
                                        <div className="leader-user-img d-flex align-items-center justify-content-center flex-shrink-0">
                                          <img
                                            src={
                                              val?.image
                                                ? val?.image
                                                : UserImage
                                            }
                                            className=""
                                          ></img>
                                        </div>

                                        <div className="text-muted ms-2">
                                          <p className="fw-bold my-0">
                                            {val?.client?.fullname}
                                          </p>
                                          {/* <p className="my-0">Anderson</p> */}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="row mt-3">
                              <div className="col-md-12 col-lg-12 d-flex align-items-end justify-content-between">
                                <div className="left-heading">
                                  <h3 className="secheading d-flex">
                                    Employee{" "}
                                    <p className="count-box ms-2 text-center my-0">
                                      {ActiveEmployeeList?.length}
                                    </p>
                                  </h3>
                                  <p className="text-muted">
                                    Here is an overview of all the details.
                                  </p>
                                </div>
                                <p
                                  className="view-all-btn cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `../${AdminRoute.Common.Employee}/no`
                                    )
                                  }
                                >
                                  View All
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="row mt-2 gx-2">
                              {ActiveEmployeeList?.length === 0 ? (
                                <div
                                  className="alert alert-warning digital-notes-alert"
                                  role="alert"
                                >
                                  Data Not Found
                                </div>
                              ) : (
                                ActiveEmployeeList?.length &&
                                ActiveEmployeeList.slice(0, 4)?.map(
                                  (val, i) => {
                                    return (
                                      <div
                                        className="col-md-6 col-lg-4 col-xl-3"
                                        key={i}
                                      >
                                        <div className="leader-box bg-white p-2 d-flex align-items-center justify-content-around h-100">
                                          <div className="leader-user-img d-flex align-items-center justify-content-center flex-shrink-0">
                                            <img
                                              src={UserImage}
                                              className=""
                                            ></img>
                                          </div>

                                          <div className="text-muted ms-2">
                                            <p className="fw-bold my-0">
                                              {val?.client?.fullname}
                                            </p>
                                            {/* <p className="my-0">Anderson</p> */}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <h3 className="secheading mt-3">Your Groups</h3>
                              <p className="mb-3 text-muted">
                                A friend in need is a friend in deed; and we aim
                                to be that one for you.
                              </p>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-12 col-lg-6">
                                <div
                                  className="dashboard-inner p-2 h-100 cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `../${AdminRoute.Common.Groupchat}`
                                    )
                                  }
                                >
                                  <div className="dashboard-img-div your-groups">
                                    <div className="img d-flex justify-content-center align-items-center">
                                      <img src={Groups} alt="AiTherapist" />
                                    </div>
                                  </div>
                                  <div className="dashboard-text-div px-3">
                                    <h3 className="secheading mt-3">
                                      {" "}
                                      Focused
                                    </h3>
                                    <p>
                                      Join forces with others in focused group
                                      therapy, emphasising practical solutions
                                      and diverse viewpoints for a more dynamic,
                                      collective healing experience.
                                    </p>
                                  </div>
                                  <p
                                    className="px-3 fw-bold cursor-pointer"
                                    onClick={() =>
                                      navigate(
                                        `../${AdminRoute.Common.Groupchat}`
                                      )
                                    }
                                  >
                                    Know More
                                  </p>
                                </div>

                                {CurrentPlan.find(
                                  (item) => item.features === "Groups"
                                )?.is_available !== 1 &&
                                checkDate(
                                  new Date(
                                    CurrentPlan.find(
                                      (item) => item.features === "Groups"
                                    )?.endDate
                                  )
                                ) ? (
                                  <></>
                                ) : (
                                  <div
                                    className="dashboard-inner p-2 h-100 cursor-pointer"
                                    onClick={() =>
                                      navigate(
                                        `../${AdminRoute.Common.Groupchat}`
                                      )
                                    }
                                  >
                                    <div className="dashboard-img-div your-groups">
                                      <div className="img d-flex justify-content-center align-items-center">
                                        <img src={Groups} alt="AiTherapist" />
                                      </div>
                                    </div>
                                    <div className="dashboard-text-div px-3">
                                      <h3 className="secheading mt-3">
                                        {" "}
                                        Focused
                                      </h3>
                                      <p>
                                        Join forces with others in focused group
                                        therapy, emphasising practical solutions
                                        and diverse viewpoints for a more
                                        dynamic, collective healing experience.
                                      </p>
                                      <div className="row my-2">
                                        {FocussedData &&
                                          FocussedData.slice(0, 3).map(
                                            (slide, i) => (
                                              <React.Fragment key={1}>
                                                <div className="col-3">
                                                  <span className="group-img position-relative">
                                                    {/*  <span className='group-messages position-absolute'></span>*/}
                                                    <img
                                                      src={
                                                        slide.logo
                                                          ? `${baseApi.baseurl}/images/${slide.logo}`
                                                          : `${process.env.REACT_APP_BASE_URL}images/group-default.jpg`
                                                      }
                                                      alt="AiTherapist"
                                                    />
                                                  </span>
                                                </div>
                                              </React.Fragment>
                                            )
                                          )}
                                        <div
                                          className="col-3 view-all-btn d-flex align-items-center cursor_pointer"
                                          onClick={() =>
                                            navigate(
                                              `../${AdminRoute.Common.Join_Group}`
                                            )
                                          }
                                        >
                                          View All
                                        </div>
                                      </div>

                                      <p
                                        className="px-3 fw-bold cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `../${AdminRoute.Common.Groupchat}`
                                          )
                                        }
                                      >
                                        Know More
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="col-md-12 col-lg-6">
                                <div className="dashboard-inner p-2 mb-2 h-100">
                                  {CurrentPlan.find(
                                    (item) => item.features === "Playground"
                                  )?.is_available !== 1 &&
                                  checkDate(
                                    new Date(
                                      CurrentPlan.find(
                                        (item) => item.features === "Playground"
                                      )?.endDate
                                    )
                                  ) ? (
                                    <>
                                      <div
                                        className="dashboard-img-div your-groups cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `../${AdminRoute.Common.Groupchat}`
                                          )
                                        }
                                      >
                                        <div className="img d-flex justify-content-center align-items-center">
                                          <img src={Support} alt="Support" />
                                        </div>
                                      </div>
                                      <div
                                        className="dashboard-text-div px-3 cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `../${AdminRoute.Common.Groupchat}`
                                          )
                                        }
                                      >
                                        <h3 className="secheading mt-3">
                                          Support
                                        </h3>
                                        <p>
                                          Connect with a community that truly
                                          understands you, sharing and growing
                                          through shared emotional experiences.
                                        </p>
                                      </div>

                                      <p
                                        className="px-3 fw-bold cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `../${AdminRoute.Common.Groupchat}`
                                          )
                                        }
                                      >
                                        Know More
                                      </p>
                                    </>
                                  ) : (
                                    <div
                                      className="cursor_pointer"
                                      onClick={() =>
                                        navigate(
                                          `../${AdminRoute.Common.Playground}`
                                        )
                                      }
                                    >
                                      <div className="dashboard-img-div your-groups">
                                        <div className="img d-flex justify-content-center align-items-center">
                                          <img src={PlayGround} alt="Groups" />
                                        </div>
                                      </div>
                                      <div className="dashboard-text-div px-3">
                                        <h3 className="secheading mt-3">
                                          Support
                                        </h3>
                                        <p>
                                          Connect with a community that truly
                                          understands you, sharing and growing
                                          through shared emotional experiences.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-lg-3 mb-2 col-md-12 list-item">
                        <div className="dashboard-inner p-3">
                          <div className="row h-100">
                            <div className="col-md-12 mb-2">
                              <div className="time-text-right">
                                <div className="dashboard-text-div">
                                  <h3 className="secheading mb-2">
                                    Updates From Therapist
                                  </h3>
                                  <p className="my-2">
                                    Attend all sessions for speedier recovery.
                                  </p>
                                  <div className="update-therapist-box d-flex justify-content-around align-items-center py-2 mb-1 pt-0">
                                    <div className="name-profile">
                                      <p className="text-center mx-auto d-flex justify-content-center aline-items-center">
                                        <img
                                          src={AiTherapist}
                                          alt="PlayGround"
                                        />
                                      </p>
                                      <p className="fw-bold text-center px-4">
                                        {currentSession?.theropis?.length &&
                                          currentSession?.theropis[0]?.fullname}
                                      </p>
                                    </div>
                                    <div className="activity-messages">
                                      <p className="mt-0 fw-bold d-flex align-items-center">
                                        <span>
                                          {ActivityCount ? ActivityCount : "0"}
                                        </span>
                                        Activity
                                      </p>
                                      <p className="mt-0 fw-bold d-flex align-items-center">
                                        <span>
                                          {MessagecntCount
                                            ? MessagecntCount
                                            : "0"}
                                        </span>
                                        Messages
                                      </p>
                                    </div>
                                  </div>
                                  <NavLink
                                    to={AdminRoute?.Common.MyTherapist}
                                    className={"float-end"}
                                  >
                                    <Button className="button_common m-0 mb-2 px-4">
                                      View Details
                                    </Button>
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* dashboard 2 sections End*/}
                  <div className="col-lg-6 mb-2 col-md-12">
                    <div className="dashboard-inner p-3">
                      <div className="row">
                        <div className="dashboard-text-div">
                          <div className="row">
                            <h6 className="mb-3 secheading">
                              Employee Analytics{" "}
                            </h6>
                            <div className="col-md-12">
                              <Chart />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <h3 className="secheading mt-3">Playground</h3>
                          <p className="mb-3 text-muted">
                            Here is an overview of latest clients assigned to
                            you.
                          </p>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row">
                          <div className="col-md-12 col-lg-6">
                            {CurrentPlan.find(
                              (item) => item.features === "Groups"
                            )?.is_available !== 1 &&
                            checkDate(
                              new Date(
                                CurrentPlan.find(
                                  (item) => item.features === "Groups"
                                )?.endDate
                              )
                            ) ? (
                              <div
                                className="dashboard-inner p-2 h-100 cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `../${AdminRoute.Common.MyChecklist}`
                                  )
                                }
                              >
                                <div className="dashboard-img-div">
                                  <span className="img">
                                    <img src={FollowUp} alt="Follow Up" />
                                  </span>
                                </div>
                                <div className="dashboard-text-div px-3">
                                  <h3 className="secheading mt-3">
                                    {" "}
                                    Follow up
                                  </h3>
                                  <p>
                                    Find your tribe, share challenges, and
                                    unlock strength with other driven
                                    professionals.
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            {CurrentPlan.find(
                              (item) => item.features === "Groups"
                            )?.is_available !== 1 &&
                            checkDate(
                              new Date(
                                CurrentPlan.find(
                                  (item) => item.features === "Groups"
                                )?.endDate
                              )
                            ) ? (
                              <></>
                            ) : (
                              <div
                                className="dashboard-inner p-2 h-100"
                                onClick={() =>
                                  navigate(`../${AdminRoute.Common.Groupchat}`)
                                }
                              >
                                <div className="dashboard-img-div">
                                  <span className="img">
                                    <img src={Groups} alt="AiTherapist" />
                                  </span>
                                </div>
                                <div className="dashboard-text-div px-3">
                                  <h3 className="secheading mt-3"> Groups</h3>
                                  <p>
                                    Find your tribe, share challenges, and
                                    unlock strength with other driven
                                    professionals.
                                  </p>
                                  <div className="row my-2">
                                    {FocussedData &&
                                      FocussedData.slice(0, 3).map(
                                        (slide, i) => (
                                          <React.Fragment key={1}>
                                            <div className="col-3">
                                              <span className="group-img position-relative">
                                                {/*  <span className='group-messages position-absolute'></span>*/}
                                                <img
                                                  src={
                                                    slide.logo
                                                      ? `${baseApi.baseurl}/images/${slide.logo}`
                                                      : `${process.env.REACT_APP_BASE_URL}images/group-default.jpg`
                                                  }
                                                  alt="AiTherapist"
                                                />
                                              </span>
                                            </div>
                                          </React.Fragment>
                                        )
                                      )}
                                    <div
                                      className="col-3 view-all-btn d-flex align-items-center cursor_pointer"
                                      onClick={() =>
                                        navigate(
                                          `../${AdminRoute.Common.Join_Group}`
                                        )
                                      }
                                    >
                                      View All
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 col-lg-6">
                            <div className="dashboard-inner p-2 mb-2 h-100">
                              {CurrentPlan.find(
                                (item) => item.features === "Playground"
                              )?.is_available !== 1 &&
                              checkDate(
                                new Date(
                                  CurrentPlan.find(
                                    (item) => item.features === "Playground"
                                  )?.endDate
                                )
                              ) ? (
                                <>
                                  <div
                                    className="dashboard-img-div cursor-pointer"
                                    onClick={() =>
                                      navigate(
                                        `../${AdminRoute.Common.Playground}`
                                      )
                                    }
                                  >
                                    <span className="img">
                                      <img src={PlayGround} alt="Groups" />
                                    </span>
                                  </div>
                                  <div
                                    className="dashboard-text-div px-3 cursor-pointer"
                                    onClick={() =>
                                      navigate(
                                        `../${AdminRoute.Common.Playground}`
                                      )
                                    }
                                  >
                                    <h3 className="secheading mt-3">
                                      Playground
                                    </h3>
                                    <p>
                                      Unleash your creativity and recharge your
                                      mind with playful exercises designed for
                                      peak performance.
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <div
                                  className="cursor_pointer"
                                  onClick={() =>
                                    navigate(
                                      `../${AdminRoute.Common.Playground}`
                                    )
                                  }
                                >
                                  <div className="dashboard-img-div">
                                    <span className="img">
                                      <img src={PlayGround} alt="Groups" />
                                    </span>
                                  </div>
                                  <div className="dashboard-text-div px-3">
                                    <h3 className="secheading mt-3">
                                      Playground
                                    </h3>
                                    <p>
                                      Unleash your creativity and recharge your
                                      mind with playful exercises designed for
                                      peak performance.
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* col-lg-9 */}
            </div>

            <Footer />
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
