/* eslint-disable */
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import { combineDateTime } from "../Common/Functions";

const Appointment_scheduler = () => {
  const [loading, setLoading] = useState(false);

  const [daysOfWeek, setDaysOfWeek] = useState(1);
  const [selectedDays, setSelectedDays] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [Time, setTime] = useState([]);
  const [isSetStartTime, setisSetStartTime] = useState(false);
  const [isSetEndTime, setisSetEndTime] = useState(false);
  const [disableindex, setdisableindex] = useState(0);
  const [pickedSlots, setpickedSlots] = useState([]);
  const [CalenderError, setCalenderError] = useState();
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const navigate = useNavigate();
  useEffect(() => {
    console.log(pickedSlots);
  });
  const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const timeSlots = [
    { "Select Time": false },
    { "12:00 AM": false },
    { "01:00 AM": false },
    { "02:00 AM": false },
    { "03:00 AM": false },
    { "04:00 AM": false },
    { "05:00 AM": false },
    { "06:00 AM": false },
    { "07:00 AM": false },
    { "08:00 AM": false },
    { "09:00 AM": false },
    { "10:00 AM": false },
    { "11:00 AM": false },
    { "12:00 PM": false },
    { "01:00 PM": false },
    { "02:00 PM": false },
    { "03:00 PM": false },
    { "04:00 PM": false },
    { "05:00 PM": false },
    { "06:00 PM": false },
    { "07:00 PM": false },
    { "08:00 PM": false },
    { "09:00 PM": false },
    { "10:00 PM": false },
    { "11:00 PM": false },
  ];

  const slots = [
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];

  const handleClickSlots = (ele) => {
    // console.log(ele)
    const filteredTimeSlots = Time.filter((timeSlot) => {
      const time = Object.keys(timeSlot)[0];
      return time === ele;
    });
    // console.log("valval", Object.values(filteredTimeSlots[0])[0])
    if (
      Object.values(filteredTimeSlots[0])[0] &&
      pickedSlots.includes(ele) === false
    ) {
      setpickedSlots([...pickedSlots, ele]);
    } else if (pickedSlots.length) {
      setpickedSlots(pickedSlots.filter((word) => word !== ele));
    }
  };

  const handleTimeSlots = (e) => {
    // console.log(e.target.selectedIndex);
    setdisableindex(e.target.selectedIndex);
    setStartTime(e.target.selectedIndex);
    setisSetStartTime(true);
  };

  const handleSecondTimeSlot = (e) => {
    let lowerIndex;
    let higherIndex;
    timeSlots.filter((ele, i) => {
      if (Object.values(ele)[0] === true) {
        lowerIndex = i;
      }
      if (Object.keys(ele).toString() === e) {
        higherIndex = i;
        // setdisableindex(i);
      }
    });

    timeSlots.forEach((ele, i) => {
      if (lowerIndex <= i && higherIndex >= i) {
        timeSlots[i][Object.keys(ele)] = true;
      } else {
        timeSlots[i][Object.keys(ele)] = false;
      }
    });

    //Set Var Data Here
    setTime(timeSlots);
    // setEndT(1 + e.target.value);
    if (startTime < 1 + e.target.selectedIndex) {
      const selectedTimeSlots = timeSlots.slice(
        startTime,
        1 + e.target.selectedIndex
      );
      const modifiedTimeSlots = timeSlots.map((timeSlot) => {
        const isIncluded = selectedTimeSlots.find((selectedSlot) => {
          return Object.keys(timeSlot)[0] === Object.keys(selectedSlot)[0];
        });

        return {
          [Object.keys(timeSlot)[0]]: isIncluded ? true : false,
        };
      });
      // setpickedSlots(modifiedTimeSlots)
      setTime(modifiedTimeSlots);
      // newa = modifiedTimeSlots;
    }
    setisSetEndTime(true);
  };

  const submitCalender = (e) => {
    e.preventDefault();
    if (
      daysOfWeek?.length != 0 &&
      selectedDays?.length != 0 &&
      Time?.length != 0
      // &&
      // pickedSlots?.length != 0
    ) {
      try {
        setLoading(true);
        API.Auth.CalenderUpdate({
          data: {
            theropist_calender: {
              noofworking_days: parseInt(daysOfWeek),
              days: selectedDays,
              time: Time,
              exclude: pickedSlots,
            },
          },
          UserId: UserData?.userdata?._id,
        }).then((response) => {
          // console.log(response?.data);

          if (response?.data?.status === 1) {
            setCalenderError("");
            // toast.success(response?.data?.message);
            navigate(`../${AdminRoute?.Common?.Dashboard}`);
          }
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      // alert("API call");
      setCalenderError("");
    } else {
      // alert("Error display");
      setCalenderError("Please Fill all the details!");
    }
  };

  const handleClickDays = (item) => {
    if (
      parseInt(daysOfWeek) > selectedDays.length &&
      selectedDays.includes(item) === false
    ) {
      setSelectedDays([...selectedDays, item]);
    } else if (selectedDays.length) {
      setSelectedDays(selectedDays.filter((word) => word !== item));
    }
  };
  return (
    <div className="main-container dashboard therapist appointmentscheduler">
      <div className="row d-flex justify-content-center inner">
        <div className="col-md-12">
          <Navbar className="top-bar">
            <span className="backbtn" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </span>
            <Nav className="mr-auto">
              <span className="logo-div">
                <NavLink to="/">
                  <img src="/images/logo.png" className="logo" alt="logo" />
                </NavLink>
              </span>
            </Nav>
          </Navbar>
        </div>
        <div className="col-md-12">
          <h4 className="secheading mb-4">
            Please begin your journey by setting up your calender
          </h4>
          {/* <p className="mb-4">please select your therapist</p> */}
        </div>
        <div className="row px-0">
          <div className="col-lg-5 mb-4 col-md-6">
            <div className="calendar inner-div h-100">
              {/* <Calendar
                onChange={onChange}
                value={value}
                tileDisabled={tileDisabled}
              /> */}
              <div className="row">
                <p className="mb-2">
                  <span className="text-danger fw-bold">*</span> Please Select
                  working days of the week
                </p>
                <div className="col-12 mb-3">
                  <div className="inner-container p-3">
                    <p className="mb-2">
                      <span className="text-danger fw-bold">*</span> Please
                      enter the no. of working days of the week
                    </p>
                    <div className="day-input-container">
                      <select
                        className="time-select"
                        defaultValue={daysOfWeek}
                        Value={daysOfWeek}
                        onChange={(e) => setDaysOfWeek(e.target.value)}
                      >
                        <option value={1}>01</option>
                        <option value={2}>02</option>
                        <option value={3}>03</option>
                        <option value={4}>04</option>
                        <option value={5}>05</option>
                        <option value={6}>06</option>
                        <option value={7}>07</option>
                      </select>
                      <span className="days-placeholder ms-2">days a week</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="inner-container days-container">
                    {days.map((item, index) => {
                      return (
                        <label
                          key={index}
                          className={
                            selectedDays.includes(item)
                              ? "days-active p-2 rounded-xl"
                              : "p-2"
                          }
                          onClick={() => {
                            handleClickDays(item);
                          }}
                        >
                          {item}
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="col-12">
                  <div className="time-pick mt-0 row">
                    <div className="col-6">
                      <p className="commmon-heading mb-1">
                        <span className="text-danger fw-bold">*</span> Start
                        Time
                      </p>
                      <select
                        className="time-select w-100"
                        onChange={(e) => handleTimeSlots(e)}
                      >
                        {timeSlots.map((ele, i) => {
                          return (
                            <>
                              {i !== timeSlots.length - 1 ? (
                                <option id={i} key={i} value={Object.keys(ele)}>
                                  {Object.keys(ele)}
                                </option>
                              ) : null}
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-6">
                      <p className="commmon-heading mb-1">
                        <span className="text-danger fw-bold">*</span> End Time
                      </p>
                      <select
                        disabled={isSetStartTime == 0 ? true : false}
                        className="time-select w-100"
                        onChange={(e) => handleSecondTimeSlot(e)}
                      >
                        {timeSlots.map((ele, i) => {
                          return (
                            <>
                              {i !== 0 ? (
                                <option
                                  key={i}
                                  disabled={disableindex >= i}
                                  id={i}
                                  value={Object.keys(ele)}
                                >
                                  {Object.keys(ele)}
                                </option>
                              ) : null}
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 mb-4 col-md-12">
            <div className="slot-container inner-div h-100 calender-slots">
              <p>Please select time slot you wish to exclude</p>
              {slots.map((ele, index) => {
                return (
                  <button
                    key={index}
                    disabled={isSetEndTime === false ? true : false}
                    // disabled={disableindex >= index + 1}
                    className={
                      pickedSlots.includes(ele) ? `slot perticular ` : `slot`
                    }
                    onClick={() => handleClickSlots(ele)}
                  >
                    {ele}
                  </button>
                );
              })}
            </div>
          </div>
          <h3 className="text-danger">{CalenderError}</h3>
          <div className="col-md-12 d-flex skip">
            <div>
              <button
                className="button_common mt-4 ms-0 next-btn"
                type="submit"
                role="button"
                // to={navigate(`../${AdminRoute?.Auth?.Dashboard}`)}
                onClick={(e) => submitCalender(e)}
              >
                Next
              </button>
              {loading ? <div className="loader_orange"></div> : <></>}
            </div>
          </div>
        </div>
      </div>
      {/* ... (your existing code) */}
    </div>
  );
};

export default Appointment_scheduler;
