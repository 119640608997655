import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import { toast } from "react-toastify";
import SuccessPop from "../Common/modal/SuccessPop";
import API from "../../Api/Api";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const path = useParams();
  const mobile = JSON.parse(localStorage.getItem("Mobileno"));
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [counter, setCounter] = useState(30);
  const [masbableMobile, setmasbableMobile] = useState();
  const [ApiError, setApiError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopUp] = useState(false);

  // const goBackFun = () => {
  //   navigate(-1);
  //   localStorage.removeItem("Mobileno");
  // };

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Code to execute after 30 seconds
    }, 30000);

    const interval = setInterval(() => {
      if (counter) {
        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [counter]);

  function handleChange(event, index) {
    const { value } = event.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });

      if (value.length === 1 && index < 3) {
        inputRefs[index + 1].current.focus();
      } else if (value.length === 0 && index > 0) {
        if (event.key === "Backspace") {
          inputRefs[index - 1].current.focus();
        }
      }
    }
  }
  const handleKeyDown = (event, index) => {
    const { value } = event.target;
    if (event.key === "Backspace") {
      if (value.length === 0 && index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };
  const newotp = otp.join("");

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    setApiError("");
    setIsSubmit(true);

    // console.log("locat", path.path)
    if (path.path == 2) {
      setLoading(true);
      try {
        API.Auth.VerifyViaMobile({
          data: {
            phone: mobile,
            otp: newotp,
            role: "Wellnessclient",
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            localStorage.setItem("AuthTokenWelnesscoach", response?.data?.token);
            localStorage.setItem(
              "WellnessCoachData",
              JSON.stringify(response?.data)
            );
            navigate(`../${AdminRoute?.Auth?.verifyOtpMail}/2`);
          } else {
            setApiError(response?.data?.message);
            // toast.error(response?.data?.message);
          }
          setLoading(false);
        });
      } catch (error) {
        setApiError(error?.message);
        console.log(error);
        setLoading(false);
      }
    }
    if (path.path == 1) {
      setLoading(true);
      try {
        API.Auth.VerifyViaMobile({
          data: {
            phone: mobile,
            otp: newotp,
            role: "Wellnessclient",
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            setApiError(response?.data?.message);
            localStorage.setItem("AuthTokenWelnesscoach", response?.data?.token);
            localStorage.setItem(
              "WellnessCoachData",
              JSON.stringify(response?.data)
            );
            setShowPopUp(true);
            setTimeout(() => {
              setShowPopUp(false);
              navigate(`../${AdminRoute?.Common?.Dashboard}`);
            }, 2000);
            // navigate(`../${AdminRoute?.Auth?.Dashboard}`);
          } else {
            setApiError(response?.data?.message);
            // toast.error(response?.data?.message);
          }
          setLoading(false);
        });
      } catch (error) {
        setApiError(error?.message);
        console.log(error);
        setLoading(false);
      }
    }
  };

  const handleResendOtp = (e) => {
    e.preventDefault();
    setApiError("");
    setLoading(true);
    try {
      API.Auth.LoginAPIMobile({
        data: {
          phone: mobile,
          role: "Wellnessclient",
          resend: "true",
        },
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          setApiError(response?.data?.message);
          // toast.success("Resent OTP");
        } else {
          setApiError(response?.data?.message);
        }
        setLoading(false);
      });
    } catch (error) {
      setApiError(error?.message);
      console.log(error);
      setLoading(false);
    }
  };

  // =====Mobile number hide======

  const mobilefun = () => {
    if (/^\d{10}$/.test(mobile)) {
      var numberString = mobile.toString();
      var lastFourDigits = numberString.slice(-4);
      var maskedPart = "X".repeat(numberString.length - 4);
      var maskedNumber = maskedPart + lastFourDigits;
      setmasbableMobile(maskedNumber);
    }
  };

  useEffect(() => {
    mobilefun();
  }, []);

  return (
    <>
      {showPopup ? (
        <SuccessPop text="Login Successful" />
      ) : (
        <div className="Otp">
          <div className="container login-page">
            <div className="row main-container">
              <div className="col-md-6 col-sm-12 left-text">
                <div className="logo-div">
                  <NavLink to="/">
                    <img src="/images/logo.png" className="logo" alt="logo" />{" "}
                  </NavLink>
                </div>
                <h1 className="heading">Reclaim control over your</h1>
                <p className="subheading">Mental Health journey</p>
                <p>
                  through our online therapy programs, guided by our
                  compassionate team of psychologists.
                </p>
                {/* <h1 className="heading">Take Charge of Your Mental Well-Being!</h1>
                <p className="mb-2 fw-bold">
                  Are you ready to:
                </p>
                <p className="ps-3 mb-0">-	Reduce stress and burnout</p>
                <p className="ps-3 mb-0">-	Feel happier and more productive</p>
                <p className="ps-3">-	Boost your mood and energy levels</p>
                <p className="subheading w-100">If yes, you’re in the right place! </p> */}
              </div>
              {/* col-md-6 */}

              <div className="col-md-6 col-sm-12">
                <div className="otp-div">
                  <h2 className="heading">Verify via Mobile OTP</h2>

                  <p>
                    <span className="text-danger fw-bold">*</span> Enter 4 digit
                    OTP sent on the registered{" "}
                    <b>&quot;{masbableMobile}&quot;</b>
                    mobile no.
                  </p>

                  <div
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        value={digit}
                        maxLength={1}
                        onChange={(event) => handleChange(event, index)}
                        onKeyDown={(event) => handleKeyDown(event, index)}
                        ref={inputRefs[index]}
                        autoComplete="off"
                        // onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                        // ref={(reference) =>
                        //   (otpBoxReference.current[index] = reference)
                        // }
                        className="form-control custom-input-text-primary otp-input mb-0"
                      />
                    ))}
                  </div>
                  <p className="text-danger mb-0 mt-2">{ApiError}</p>
                  <button
                    className="button_common"
                    disabled={isSubmit}
                    onClick={(e) => handleVerifyOtp(e)}
                  >
                    Verify and Proceed
                  </button>

                  {loading ? <div className="loader_orange"></div> : <></>}

                  {/* {counter === 0 ? (
                <p
                  className="orange-small-link text-center cursor-pointer text-decoration-underline"
                  onClick={(e) => handleResendOtp(e)}
                >
                  Resend OTP
                </p>
              ) : (
                <h3 className="text-center text-color-secondary">
                  {counter == 30
                    ? `00:30`
                    : counter >= 10
                    ? `00:${counter}`
                    : `00:0${counter}`}
                </h3>
              )} */}

                  <p
                    className="orange-small-link text-center text-decoration-underline cursor_pointer resend-otp"
                    onClick={(e) => handleResendOtp(e)}
                  >
                    Resend OTP
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyOtp;
