import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { ArrowLeft } from "react-bootstrap-icons";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import userImg from "../../Assets/Images/icons/userIMG.jpg";
// import { toast } from "react-toastify";

const Register2 = () => {
  const navigate = useNavigate();
  const regdata = JSON.parse(localStorage.getItem("RegUserData"));
  const ProfileImg = JSON.parse(localStorage.getItem("ProfileImage"));
  const [loading, setLoading] = useState(false);

  const [ApiError, setApiError] = useState("");

  const goBackFun = () => {
    navigate(-1);
    localStorage.removeItem("RegUserData");
    localStorage.removeItem("ProfileImage");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("Mobileno");
  };

  const initialValues = {
    YearOfExp: "",
    Expertise: "",
    Quote: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const RegisterValidate = (values) => {
    // console.log("===> ok", values);
    const errors = {};

    if (!values?.YearOfExp) {
      errors.YearOfExp = "Year of Experience is required";
    }
    if (!values?.Expertise) {
      errors.Expertise = "Your Expertise is required";
    }
    if (!values?.Quote) {
      errors.Quote = "Quote is required";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setLoading(true);
      try {
        API.Auth.Register({
          data: {
            fullname: `${regdata?.Name}${" " + regdata?.Surname}`,
            email: regdata?.Email,
            phone: regdata?.PhoneNumber,
            // age: regdata?.Age,
            dob: regdata?.birthDate,
            gender: regdata?.Gender,
            role: "Wellnessclient",
            experience: formData?.YearOfExp,
            experties: formData?.Expertise,
            qote: formData?.Quote,
            image: ProfileImg,
          },
        }).then((response) => {
          // console.log(response?.data);

          if (response?.data?.message === "User create successfully") {
            setApiError(response?.data?.message);
            localStorage.setItem("EmailId", response?.data?.data?.email);
            localStorage.setItem("Mobileno", response?.data?.data?.phone);
            navigate(`../${AdminRoute?.Auth?.VerifyOtp}/2`);
          }
          if (response?.data?.message === "User Allready exists") {
            setApiError(response?.data?.message);
            setApiError(response?.data?.message);
            // toast.error(response?.data?.message);
          }
          localStorage.removeItem("RegUserData");
          localStorage.removeItem("ProfileImage");
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setApiError(error.message);
        setLoading(false);
        // toast.error(error.message);
        // console.log("=====>", error);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
  };

  return (
    <>
      <div className="container">
        <div className="row main-container">
          <div className="col-md-6 col-sm-12 left-text">
            {/* <div className="logo-div">
              <NavLink to="/">
                <img src="/images/logo.png" className="logo" alt="logo" />{" "}
              </NavLink>
            </div> */}
            <h1 className="heading">
              Begin your transformative with our online therapy.{" "}
            </h1>
            {/* <p className="subheading">Mental Health journey</p> */}
            <p>
              Navigate your path to well-being with our dedicated psychologists
              by your side, every step of the way.
            </p>
          </div>
          {/* col-md-6 */}
          <div className="col-md-6 col-sm-12">
            <div className="register">
              <h2 className="heading mb-4">
                {" "}
                <NavLink to="/">
                  {/* <ArrowLeft className="left-arrow" size={16} /> */}
                  <i className="fa fa-chevron-left left-arrow"></i>
                </NavLink>
                Registration
              </h2>
              <form>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label>
                      <span className="text-danger fw-bold">*</span> Years of
                      Experince:
                    </label>
                    <input
                      type="number"
                      id="myNumberInput"
                      name="YearOfExp"
                      placeholder="Enter Years of Experince"
                      required
                      onChange={(e) => handleChange(e)}
                      autoComplete="off"
                    />
                    <p className="text-danger">{formErrors?.YearOfExp}</p>
                  </div>

                  <div className="col-12 mb-3">
                    <label className="mb-2">
                      <span className="text-danger fw-bold">*</span> Your
                      Expertise:
                    </label>
                    <textarea
                      rows="4"
                      name="Expertise"
                      className="form-input-text-area"
                      placeholder="Enter your Expertise"
                      onChange={(e) => handleChange(e)}
                      autoComplete="off"
                    ></textarea>
                    <p className="text-danger">{formErrors?.Expertise}</p>
                  </div>

                  <div className="col-12 mb-2">
                    <label>
                      <span className="text-danger fw-bold">*</span> Quote you
                      resonate with:
                    </label>

                    <input
                      type="text"
                      name="Quote"
                      placeholder="Enter the Quote you resonate with"
                      onChange={(e) => handleChange(e)}
                      autoComplete="off"
                    />
                    <p className="text-danger">{formErrors?.Quote}</p>
                    {/* {error && <p className="text-danger">{error}</p>} */}
                  </div>
                  <p className="text-danger"> {ApiError}</p>
                </div>

                <button
                  className="button_common"
                  onClick={(e) => handleSubmit(e)}
                  disabled={
                    isSubmit === true && Object.keys(formErrors).length === 0
                      ? true
                      : false
                  }
                >
                  Next
                </button>

                {loading ? <div className="loader_orange"></div> : <></>}
              </form>
            </div>
          </div>
          {/* col-md-6 */}
        </div>
      </div>
      {/* end of container */}
    </>
  );
};

export default Register2;
