import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import API from "../../Api/Api";
import moment from "moment";
import plusIcon from "../../Assets/Images/Icon feather-plus.png";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // height: "500px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#FFF",
    borderRadius: "20px",
    paddingTop: "20px",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0px 0px 24px -10px #bdbdbd",
  },
};

const MyChecklist = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const [loading, setLoading] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [list, setList] = useState([]);
  const [tab, setTab] = useState("today");

  const initialValues = {
    Title: "",
    Description: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  //   const [PendingCount, setPendingCount] = useState();

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  //   function OpenViewModal() {
  //     setviewmodalIsOpen(true);
  //   }

  //   function afterViewOpenModal() {
  //     // references are now sync'd and can be accessed.
  //   }

  //   function closeViewModal() {
  //     setviewmodalIsOpen(false);
  //   }

  const renderList = (Status) => {
    // console.log(Status);
    setLoading(true);
    try {
      API.MyChecklist.TaskList({
        data: {
          created_by: UserData?.userdata?._id,
          status: Status, // pass status accordingly
        },
      }).then((res) => {
        // console.log("Task list", res?.data?.data?.length);

        // if (res?.data?.data?.status === "pending") {
        //   setPendingCount(res?.data?.data?.length);
        // }
        setList(res?.data?.data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSelect = (key) => {
    // if (key === "today") {
    //   renderList("today");
    //   setTab(key);
    // }
    if (key === "pending") {
      renderList("pending");

      setTab(key);
    }
    if (key === "completed") {
      renderList("completed");
      setTab(key);
    }
  };

  const handleClickCompleted = (val) => {
    // console.log("val========", val);
    try {
      API.MyChecklist.UpdateTask({
        data: {
          status: "completed",
        },
        id: val,
      }).then((response) => {
        // console.log(response?.data);
        if (response.data.status === 1) {
          renderList("pending");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    renderList("pending");
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const Validate = (values) => {
    // console.log("===> ok", values);
    const errors = {};

    if (!values?.Title) {
      errors.Title = "Title is required!";
    }

    if (!values?.Description) {
      errors.Description = "Description is required!";
    }

    return errors;
  };

  useEffect(() => {
    // console.log("Form errors", formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      //   console.log("Create Task Data", {
      //     title: formData?.Title,
      //     description: formData?.Description,
      //     created_by: UserData?.userdata?._id,
      //   });

      setisLoading(true);
      try {
        API.MyChecklist.CreateTask({
          data: {
            title: formData?.Title,
            description: formData?.Description,
            created_by: UserData?.userdata?._id,
            // status: "pending", // pass status accordingly
            // createdAt: `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`,
          },
        }).then((res) => {
          if (res.data.status === 1) {
            renderList("pending");
            closeModal();
            setisLoading(false);
          }
        });
      } catch (error) {
        console.log(error);
        setisLoading(false);
      }
    }
  }, [formErrors]);

  const CreateNotes = (e) => {
    e.preventDefault();
    setFormErrors(Validate(formData));
    setIsSubmit(true);
  };

  //jsx
  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className="w-100 h-head">
                  <h2 className="heading  mt-1 mb-1">My Checklist</h2>
                  <Button
                    type="button"
                    onClick={openModal}
                    className="create-new_notes button_common notes-btn transparent-btn"
                  >
                    Create New
                  </Button>
                </div>
                <Header />
              </nav>
            </div>

            <div className="row">
              <div className="col-12">
                <p>Here is list of your tasks.</p>
              </div>

              <span className="my-checklist-modal">
                <Modal
                  isOpen={modalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                >
                  <button onClick={closeModal} className="close-btn ">
                    <img src={plusIcon} />{" "}
                  </button>
                  <div>
                    <input
                      type="text"
                      placeholder="My Task "
                      className="mb-3 my-task-add"
                      disabled
                    />
                    <p>Please list down your today task</p>
                    <input
                      type="text"
                      placeholder="Enter Title "
                      name="Title"
                      onChange={(e) => handleChange(e)}
                    />
                    <p className="text-danger">{formErrors.Title}</p>
                    <br />
                    <textarea
                      name="Description"
                      onChange={(e) => handleChange(e)}
                      cols="85"
                      rows="10"
                      className="form-input-text-area"
                      placeholder="My Activity"
                    ></textarea>
                    <p className="text-danger">{formErrors.Description}</p>
                  </div>
                  <button
                    type="button"
                    className="button_common w-100 mt-2 mb-0 text-center"
                    role="button"
                    // onClick={(e) => submit(e)}
                    onClick={(e) => CreateNotes(e)}
                  >
                    Save
                  </button>

                  {isLoading ? <div className="container_loader"></div> : <></>}
                </Modal>
                {/* **************** View Modal Starts ************************** */}
                {/* <Modal
                  isOpen={viewmodalIsOpen}
                  onAfterOpen={afterViewOpenModal}
                  onRequestClose={closeViewModal}
                  style={customStyles}
                >
                  <button onClick={closeViewModal} className="close-btn ">
                    <img src={plusIcon} />{" "}
                  </button>
                  <div>
                    <input
                      type="text"
                      placeholder="My View Task "
                      className="mb-3 my-task-add"
                      disabled
                    />
                    <input
                      type="text"
                      placeholder="Enter Title "
                      className="mb-3 "
                      name="Title"
                      readOnly
                      // defaultValue={NotesDetails?.title ? NotesDetails?.title : ""}
                    />
                    <p>Please list down your today task</p>
                    <textarea
                      rows="8"
                      name="Description"
                      className="form-input-text-area"
                      placeholder="My Activity"
                      readOnly
                      // defaultValue={NotesDetails?.description}
                    ></textarea>
                  </div>
                  <button
                    disabled
                    type="submit"
                    className="button_common w-100 mt-2 mb-0 text-center"
                    role="button"
                  >
                    Done
                  </button>
                </Modal> */}
                {/* ************View Modal Starts ******************* */}
              </span>
            </div>

            <div className="col-md-12 mb-2 list-item my-checklist-v-height">
              <div className="sessions-tabs mt-2 my-checklist-wrap">
                <Tabs
                  defaultActiveKey="pending"
                  id="uncontrolled-tab-example"
                  className="mb-3 px-0"
                  onSelect={handleSelect}
                >
                  {/* <Tab eventKey="today" title="Today">
                    <div className="employee-list">
                      <div className="row mt-2 gx-3">
                        {loading ? (
                          <div className="container_loader"></div>
                        ) : (
                          list?.length &&
                          list?.map((val) => {
                            return (
                              <div className="col-12 col-md-4">
                                <div className="activity-card cursor-pointer">
                                  <div>
                                    <div className="v-div new"></div>
                                    <p className="card-header">{val?.title}</p>
                                    <p className="activity-lbl mb-3">
                                      {val?.description}
                                    </p>
                                    <p className="activity-time-lbl">
                                      {moment(val?.createdAt).format("LLLL")}
                                    </p>
                                  </div>
                                  <span>
                                    <label className="check_container">
                                      <input
                                        type="checkbox"
                                        value={val?._id}
                                        onChange={() =>
                                          handleClickCompleted(val?._id)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </Tab> */}
                  <Tab eventKey="pending" title="Pending">
                    {/* <p className="count-box ms-2 text-center my-0">
                      {PendingCount}
                    </p> */}
                    <div className="employee-list">
                      <div className="row mt-2 gx-3">
                        {loading ? (
                          <div className="container_loader"></div>
                        ) : (
                          list?.length &&
                          list?.map((val, i) => {
                            return (
                              <div className="col-12 col-md-4" key={i}>
                                <div className="activity-card cursor-pointer">
                                  <div>
                                    <div className="v-div new-pending"></div>
                                    <p className="card-header">{val?.title}</p>
                                    <p className="activity-lbl mb-3">
                                      {val?.description}
                                    </p>
                                    <p className="activity-time-lbl">
                                      {moment(val?.createdAt).format("LLLL")}
                                    </p>
                                  </div>
                                  <span>
                                    <label className="check_container">
                                      <input
                                        type="checkbox"
                                        value={val?._id}
                                        onClick={() =>
                                          handleClickCompleted(val?._id)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="completed" title="Completed">
                    <div className="employee-list">
                      <div className="row mt-2 gx-3">
                        {loading ? (
                          <div className="container_loader"></div>
                        ) : (
                          list?.length &&
                          list?.map((val, i) => {
                            return (
                              <div className="col-12 col-md-4" key={i}>
                                {/* <div className="activity-card cursor-pointer">
                                  <div>
                                    <div className="v-div new-completed"></div>
                                    <p className="card-header">
                                      Create Activity
                                    </p>
                                    <p className="activity-lbl mb-3">
                                      Here is an overview of your session’s
                                      latest a…
                                    </p>
                                    <p className="activity-time-lbl">
                                      Today 2:00 pm
                                    </p>
                                  </div>
                                </div> */}
                                <div className="activity-card cursor-pointer">
                                  <div>
                                    <div className="v-div new-completed"></div>
                                    <p className="card-header">{val?.title}</p>
                                    <p className="activity-lbl mb-3">
                                      {val?.description}
                                    </p>
                                    <p className="activity-time-lbl">
                                      {moment(val?.createdAt).format("LLLL")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MyChecklist;
