import BaseApi from "./BaseApi";

const API = {
  // auth apis
  Auth: {
    LoginAPIEmail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/resendotp`,
        data: props.data,
        tokenRequired: false,
      });
    },
    LoginAPIMobile: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/mresendotp`,
        data: props.data,
        tokenRequired: false,
      });
    },
    Register: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/create`,
        data: props.data,
        tokenRequired: false,
      });
    },
    VerifyViaMobile: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/verify/otpphon`,
        data: props.data,
        tokenRequired: false,
      });
    },
    VerifyViaMail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/verify/otpmail`,
        data: props.data,
        tokenRequired: true,
      });
    },

    CalenderUpdate: (props) => {
      return BaseApi({
        method: "PUT",
        url: `users/theropistcalender/${props.UserId}`,
        data: props.data,
        tokenRequired: true,
      });
    },
    MyOrg: () => {
      return BaseApi({
        method: "GET",
        // Old url
        // url: `users/theropist?key=Orgadmin&page=1&size=10`,
        // New url
        url: `users/orgemployee`,
        tokenRequired: true,
      });
    },

    Theme: () => {
      return BaseApi({
        method: "GET",
        url: `theme/themelist`,
        tokenRequired: true,
      });
    },
    SelectThemeById: (props) => {
      return BaseApi({
        method: "PUT",
        url: `users/${props.UserId}`,
        data: props.data,
        tokenRequired: true,
      });
    },

    // digital Notes api
    // digital notes list api
    DigitalNotesList: () => {
      return BaseApi({
        method: "GET",
        url: `notes/notelist`,
        tokenRequired: true,
      });
    },
    // digital notes list details api

    DigitalNotesDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `notes/notelist/${props.notes_id}`,
        tokenRequired: true,
      });
    },

    // update user profile api
    UpdateProfileAPI: (props) => {
      return BaseApi({
        method: "PUT",
        url: `users/${props.UserId}`,
        data: props.data,
        tokenRequired: true,
      });
    },

    //api  call for side bar info on after sign in page
    InfoInfoListAPI: (props) => {
      return BaseApi({
        method: "Get",
        url: `info/infolist?key=${props.helpus}`,
        tokenRequired: false,
      });
    },

    //api  call for Plan list
    PlanPlanListAPI: () => {
      return BaseApi({
        method: "GET",
        url: `plan/planlist`,
        tokenRequired: false,
      });
    },

    //PlayGround API call
    // Mental Health Resources
    Mental_HealthAPI: (props) => {
      return BaseApi({
        method: "GET",
        url: `info/infolist?key=${props.health}`,
        tokenRequired: false,
      });
    },

    // Videos
    Videos_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/info/infolist?key=${props.kind}`,
        tokenRequired: false,
      });
    },

    // Self Assessment
    SelfAsses_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/info/infolist?key=${props.selfAsses}`,
        tokenRequired: false,
      });
    },

    //Group apis

    // Group Support
    Support_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groups/grouplist?key=${props.support}&name=${
          props.serchKey ? props.serchKey : ""
        }&ingroup=${props.ingroup ? props.ingroup : ""}`,
        tokenRequired: true,
      });
    },
    Support_APIAll: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groups/grouplistall?type=${props.support}`,
        tokenRequired: true,
      });
    },
    Focussed_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groups/grouplist?key=${props.focused}&name=${
          props.serchKey ? props.serchKey : ""
        }&ingroup=${props.ingroup ? props.ingroup : ""}`,
        tokenRequired: true,
      });
    },
    //therpist list
    TherpistList_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/theropist?key=${props.Therapist}&page=${props.page}&name=${props.name}`,
        tokenRequired: true,
      });
    },
  },
  Therapist: {
    TherpistAdd: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/theropistAdd`,
        data: props.data,
        tokenRequired: true,
      });
    },
    getTherpistDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/theropistdtls?date=${props.date}`,
        tokenRequired: true,
      });
    },
    CreatMessageid: (props) => {
      return BaseApi({
        method: "POST",
        url: `/aiconnect/creatmessageid`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Chatapi: (props) => {
      return BaseApi({
        method: "POST",
        url: `/aiconnect/chatapi`,
        data: props.data,
        tokenRequired: true,
      });
    },
    ChatHistory: (props) => {
      return BaseApi({
        method: "GET",
        url: props.msgId
          ? `/aiconnect/aichatlist?messageid=${props.msgId}`
          : `/aiconnect/aichatlist`,
        tokenRequired: true,
      });
    },
  },
  MyThreapist: {
    ChatList: () => {
      return BaseApi({
        method: "GET",
        url: `/users/messages`,
        tokenRequired: true,
      });
    },
    Aichatlisthistory: () => {
      return BaseApi({
        method: "GET",
        url: `/aiconnect/aichatlisthistory`,
        tokenRequired: true,
      });
    },
    eventlistindividual: () => {
      return BaseApi({
        method: "GET",
        url: `/event/eventlistindividual`,
        tokenRequired: true,
      });
    },
    meadialistindividual: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groupmedia/medialistindividual?id=${props.Userid}`,
        tokenRequired: true,
      });
    },
  },
  Session: {
    CreateSession: (props) => {
      return BaseApi({
        method: "POST",
        url: `/session/createsession`,
        data: props.data,
        tokenRequired: true,
      });
    },
    //geeta
    upcomingSessionList: (props) => {
      return BaseApi({
        method: "GET",
        url: `cmsSession/upcomingCmssessiontheropistlist?theropist=${props.UserId}`,
        tokenRequired: true,
      });
    },
    //geeta
    sumarySessionList: (props) => {
      return BaseApi({
        method: "GET",
        // url: `/session/sumarysessionlisttheropist?theropist=${props.UserId}`,
        url: `/session/sumarysessioncmslisttheropist?theropist=${props.UserId}`,
        tokenRequired: true,
      });
    },

    //geeta
    SessionDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `/cmsSession/cmsSessiondetails/${props.SessionId}`,
        tokenRequired: true,
      });
    },

    currentSessionList: () => {
      return BaseApi({
        method: "GET",
        url: `/session/currentsessionlist`,
        tokenRequired: true,
      });
    },
    DeleteSession: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/flushSession?id=${props.id}`,
        tokenRequired: true,
      });
    },

    UpdateSession: (props) => {
      return BaseApi({
        method: "PUT",
        // url: `/cmsSession/cmsUpdatesession/${props.clientId}`,
        url: `/cmsSession/updateCmssession/${props.clientId}`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  Notes: {
    UpdateNotes: (props) => {
      return BaseApi({
        method: "PUT",
        url: `/notes/updatenote/${props.update_id}`,
        data: props.data,
        tokenRequired: true,
      });
    },
    NoteResponseList: (props) => {
      return BaseApi({
        method: "GET",
        url: `notes/notelist/${props.NoteId}`,
        tokenRequired: true,
      });
    },
  },
  Group: {
    CreateGroup: (props) => {
      return BaseApi({
        method: "POST",
        url: `cmsGroups/createCmsgroup`,
        data: props.data,
        tokenRequired: true,
      });
    },
    GroupList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/cmsGroups/cmsGrouplist?${
          props.Grouptype ? "key=" + props.Grouptype : ""
        }${props.serchKey ? "&name=" + props.serchKey : ""}&istheropist=yes`,
        tokenRequired: true,
      });
    },
    Groupcount: (props) => {
      return BaseApi({
        method: "POST",
        url: `/groups/groupcount`,
        data: props.data,
        tokenRequired: true,
      });
    },
    GroupsChatList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/chat/chatlist/${props.group_id}?userid=${props.userId}`,
        tokenRequired: true,
      });
    },
    EventList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/event/cmseventlist?key=${props.group_id}`,
        tokenRequired: true,
      });
    },
    Medialist: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groupmedia/medialist?key=${props.group_id}`,
        tokenRequired: true,
      });
    },
    SelectGroupMembers: (props) => {
      return BaseApi({
        method: "POST",
        url: `cmsGroups/joinCmsgroup`,
        // url: `http://localhost:4209/cmsGroups/cmsJoinGrouplist`,
        data: props.data,
        tokenRequired: true,
      });
    },
    //delete a member
    RemoveGroupMembers: (props) => {
      return BaseApi({
        method: "POST",
        url: `cmsGroups/removeGroupMember`,
        // url: `http://localhost:4209/cmsGroups/cmsJoinGrouplist`,
        data: props.data,
        tokenRequired: true,
      });
    },
    //delete group
    RemoveGroup: (props) => {
      return BaseApi({
        method: "POST",
        url: `cmsGroups/removeGroup`,
        // url: `http://localhost:4209/cmsGroups/cmsJoinGrouplist`,
        data: props.data,
        tokenRequired: true,
      });
    },
    SelectGroupMemberList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/superadmin/viewEmployee?Companyname=${props.orgname}`,
        tokenRequired: true,
      });
    },

    Event: {
      CreateEvent: (props) => {
        return BaseApi({
          method: "POST",
          url: `event/createevent`,
          data: props.data,
          tokenRequired: true,
        });
      },
      CreateMedia: (props) => {
        return BaseApi({
          method: "POST",
          url: `groupmedia/createmedia`,
          data: props.data,
          tokenRequired: true,
        });
      },
    },

    // JoinGroups: (props) => {
    //   return BaseApi({
    //     method: "POST",
    //     url: `/groups/joingroup`,
    //     data: props.data,
    //     tokenRequired: true,
    //   });
    // },
    // GroupsChatList: (props) => {
    //   return BaseApi({
    //     method: "GET",
    //     url: `/chat/chatlist/${props.group_id}?userid=${props.userId}`,
    //     tokenRequired: true,
    //   });
    // },

    // AnswerEvent: (props) => {
    //   return BaseApi({
    //     method: "POST",
    //     url: `/event/answerevent`,
    //     data: props.data,
    //     tokenRequired: true,
    //   });
    // },
    // Groupcount: (props) => {
    //   return BaseApi({
    //     method: "POST",
    //     url: `/groups/groupcount`,
    //     data: props.data,
    //     tokenRequired: true,
    //   });
    // }
  },
  Survey: {
    Surveylist: (props) => {
      return BaseApi({
        method: "GET",
        url: `/survaytype/survaylist?title=${props.title}`,
        tokenRequired: true,
      });
    },
    AnswerEvent: (props) => {
      return BaseApi({
        method: "POST",
        url: `/event/answerevent`,
        data: props.data,
        tokenRequired: true,
      });
    },
    SubmitSurvay: (props) => {
      return BaseApi({
        method: "POST",
        url: `/survaytype/answersurvey`,
        data: props.data,
        tokenRequired: true,
      });
    },
    AnswerSurvey: () => {
      return BaseApi({
        method: "GET",
        url: `/survaytype/answersurvey`,
        tokenRequired: true,
      });
    },
    Wellness: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/welness`,
        data: props.data,
        tokenRequired: true,
      });
    },
    GetTimit: () => {
      return BaseApi({
        method: "GET",
        url: `/survaytype/getlimit`,
        tokenRequired: true,
      });
    },
  },
  Payment: {
    RezorPayGetId: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/userpay`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Paymentupdate: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/paymentupdate`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Adduserplan: (props) => {
      return BaseApi({
        method: "POST",
        url: `/plan/adduserplan`,
        data: props.data,
        tokenRequired: true,
      });
    },
    MyPlanlfeatures: () => {
      return BaseApi({
        method: "GET",
        url: `/plan/planlfeatures`,
        tokenRequired: true,
      });
    },
    FeaturePriceList: () => {
      return BaseApi({
        method: "GET",
        url: `/users/featurepricelist`,
        tokenRequired: true,
      });
    },
    paymentHistory: () => {
      return BaseApi({
        method: "GET",
        url: `/users/paymenthistory`,
        tokenRequired: true,
      });
    },
  },
  NotificationApis: {
    getAllnotificationApi: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/notification`,
        tokenRequired: true,
      });
    },
  },
  MyChecklist: {
    TaskList: (props) => {
      return BaseApi({
        method: "POST",
        url: `/task/tasklist`,
        data: props.data,
        tokenRequired: true,
      });
    },
    CreateTask: (props) => {
      return BaseApi({
        method: "POST",
        url: `/task/createTask`,
        data: props.data,
        tokenRequired: true,
      });
    },
    UpdateTask: (props) => {
      return BaseApi({
        method: "PUT",
        url: `/task/updateTask/${props.id}`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  //geeta
  Profile: {
    GetProfileData: () => {
      return BaseApi({
        method: "GET",
        url: `users/userinfo`,
        tokenRequired: true,
      });
    },
    UpdateProfileAPI: (props) => {
      return BaseApi({
        method: "PUT",
        url: `users/${props.UserId}`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  EmployeeTab: {
    // ===== old api =======
    // ActiveEmployee: (props) => {
    //   return BaseApi({
    //     method: "GET",
    //     // url: `/users/theropist?key=Cmsemployee&page=1&size=10`,
    //     url: `/users/theropist?key=Cmsemployee&page=1&size=10&is_authorized=${props.isAuthorised}`,
    //     // data: props.data,
    //     tokenRequired: true,
    //   });
    // },
    // ===== new api fot active employee ========
    ActiveEmployee: (props) => {
      return BaseApi({
        method: "GET",
        // url: `/users/theropist?key=Cmsemployee&page=1&size=10`,
        url: `/session/upcomingsessiontheropistlistclient?theropist=${props.UserId}&is_active=1`,
        // data: props.data,
        tokenRequired: true,
      });
    },

    InActiveEmployee: (props) => {
      return BaseApi({
        method: "GET",
        // url: `/users/theropist?key=Cmsemployee&page=1&size=10`,
        url: `/session/upcomingsessiontheropistlistclient?theropist=${props.UserId}&is_active=0`,
        // data: props.data,
        tokenRequired: true,
      });
    },
    EmployeeProfileDetails: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/viewProfile`,
        data: props.data,
        tokenRequired: true,
      });
    },

    EmployeeSessionHistoryList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/cmsSession/cmsSumarysessionlisttheropist?client=${props.ClientId}&theropist=${props.TherapistID}`,
        tokenRequired: true,
      });
    },

    EmployeeDigitalNotesList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/notes/usernotelist?id=${props.UserId}`,
        tokenRequired: true,
      });
    },

    EmployeeNotesDetailbyId: (props) => {
      return BaseApi({
        method: "GET",
        url: `notes/notelist/${props.NoteId}`,
        data: props.data,
        tokenRequired: true,
      });
    },

    CreateMediaIndividual: (props) => {
      return BaseApi({
        method: "POST",
        url: `groupmedia/createmedia`,
        data: props.data,
        tokenRequired: true,
      });
    },
    UpcomingListdashboard: (props) => {
      return BaseApi({
        method: "GET",
        url: `/cmsSession/currentCmssessionlisttheropist`,
        tokenRequired: true,
      });
    },
  },
  JoinSession: {
    Joinmeeting: (props) => {
      return BaseApi({
        method: "POST",
        url: `session/joinmeeting`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  Refer: {
    CreateRefer: (props) => {
      return BaseApi({
        method: "POST",
        url: `/referrel/createrefer`,
        data: props.data,
        tokenRequired: true,
      });
    },
    MyreferralList: () => {
      return BaseApi({
        method: "GET",
        url: `/referrel/referlist`,
        tokenRequired: true,
      });
    },
    refertoCoachlist: (props) => {
      return BaseApi({
        method: "GET",
        url: `/superadmin/coachList?role=Wellnessclient&is_authorized=1`,
        tokenRequired: true,
      });
    },
  },
  Leaders: {
    LeaderEmployee: (props) => {
      return BaseApi({
        method: "GET",
        // url: `/users/theropist?key=Cmsemployee&page=1&size=10`,
        // url: `/session/upcomingsessiontheropistlistclient?theropist=${props.UserId}&is_active=1`,
        url: `/session/upcomingsessiontheropistlead?theropist=${
          props?.UserId
        }&is_active=1&is_lead=${props?.isLead}${
          props?.org_name ? "&org_name=" + props?.org_name : ""
        }`,
        // data: props.data,
        tokenRequired: true,
      });
    },

    LeaderEmployeeInactive: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/csvuploadeduserscoach?isActive=1&lead=${
          props.isLead
        }&page=1&size=50${
          props?.org_name ? "&org_name=" + props?.org_name : ""
        }`,
        tokenRequired: true,
      });
    },
  },
};

export default API;
