import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Header from "../../Components/Common/Header";
import Footer from "../../Components/Common/Footer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import io from "socket.io-client";
import { formatDateTime } from "../Common/Functions";
import API from "../../Api/Api";
import PlayGround from "../../Assets/Images/playground.jpg";
import moment from "moment";
import baseApi from "../../Api/config";

// const socket = io("https://ai.rytlife.com", { path: "/api/socket.io" });
//for production
// const socket = io(process.env.REACT_APP_BASE_URL_SOCKET, {
//   path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
// });

//for dev
// const URL = "http://localhost:4210";
const URL = process.env.REACT_APP_BASE_URL_SOCKET;

// Create a Socket.IO connection
const socket = io(URL, {
  path: `/socket.io`, // This matches the path used in your backend
});


const MyTherapist = () => {
  const [room] = useState("messageroom");
  const [currMessage, setCurrMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [rows, setRows] = useState(1);
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const Therapistid = localStorage.getItem("TherapistID");
  const [therapistData, setTherapishData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSubmitAns, setLoadingSubmitAns] = useState(false);
  const [allActivityData, setAllActivityData] = useState([]);
  const [allMediaData, setAllMediaData] = useState([]);

  useEffect(() => {
    getAllChats();
    getTherapistDetails();
    getAllEventData();
    getAllMedia();
  }, []);

  const getTherapistDetails = () => {
    setLoading(true);
    API.Therapist.getTherpistDetails({
      date: moment(new Date()).format("YYYY-MM-DD"),
    })
      .then((response) => {
        if (response.data.status === 1) {
          setTherapishData(response?.data.data_all[0]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getAllChats = (val) => {
    // setLoadingChat(true)
    API.MyThreapist.ChatList()
      .then((response) => {
        if (response.data.status === 1) {
          setMessageList(response.data.data_all);
          // setLoadingChat(false)
        }
      })
      .catch((error) => {
        console.log(error);
        // setLoadingChat(false)
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      setRows(1);
      sendMessage(e);
      e.preventDefault();
    } else if (e.key === "Enter" && e.shiftKey) {
      if (rows < 6) {
        setRows(rows + 1);
      }
      const cursorPosition = e.target.selectionStart;
      const newInputValue =
        currMessage.substring(0, cursorPosition) +
        "\n" +
        currMessage.substring(cursorPosition);
      setCurrMessage(newInputValue);
      e.preventDefault();
    }
  };

  //socket .io
  const sendMessage = async () => {
    if (currMessage !== "") {
      const messageData = {
        author: `${UserData?.userdata?.name} ${UserData?.userdata?.last_name}`,
        created_id: UserData?.userdata?._id,
        receiver_id: Therapistid
          ? Therapistid
          : UserData?.data_theropist[0]?.theropistId,
        message: currMessage,
        type: "all",
      };
      await socket.emit(room, messageData);
    }
    setCurrMessage("");
  };

  useEffect(() => {
    socket.on(room, (data) => {
      setMessageList((list) => [...list, data]);
    });
    return () => {
      socket.removeListener(room);
    };
  }, [socket]);

  const getAllMedia = () => {
    API.MyThreapist.meadialistindividual({
      Userid: UserData?.userdata?._id,
    })
      .then((response) => {
        if (response.data.status === 1) {
          setAllMediaData(response?.data?.data_all);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllEventData = () => {
    API.MyThreapist.eventlistindividual()
      .then((response) => {
        if (response.data.status === 1) {
          setAllActivityData(response?.data?.data_all);
        }
      })
      .catch((error) => {
        // setLoadingChat(false)
      });
  };

  const handleSubmitAns = (val, option) => {
    if (!loadingSubmitAns) {
      setLoadingSubmitAns(true);
      const requestData = {
        event_id: val._id,
        created_by: UserData?.userdata?._id,
      };
      requestData[option] = true;
      API.Groups.AnswerEvent({
        data: requestData,
      })
        .then((response) => {
          if (response.data.status === 1) {
            setLoadingSubmitAns(false);
            getAllEventData();
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingSubmitAns(false);
        });
    }
  };

  const handleDownload = async (imageUrl) => {
    let extension = imageUrl.split(".").pop();
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `image.${extension}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    console.log(messageList);
  }, [messageList]);

  return (
    <div className="d-flex h-dashboard playground">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list dashboard therapist">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className="w-100 h-head">
                  <h2 className="heading p-0">My Therapist</h2>
                </div>
                <Header />
              </nav>
            </div>

            <div className="col-lg-9 col-md-7 col-sm-7 mb-2 list-item">
              <div className="dashboard-inner mb-0 h-100">
                <div className="col-md-12  mb-2 list-item">
                  <div className="sessions-tabs">
                    <Tabs
                      defaultActiveKey="home"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      {/* <Tab eventKey="activity" title="Activity">
                        <div className="d-flex flex-end">
                          <div className="chat">
                            <div className="chat-history">
                              <ul>
                                {allActivityData.length === 0 ? (
                                  <span>Data not found </span>
                                ) : (
                                  allActivityData?.map((val) => {
                                    return (
                                      <React.Fragment key={val.data._id}>
                                        {val.data.type?.toLowerCase() ===
                                          "poll" ||
                                          val.data.type?.toLowerCase() ===
                                          "quiz" ? (
                                          <li className="clearfix">
                                            <div className="message other-message">
                                              <div className="activity-width">
                                                <h6>
                                                  <span>
                                                    {" "}
                                                    <img
                                                      src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg"
                                                      alt="avatar"
                                                    />
                                                  </span>
                                                  <span className="ml-piv">
                                                    Discussion Joshi
                                                  </span>
                                                </h6>
                                                <h4>{val.data?.question}</h4>
                                                <p>{val.data?.description}</p>
                                                <div className="message-data-time message-data">
                                                  {moment(
                                                    val.data?.startDate
                                                  ).format("LL")}{" "}
                                                  {val.data?.startTime}
                                                </div>

                                                {val?.isresSubmited ? (
                                                  <>
                                                    <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                      <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                          width: `${((val?.score
                                                            ?.option1 || 0) /
                                                            (val?.totaloption
                                                              ?.total || 1)) *
                                                            100
                                                            }%`,
                                                        }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                      >
                                                        <p className="ps-3">
                                                          {val.data?.option1}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                      <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                          width: `${((val?.score
                                                            ?.option2 || 0) /
                                                            (val?.totaloption
                                                              ?.total || 1)) *
                                                            100
                                                            }%`,
                                                        }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                      >
                                                        <p className="ps-3">
                                                          {val.data?.option2}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                      <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                          width: `${((val?.score
                                                            ?.option3 || 0) /
                                                            (val?.totaloption
                                                              ?.total || 1)) *
                                                            100
                                                            }%`,
                                                        }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                      >
                                                        <p className="ps-3">
                                                          {val.data?.option3}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      className="chat-header shadow-pink mb-3 clearfix cursor_pointer"
                                                      onClick={() =>
                                                        handleSubmitAns(
                                                          val.data,
                                                          "option1"
                                                        )
                                                      }
                                                    >
                                                      {val.data?.option1}
                                                    </div>
                                                    <div
                                                      className="chat-header shadow-pink clearfix cursor_pointer"
                                                      onClick={() =>
                                                        handleSubmitAns(
                                                          val.data,
                                                          "option2"
                                                        )
                                                      }
                                                    >
                                                      {val.data?.option2}
                                                    </div>
                                                    <div
                                                      className="chat-header shadow-pink clearfix cursor_pointer"
                                                      onClick={() =>
                                                        handleSubmitAns(
                                                          val.data,
                                                          "option3"
                                                        )
                                                      }
                                                    >
                                                      {val.data?.option3}
                                                    </div>
                                                  </>
                                                )}
                                                <div className="mb-3 mt-3 note-msg-text">
                                                  <span className="message-data-time message-data  align-left">
                                                    Note: You can only vote
                                                    until 20-7-2023
                                                  </span>
                                                  <span className="message-data-time message-data align-right">
                                                    {moment(
                                                      val.data?.startDate
                                                    ).format("LL")}{" "}
                                                    {val.data?.startTime}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        ) : (
                                          <li>
                                            <div className="message other-message overflow-hidden event-box">
                                              <h4>
                                                Event Ttlle :{" "}
                                                {val?.data?.question}{" "}
                                              </h4>
                                              <div className="mb-2">
                                                Description :{" "}
                                                {val?.data?.description}{" "}
                                              </div>
                                              <div className="message-data-time p-0">
                                                Start Date :{" "}
                                                {moment(
                                                  val?.data?.startDate
                                                ).format("LL")}
                                              </div>
                                              <div className="message-data-time p-0">
                                                End Date :{" "}
                                                {moment(
                                                  val?.data?.endDate
                                                ).format("LL")}
                                              </div>
                                            </div>
                                          </li>
                                        )}
                                      </React.Fragment>
                                    );
                                  })
                                )}
                              </ul>
                            </div>

                            <div className="send-box">
                              <input
                                type="text"
                                className="send-box-input bg-pink"
                                placeholder="Write a message..."
                                tabIndex="0"
                                autoComplete="off"
                              />
                              <div className="send-box-icon">
                                <Button
                                  className="btn btn--send"
                                  title="Send message"
                                  tabIndex="0"
                                >
                                  <svg
                                    className="btn__icon"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <line x1="22" y1="2" x2="11" y2="13"></line>
                                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                  </svg>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab> */}

                      <Tab
                        eventKey="resource"
                        title="Activity"
                        className="tab-content"
                      >
                        <div className="d-flex chat justify-content-around resource">
                          <div className="chat">
                            <div className="chat-history">
                              {/* <div className="text-center"><span className="badge-text mb-3">Today</span></div>*/}
                              <div className="row row-flex row-flex-wrap px-4">
                                {allMediaData.length === 0 ? (
                                  <span>Data not found </span>
                                ) : (
                                  allMediaData?.map((val) => (
                                    <div
                                      className="col-xl-3 col-lg-6 col-md-6 mb-3"
                                      key={val._id}
                                    >
                                      <div className="panel panel-default flex-col h-100">
                                        <div className="drop_box">
                                          <a
                                            href={`${baseApi.baseurl}images/${val?.mediafile}`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <div
                                              className="d-flex pdf-div cursor_pointer"
                                              // onClick={() => handleDownload(val?.mediafile)}
                                            >
                                              <span
                                                className={
                                                  val?.type === "pdf"
                                                    ? "pdf-icon"
                                                    : "media-icon"
                                                }
                                              ></span>
                                              <span className="pdf-text">
                                                {" "}
                                                Download
                                              </span>
                                            </div>
                                          </a>

                                          {val?.type === "pdf" ? (
                                            <h6>
                                              {val?.mediafile?.substr(34)}
                                            </h6>
                                          ) : (
                                            <h6>{val?.mediafile}</h6>
                                          )}
                                          <p>Media Type: {val?.type}</p>
                                          <p>
                                            {moment(val?.createdAt).format(
                                              "LL"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                            {/* end chat-history */}
                            {/*
                                            <div className="send-box">
                                            <input
                                               type="text"
                                               className="send-box-input bg-pink"
                                               placeholder="Write a message..."
                                               tabIndex="0"
                                               autoComplete="off"
                                            />
                                            <div className="send-box-icon send-box-icon-orange">
                                               <Button
                                                  className="btn btn--send"
                                                  title="Send message"
                                                  tabIndex="0"
                                               >
                                                  <svg
                                                     className="btn__icon"
                                                     viewBox="0 0 24 24"
                                                     fill="none"
                                                     stroke="currentColor"
                                                     strokeWidth="2"
                                                     strokeLinecap="round"
                                                     strokeLinejoin="round"
                                                  >
                                                     <line x1="22" y1="2" x2="11" y2="13"></line>
                                                     <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                                  </svg>
                                               </Button>
                                            </div>
                                         </div>
                                          */}{" "}
                            {/* end chat-message */}
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey="home" title="Messages">
                        <div className="d-flex justify-content-around">
                          <div className="chat">
                            <div className="chat-history">
                              {/* <div className="text-center"><span className="badge-text mb-3">Today</span></div>*/}
                              <ul>
                                {messageList &&
                                  messageList?.map((messageContent) => {
                                    return (
                                      <li
                                        key={messageContent?._id}
                                        className={
                                          UserData?.userdata?._id ===
                                          messageContent.created_id
                                            ? "send-message "
                                            : "receive-message"
                                        }
                                      >
                                        <div className="message other-message">
                                          <h6>
                                            <span>
                                              {UserData?.userdata?._id ===
                                              messageContent.created_id ? (
                                                <img
                                                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg"
                                                  alt="avatar"
                                                />
                                              ) : (
                                                <img
                                                  src={
                                                    therapistData?.image
                                                      ? therapistData?.image
                                                      : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg"
                                                  }
                                                  alt="avatar"
                                                  style={{ height: "35px" }}
                                                />
                                              )}
                                            </span>
                                            <span className="ml-piv">
                                              {UserData?.userdata?._id !==
                                              messageContent?.created_id
                                                ? messageContent?.created_user &&
                                                  messageContent
                                                    ?.created_user[0]?.fullname
                                                : UserData?.userdata?.name}
                                            </span>
                                          </h6>
                                          <p>{messageContent?.message}</p>
                                          <div className="message-data-time message-data align-right">
                                            {messageContent?.createdAt
                                              ? formatDateTime(
                                                  messageContent?.createdAt
                                                )
                                              : formatDateTime(new Date())}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            {/* end chat-history */}
                            <div className="send-box">
                              <textarea
                                // type="text"
                                className="form-control bg-pink"
                                placeholder="Write a message..."
                                tabIndex="0"
                                // rows={rows}
                                cols={50}
                                autoComplete="off"
                                value={currMessage}
                                onChange={(event) =>
                                  setCurrMessage(event.target.value)
                                }
                                onKeyDown={handleKeyPress}
                              />
                              <div className="send-box-icon">
                                <Button
                                  className="btn btn--send"
                                  title="Send message"
                                  tabIndex="0"
                                  onClick={sendMessage}
                                >
                                  <svg
                                    className="btn__icon"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <line x1="22" y1="2" x2="11" y2="13"></line>
                                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                  </svg>
                                </Button>
                              </div>
                            </div>{" "}
                            {/* end chat-message */}
                          </div>
                        </div>
                      </Tab>

                      {/* end Discussion-tab */}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-5 col-sm-5 mb-2 list-item">
              <div className="dashboard-inner p-0 mb-0">
                <h3 className="secheading text-white text-center therapist-profile mt-0 mx-0">
                  My Therapist's Profile
                </h3>
                {loading ? (
                  <div className="container_loader"></div>
                ) : (
                  <div className="therapist-profile-text-div px-3">
                    <p className="therapist-profile-img mx-auto">
                      <img
                        src={
                          therapistData?.image
                            ? therapistData?.image
                            : PlayGround
                        }
                        alt="PlayGround"
                      />
                    </p>
                    <h3 className="secheading text-center mb-3">
                      {therapistData?.fullname}
                    </h3>
                    <h6 className="text-center fw-normal mb-3">
                      Years of Experience: {therapistData?.experience} Years
                    </h6>
                    <p className="h6 fst-italic text-center mb-3">
                      {therapistData?.qote}
                    </p>

                    <p className="fw-bold">Expertise</p>
                    <p>{therapistData?.experties}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MyTherapist;
