import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Sessions from "../../Assets/Images/sessions.png";

const AddEditSessionDetails = () => {
  const param = useParams();
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));

  const initialValues = {
    SessionSummary: "",
    Observations: "",
    Interventions: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitPublishsummary, setIsSubmitPublishsummary] = useState(false);
  const [IsSubmitInProgressSummary, setIsSubmitInProgressSummary] =
    useState(false);
  const [SummaryStatus, setSummaryStatus] = useState("");
  const [SummaryDetails, setSummaryDetails] = useState([]);
  const [EmployeeName, setEmployeeName] = useState("");

  const getSessionSummary = (SessionId) => {
    try {
      API.Session.SessionDetails({
        SessionId: param?.SessionId,
      }).then((response) => {
        console.log(
          "summary details",
          response?.data?.data_all[0]?.clientdata[0]?.fullname
        );
        if (response?.data?.status === 1) {
          setSummaryDetails(response?.data?.data_all[0]);
          setEmployeeName(response?.data?.data_all[0]?.clientdata[0]?.fullname);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const RegisterValidate = (values) => {
    const errors = {};

    if (!values?.SessionSummary) {
      errors.SessionSummary = "Session Summary is required !";
    }
    if (!values?.Observations) {
      errors.Observations = "Observations is required !";
    }
    if (!values?.Interventions) {
      errors.Interventions = "Interventions is required !";
    }
    return errors;
  };

  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmitPublishsummary) {
      try {
        API.Session.UpdateSession({
          data: {
            summary: formData?.SessionSummary,
            observation: formData?.Observations,
            intervention: formData?.Interventions,
            SummaryStatus: "Publish",
          },
          clientId: param?.SessionId,
        }).then((response) => {
          // console.log("Session", response?.data);
          if (response?.data?.status === 1) {
            // navigate(
            //   `../${AdminRoute.Auth.SessionDetails}/${param?.SessionId}`
            // );

            navigate(`../${AdminRoute.Common.MySession}`);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);

  const handleSubmitPublish = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setSummaryStatus("Publish");
    setIsSubmitPublishsummary(true);
  };

  useEffect(() => {
    if (IsSubmitInProgressSummary) {
      try {
        API.Session.UpdateSession({
          data: {
            summary: formData?.SessionSummary,
            observation: formData?.Observations,
            intervention: formData?.Interventions,
            SummaryStatus: "Inprogress",
          },
          clientId: param?.SessionId,
        }).then((response) => {
          // console.log("Session", response?.data);
          if (response?.data?.status === 1) {
            // navigate(
            //   `../${AdminRoute.Auth.SessionDetails}/${param?.SessionId}`
            // );

            navigate(`../${AdminRoute.Common.MySession}`);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [IsSubmitInProgressSummary]);

  const handleSubmitinProgress = (e) => {
    e.preventDefault();
    setSummaryStatus("Inprogress");
    setIsSubmitInProgressSummary(true);
  };

  useEffect(() => {
    getSessionSummary();
  }, []);

  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="nav-wrap">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <div className="w-100 h-head">
                  <h2 className="heading  mt-1 mb-1">Session Summary</h2>
                  {/* <Button
                    type="submit"
                    className="create-new_notes button_common notes-btn transparent-btn"
                  >
                    <NavLink
                      to={`${AdminRoute?.Common?.Appointment_scheduler}/?key=true`}
                    >
                      Book a Sessions{" "}
                    </NavLink>
                  </Button> */}
                </div>
                <Header />
              </nav>
            </div>

            <div className="col-md-12 mb-2 my-sessions-edit-v-height my-sessions">
              <div className="row">
                <div className="sub-head px-3 d-flex col-12 align-items-center">
                  <img
                    style={{ height: "40px", marginRight: "10px" }}
                    //   src={userImage}
                    src={Sessions}
                  />
                  <div>
                    <p className="secheading my-0">
                      <strong>{EmployeeName}</strong>
                    </p>
                    <p className="secheading my-0">
                      <strong>
                        {moment(SummaryDetails?.date).format("LL")} |{" "}
                        {SummaryDetails?.timestart}
                      </strong>
                    </p>
                    {/* <p className="sub-lbl mb-0">
                      Here are all your session details
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="mb-2 client-view-cont mt-3">
                <p className="my-2" style={{ color: "#32665F" }}>
                  <strong>Session Summary:</strong>
                </p>
                <textarea
                  name="SessionSummary"
                  className="session-inp-text"
                  rows={5}
                  onChange={(e) => handleChange(e)}
                ></textarea>
                <p className="text-danger">{formErrors?.SessionSummary}</p>
                <p className="my-2" style={{ color: "#32665F" }}>
                  <strong>Observations:</strong>
                </p>
                <textarea
                  name="Observations"
                  className="session-inp-text"
                  rows={5}
                  onChange={(e) => handleChange(e)}
                ></textarea>
                <p className="text-danger">{formErrors?.Observations}</p>

                <p className="my-2" style={{ color: "#32665F" }}>
                  <strong>Interventions:</strong>
                </p>
                <textarea
                  name="Interventions"
                  className="session-inp-text"
                  rows={5}
                  onChange={(e) => handleChange(e)}
                ></textarea>
                <p className="text-danger">{formErrors?.Interventions}</p>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="button_common m-0 mb-2 text-white btn btn-primary me-3 edit-session-btn"
                  onClick={(e) => handleSubmitinProgress(e)}
                >
                  Save as Draft
                </button>
                <button
                  className="button_common m-0 mb-2 text-white btn btn-primary edit-session-btn"
                  onClick={(e) => handleSubmitPublish(e)}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default AddEditSessionDetails;
