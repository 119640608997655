import React, { useState, useEffect } from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import AdminRoute from '../../Route/RouteDetails';
import API from '../../Api/Api';
import moment from 'moment';
import { useLocation } from "react-router-dom";


const SessionDetails = () => {
  const [sessionSummary, setSessionSummary] = useState({});
  const [loadSumaryList, setLoadSumaryList] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const SessionId = queryParams.get("id");

  useEffect(() => {
    getSessionSummary()
  }, [])

  // get summary list
  const getSessionSummary = () => {
    setLoadSumaryList(true)
    if (SessionId) {
      API.Session.Sessiondetails({
        id: SessionId
      }).then((response) => {
        if (response.data.status === 1) {
          setLoadSumaryList(false)
          // console.log(response.data)
          setSessionSummary(response.data.data_all[0])
        }
      }).catch((error) => {
        setLoadSumaryList(false)
        console.log(error)
      })
    }
  }

  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className='toggleIcon'>
        <div className="container-fluid">
          <div className="row list">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className='w-100 h-head'>
                  <h2 className='heading  mt-1 mb-1 pt-0'><i className="fa fa-chevron-left me-2"></i> Sessions Summary</h2>
                </div>
                <Header />
              </nav>
            </div>
            <p className='mb-3'>Here is an overview of your session.</p>
            <div className="col-md-12 mb-2 list-item session-summary-v-height session-summary-tabs">
              <div className="sessions-tabs p-4">
                {
                  loadSumaryList ? <div className="container_loader"></div> :
                    Object.keys(sessionSummary).length === 0 ? <span>Data not found</span> :
                      <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3 ps-0"
                      >
                        <Tab eventKey="home" title="Sessions Summary">
                          <h5>Sessions Summary</h5>
                          <p className='sessions-summery-text'>{sessionSummary?.summary && sessionSummary?.summary}</p>
                        </Tab>
                        {/*
                      <Tab eventKey="observations" title="Observations">
                          <h5>Observations</h5>
                          <p className='sessions-summery-text'>{sessionSummary?.observation && sessionSummary?.observation}</p>
                        </Tab>
                        <Tab eventKey="interventions" title="Interventions">
                          <h5>Interventions</h5>
                          <p className='sessions-summery-text'>{sessionSummary?.intervention && sessionSummary?.intervention}</p>
                        </Tab>
                      */}
                      </Tabs>
                }
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div >
  );
}

export default SessionDetails;
