const RouteName = {
  Auth: {
    Login: "/login",
    VerifyOtp: "/VerifyOtp",
    verifyOtpMail: "/verifyotpmail",
    Register: "/Register",
    Register2: "/Register2",
    // MyOrg: "/MyOrg",
  },
  Common: {
    MyChecklist: "/MyChecklist",
    Aitherapist: "/Aitherapist",
    Group: "/Group",
    Plan: "/Plan",
    Theam: "/Theam",
    Appointment_scheduler: "/Appointment_scheduler",
    DisplayCalender: "/displaycalender",
    Join_Group: "/Join_Group",
    Dashboard: "/",
    DigitalNotes: "/DigitalNotes",
    CreateNotes: "/CreateNotes",
    Employee: "/Employee",
    EmployeeDetails: "/EmployeeDetails",
    MySession: "/MySession",
    SessionDetails: "/SessionDetails",
    Groupchat: "/Groupchat",
    JoinGroup: "/JoinGroup",
    JoinGroupsession: "/JoinGroupsession",
    Playground: "/Playground",
    Profile: "/Profile",
    MyReferrals: "/MyReferrals",
    Payment: "/Payment",
    Settings: "/Settings",
    UserProfile: "/UserProfile",
    Chat: "/Chat",
    WllbeingTest: "/WllbeingTest",
    LineChart: "/line-chart",
    PersonalityTests: "/PersonalityTests",
    MyTherapist: "/MyTherapist",
    MakePayment: "/MakePayment",
    VideoCall: "/VideoCall",
    MembersSelection: "/members-selection",
    // Footer:'/Footer',
    // Sidebar:'/Sidebar'
    PageNotFound: "*",
    Profileedit: "/Profileedit",
    Myorglist: "/Myorglist",
    AddEditSessionDetails: "/add-edit-session-det",
  },
};

export default RouteName;
