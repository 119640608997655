import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import plusIcon from "../../Assets/Images/Icon feather-plus.png";
import Modal from "react-modal";
import DefaultImage from "../../Assets/Images/icons/Icon awesome-user-circle.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // height: "500px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#FFF",
    borderRadius: "20px",
    paddingTop: "20px",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0px 0px 24px -10px #bdbdbd",
  },
};

const MyReferrals = () => {
  // const navigate = useNavigate();
  // const [sumarySessionList, setSumarySessionList] = useState([]);
  // const [upcomingSessionList, setUpcomingSessionList] = useState([]);
  // const [loadSumaryList, setLoadSumaryList] = useState(false);
  // const [loadUpcomingList, setLoadUpcomingList] = useState(false);

  // function OpenViewModal() {
  //   setviewmodalIsOpen(true);
  // }
  // function afterViewOpenModal() {
  //   // references are now sync'd and can be accessed.
  // }
  // function closeViewModal() {
  //   setviewmodalIsOpen(false);
  // }

  // useEffect(() => {
  //     getSumarySessionList()
  //     getUpcomingSessionList()
  // }, [])

  // // get summary list
  // const getSumarySessionList = () => {
  //     setLoadSumaryList(true)
  //     try {
  //         API.Session.sumarySessionList().then((response) => {
  //             if (response.status === 200) {
  //                 setSumarySessionList(response?.data?.data_all);
  //                 setLoadSumaryList(false)
  //             }
  //         });
  //     } catch (error) {
  //         console.log(error);
  //         setLoadSumaryList(false)
  //     }
  // }

  // const getUpcomingSessionList = () => {
  //     setLoadUpcomingList(true)
  //     try {
  //         API.Session.upcomingSessionList().then((response) => {
  //             if (response.status === 200) {
  //                 setUpcomingSessionList(response?.data?.data_all);
  //                 setLoadUpcomingList(false)
  //             }
  //         });
  //     } catch (error) {
  //         console.log(error);
  //         setLoadUpcomingList(false)
  //     }
  // }

  // const handleEmployeeDetails = (val) => {
  //     navigate(`../${AdminRoute?.Common?.EmployeeDetails}`);
  // }

  // ================= New Code starts =====================

  const navigate = useNavigate();
  const [MyRefferalList, setMyRefferalList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenW, setIsOpenW] = useState(false);
  // const [modelReasonSummaryIsOpen, setmodelReasonSummaryIsOpen] =
  //   useState(false);
  const [modalReasonIsOpen, setmodalReasonIsOpen] = useState(false);
  const [refertoCoachlist, setrefertoCoachlist] = useState([]);
  const [ActiveEmployeeList, setActiveEmployeeList] = useState([]);
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const [EmployeeId, setEmployeeId] = useState();
  const [CoachId, setCoachId] = useState([]);
  const [TherapistIdRef, setTherapistIdRef] = useState("");
  const [ReasonError, setReasonError] = useState("");
  const [isReferSubmit, setIsReferSubmit] = useState(false);
  const [EmployeeName, setEmployeeName] = useState("");

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  // ==============================

  function openModalW() {
    setIsOpenW(true);
  }

  function afterOpenModalW() {
    // references are now sync'd and can be accessed.
  }

  function closeModalW() {
    setIsOpenW(false);
  }

  // ===============================

  function openReasonModal() {
    setmodalReasonIsOpen(true);
  }

  function afterReasonOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeReasonModal() {
    setmodalReasonIsOpen(false);
  }

  // ======== REason summary model==============================

  // function openReasonSummaryModal() {
  //   setmodelReasonSummaryIsOpen(true);
  // }

  // function afterReasonSummaryOpenModal() {
  //   // references are now sync'd and can be accessed.
  // }

  // function closeReasonSummaryModal() {
  //   setmodelReasonSummaryIsOpen(false);
  // }

  // =========================================

  const GotoWmodal = () => {
    closeModal();
    openModalW();
  };

  const GotoReasonmodel = () => {
    openReasonModal();
    closeModalW();
  };

  // =================================================
  const handleSelectEmployee = (e, ele) => {
    setEmployeeId(ele?._id);
    // setEmployeeName()
    // console.log("Employee====>", ele);
  };

  const handleSelectCoach = (e, ele) => {
    setCoachId(ele?._id);
    // console.log("Welnescoach====>", ele);
  };

  const handleChangeRefferal = (e) => {
    // console.log("Reason", e.target.value);
    setTherapistIdRef(e.target.value);
  };

  const handleSubmitReason = (e) => {
    e.preventDefault();
    if (!TherapistIdRef) {
      setReasonError("Reason is Required!");
    } else {
      setIsReferSubmit(true);
      setReasonError("");
    }
  };

  // useEffect(() => {
  //   if (isReferSubmit) {
  //     closeReasonModal();
  //     setIsReferSubmit(false);
  //     openReasonSummaryModal();
  //   }
  // }, [isReferSubmit]);

  // ===============================================

  const MyReferalList = () => {
    setLoading(true);
    try {
      API.Refer.MyreferralList({}).then((response) => {
        // console.log("My Refferral list", response?.data?.data_all);
        setMyRefferalList(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const WelnesscoachList = () => {
    setLoading(true);
    try {
      API.Refer.refertoCoachlist({}).then((response) => {
        // console.log("refertoCoachlist", response?.data?.data);
        setrefertoCoachlist(response?.data?.data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const EmployeeList = () => {
    setLoading(true);
    try {
      API.Leaders.LeaderEmployee({
        UserId: UserData?.userdata?._id,
        isLead: "no",
      }).then((response) => {
        if (response.data.status === 1) {
          // console.log("Employee", response?.data?.data_all);
          // setLeaderListData(response?.data?.data_all);
          setActiveEmployeeList(response?.data?.data_all);
        }
        // setLoadingMyorgList(false);
        setLoading(false);
      });
    } catch (error) {
      // setLoadingMyorgList(false);
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    MyReferalList();
    WelnesscoachList();
    EmployeeList();
  }, []);

  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className="w-100 h-head">
                  <h2 className="heading  mt-1 mb-1">My Referrals</h2>
                  <Button
                    type="submit"
                    onClick={openModal}
                    className="create-new_notes button_common notes-btn transparent-btn"
                  >
                    Share Referrals
                  </Button>
                </div>
                <Header />
              </nav>
            </div>

            <span className="my-checklist-modal">
              {/* ************* Employee Modal ************* */}
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <button onClick={closeModal} className="close-btn ">
                  <img src={plusIcon} />{" "}
                </button>
                <div>
                  <input
                    type="text"
                    placeholder="Select Employees"
                    className="my-task-add"
                    disabled
                  />
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 people-list">
                        <div className="search position-relative my-referrals-search">
                          <input
                            type="text"
                            placeholder="Search Employee"
                            // onChange={(e) => handleSearchText(e.target.value)}
                          />
                          <i className="fa fa-search position-absolute"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row select-employee-v-height">
                    <div className="select-employee-list">
                      {ActiveEmployeeList?.length &&
                        ActiveEmployeeList?.map((val, i) => {
                          return (
                            <div
                              className="col-12 my-checklist-wrap my-referrals-wrap"
                              key={i}
                            >
                              <div className="member-selection-card cursor-pointer p-2 px-0 d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <p className="group-member-icon d-flex justify-content-center align-items-center">
                                    <img
                                      className="member-icon"
                                      src={DefaultImage}
                                      alt="avatar"
                                    />
                                  </p>
                                  <p className="group-member-name ms-3 mb-0">
                                    {val?.client?.fullname}
                                  </p>
                                </div>
                                <div>
                                  <label className="check_container position-relative">
                                    <input
                                      name="Employee"
                                      type="radio"
                                      // value={val?.client?.id}
                                      // onChange={() => handleSelectClients(val)}
                                      // onChange={() => alert(val?.client?.id)}
                                      value={val?.client?._id}
                                      onClick={(e) =>
                                        handleSelectEmployee(e, val?.client)
                                      }
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="button_common w-100 mt-2 mb-0 text-center"
                  role="button"
                  onClick={() => GotoWmodal()}
                  // onClick={openModalW}
                  // onClick={submit}
                >
                  Next
                </button>
              </Modal>

              {/* ************* Welnesscoach modal **************** */}
              <Modal
                isOpen={modalIsOpenW}
                onAfterOpen={afterOpenModalW}
                onRequestClose={closeModalW}
                style={customStyles}
              >
                <button onClick={closeModalW} className="close-btn ">
                  <img src={plusIcon} />{" "}
                </button>
                <div>
                  <input
                    type="text"
                    placeholder="Select Welnesscoach"
                    className="my-task-add"
                    disabled
                  />
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 people-list">
                        <div className="search position-relative my-referrals-search">
                          <input
                            type="text"
                            placeholder="Search Employee"
                            // onChange={(e) => handleSearchText(e.target.value)}
                          />
                          <i className="fa fa-search position-absolute"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row select-employee-v-height">
                    <div className="select-employee-list">
                      {refertoCoachlist?.length &&
                        refertoCoachlist?.map((val, i) => {
                          return (
                            <div
                              className="col-12 my-checklist-wrap my-referrals-wrap"
                              key={i}
                            >
                              <div className="member-selection-card cursor-pointer p-2 px-0 d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <p className="group-member-icon d-flex justify-content-center align-items-center">
                                    <img
                                      className="member-icon"
                                      src={DefaultImage}
                                      alt="avatar"
                                    />
                                  </p>
                                  <p className="group-member-name ms-3 mb-0">
                                    {val?.fullname}
                                  </p>
                                </div>
                                <div>
                                  <label className="check_container position-relative">
                                    <input
                                      type="radio"
                                      value={val?._id}
                                      onClick={(e) => handleSelectCoach(e, val)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="button_common w-100 mt-2 mb-0 text-center"
                  role="button"
                  // onClick={submit}
                  onClick={GotoReasonmodel}
                >
                  Next
                </button>
              </Modal>

              {/* ==== Reason for Refferal model ====== */}

              <Modal
                isOpen={modalReasonIsOpen}
                onAfterOpen={afterReasonOpenModal}
                onRequestClose={closeReasonModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="position-relative mb-2">
                  <button
                    onClick={closeReasonModal}
                    className="close-btn "
                    style={{ top: "6px", zIndex: "100", right: "10px" }}
                  >
                    <img src={plusIcon} />{" "}
                  </button>

                  <p className="pop-up-head-containter w-100">Create Reason</p>
                </div>

                <textarea
                  rows="10"
                  name="TherapistIdRef"
                  className="form-input-text-area mt-5"
                  placeholder="Reason for refferal"
                  onChange={(e) => handleChangeRefferal(e)}
                ></textarea>
                {/* <p className="text-danger">{ReasonError}</p> */}
                <button
                  type="submit"
                  className="button_common w-100 text-center"
                  role="button"
                  onClick={(e) => handleSubmitReason(e)}
                >
                  Publish
                </button>
              </Modal>

              {/* *********** Reason Summary Model ******************** */}

              {/* <Modal
                isOpen={openReasonSummaryModal}
                onAfterOpen={afterReasonSummaryOpenModal}
                onRequestClose={closeReasonSummaryModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="position-relative mb-2">
                  <button
                    onClick={closeReasonSummaryModal}
                    className="close-btn"
                    style={{ top: "6px", zIndex: "100", right: "10px" }}
                  >
                    <img src={plusIcon} />{" "}
                  </button>
                  <p className="pop-up-head-containter w-100">Referrals</p>
                </div>
                <p className="mt-5 pt-2 mb-2" style={{ fontSize: "14px" }}>
                  Client: Akshay Mahajan
                </p>

                <p className="mt-2" style={{ fontSize: "14px" }}>
                  Therapist:
                </p>

                <p className="my-4" style={{ fontSize: "14px" }}>
                  Referring to: Harshad Mahajan
                </p>
                <p className="my-4" style={{ fontSize: "14px" }}>
                  Email Id
                </p>
                <input
                  type="text"
                  placeholder="Contact No."
                  className="mb-3"
                  name="EmailId"
                  // defaultValue={TherapistData?.Email}
                  style={{ background: "#EFF2F2" }}
                  readOnly
                />
                <p className="my-3" style={{ fontSize: "14px" }}>
                  Contact No.
                </p>
                <input
                  type="text"
                  placeholder="Contact No."
                  className="mb-3"
                  name="Mobileno"
                  // defaultValue={TherapistData?.Mobile}
                  style={{ background: "#EFF2F2" }}
                  readOnly
                />
                <button
                  type="submit"
                  className="button_common w-100 text-center"
                  role="button"
                  // onClick={(e) => handleSubmitRsn(e)}
                >
                  Done
                </button>
              </Modal> */}
            </span>

            <div className="col-md-12 mb-2 list-item my-referrals-v-height">
              <div className="sessions-tabs mt-2">
                <div className="employee-list">
                  <div className="row px-0 mt-2">
                    <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-3">
                      {/* <div className="active-employee inner-div">
                        <div
                          className="employee-box"
                          // onClick={() => handleEmployeeDetails()}
                        >
                          <div className="employee-box-inner">
                            <div className="text-div-sessions px-0">
                              <div className="slot-div-name text-dark">
                                Akshay{" "}
                                <span className="fw-normal">Mahajan</span>
                              </div>
                            </div>
                            <div className="time">
                              <div className="slot-div-date small-text text-muted">
                                10/02/24, 3:00PM
                              </div>
                            </div>
                          </div>
                          <div className="employee-box-inner align-items-end mt-3">
                            <div className="text-div-sessions px-0">
                              <div className="slot-div-date small-text">
                                Refered to:
                              </div>
                              <div className="slot-div-name text-dark">
                                akshay.mahajan@gmail.com
                              </div>
                            </div>
                            <div className="clock d-flex align-items-center">
                              <i className="fa fa-phone me-1"></i>
                              <div className="slot-div-number small-text">
                                9877665544
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MyReferrals;
