import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";

const UserProfile = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const initialValues = {
    firstName: "",
    lastName: "",
    birthDate: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (UserData.userdata) {
      setFormData({
        ...formData,
        firstName: UserData.userdata.name,
        lastName: UserData.userdata.last_name,
        birthDate: UserData.userdata.dob.split("T")[0],
      });
    }
  }, []);

  const Update_userValidate = (values) => {
    const errors = {};
    if (!values?.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values?.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!values?.birthDate) {
      errors.birthDate = "Birth date is required";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // API.
      try {
        API.Auth.UpdateProfileAPI({
          data: {
            name: formData?.firstName,
            last_name: formData?.lastName,
            dob: formData?.birthDate,
            // email: formData?.email,
            role: "Wellnessclient",
          },
          UserId: UserData?.userdata?._id,
        }).then((response) => {
          UserData.userdata.name = formData?.firstName;
          UserData.userdata.last_name = formData?.lastName;
          UserData.userdata.dob = formData?.birthDate;
          if (response?.data?.message === "Profile successfully updated.") {
            navigate(`../${AdminRoute?.Common?.Profile}`);
            localStorage.setItem("WellnessCoachData", JSON.stringify(UserData));
          }
          setIsSubmit(false);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(Update_userValidate(formData));
    setIsSubmit(true);
  };

  const disableFutureDate = () => {
    const today = new Date().toISOString().split("T")[0];
    // const dd = String(today.getDate()).padStart(2, "0");
    // const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    // const yyyy = today.getFullYear();
    return today;
  };

  return (
    <>
      <div className="container-fluid p-0 bg-grey userprofile">
        <div className="main-container w-100">
          <div className="col-sm-12">
            <div className="register bg-pink m-auto">
              <h2 className="heading">
                <ArrowLeft
                  className="left-arrow"
                  size={16}
                  onClick={() => navigate(-1)}
                />
                Edit Profile
              </h2>
              <form>
                <div>
                  <label>First Name:</label>
                  <input
                    type="text"
                    className="bg-white"
                    name="firstName"
                    autoComplete="off"
                    placeholder="Enter First Name"
                    value={formData.firstName}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div>
                  <label>Last Name:</label>
                  <input
                    type="text"
                    className="bg-white"
                    placeholder="Enter Last Name"
                    name="lastName"
                    autoComplete="off"
                    value={formData.lastName}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div>
                  <label>BirthDate:</label>
                  <input
                    type="date"
                    className="bg-white"
                    placeholder="Enter BirthDate"
                    name="birthDate"
                    value={formData.birthDate}
                    autoComplete="off"
                    max={disableFutureDate()}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <Button
                  type="submit"
                  className="button_common"
                  onClick={(e) => handleSubmit(e)}
                  disabled={isSubmit}
                >
                  Save
                </Button>
              </form>
            </div>
          </div>
          {/* col-md-6 */}
        </div>
      </div>
      {/* end of container */}
    </>
  );
};
export default UserProfile;
