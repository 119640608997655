// Sidebar.js
import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import { FeatrureList } from "../../App";
import { checkDate } from "./Functions";

function Sidebar() {
  const navigate = useNavigate();
  const featureListContext = useContext(FeatrureList);

  const Logoutfun = () => {
    localStorage.removeItem("EmailId");
    localStorage.removeItem("AuthTokenWellnessCoach");
    localStorage.removeItem("WellnessCoachData");
    navigate(`../${AdminRoute?.Auth?.Login}`);
  };

  return (
    <div id="sidebar-wrapper" className="sidebar">
      <div className="sidebar-heading text-center header-navbar">
        <NavLink to="/">
          <img src="/images/logo.png" className="logo" alt="logo" />{" "}
        </NavLink>
      </div>
      <div className="list-group">
        <aside className="dropdown-menu-list" id="menu">
          <li className="dropdown">
            <NavLink
              className="pt-2"
              eventkey="/"
              to={AdminRoute?.Common?.Dashboard}
            >
              <span>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/83/83966.png"
                  className="d-icon"
                  alt="logo"
                />
              </span>
              Home
            </NavLink>
          </li>
          {/* {(featureListContext.CurrentPlan?.find(item => item.features === "Notes")?.is_available !== 1 && checkDate(new Date(featureListContext.CurrentPlan?.find(item => item.features === "Notes")?.endDate))) ?
            <></> :
            <li className="dropdown">
              <NavLink
                className="pt-2"
                eventkey="/"
                to={AdminRoute?.Common?.DigitalNotes}
              >
                <span>
                  <svg
                    src="/images/note.png"
                    className="d-icon"
                    viewBox="0 0 50 50"
                  >
                    <path d="M 14 4 C 8.4886661 4 4 8.4886661 4 14 L 4 36 C 4 41.511334 8.4886661 46 14 46 L 36 46 C 41.511334 46 46 41.511334 46 36 L 46 14 C 46 8.4886661 41.511334 4 36 4 L 14 4 z M 14 6 L 36 6 C 40.430666 6 44 9.5693339 44 14 L 44 16 L 6 16 L 6 14 C 6 9.5693339 9.5693339 6 14 6 z M 6 18 L 44 18 L 44 26 L 6 26 L 6 18 z M 8 20 L 8 22 L 10 22 L 10 20 L 8 20 z M 12 20 L 12 22 L 14 22 L 14 20 L 12 20 z M 16 20 L 16 22 L 18 22 L 18 20 L 16 20 z M 20 20 L 20 22 L 22 22 L 22 20 L 20 20 z M 24 20 L 24 22 L 26 22 L 26 20 L 24 20 z M 28 20 L 28 22 L 30 22 L 30 20 L 28 20 z M 32 20 L 32 22 L 34 22 L 34 20 L 32 20 z M 36 20 L 36 22 L 38 22 L 38 20 L 36 20 z M 40 20 L 40 22 L 42 22 L 42 20 L 40 20 z M 6 28 L 44 28 L 44 35 L 6 35 L 6 28 z M 6.2011719 37 L 43.798828 37 C 43.288508 40.932523 40.075535 44 36 44 L 14 44 C 9.9244647 44 6.7114915 40.932523 6.2011719 37 z"></path>
                  </svg>
                </span>
                Digital Notes
              </NavLink>
            </li>
        }
         */}
          <li className="dropdown">
            <NavLink
              className="pt-2"
              eventkey="/"
              to={`../${AdminRoute?.Common?.Employee}/yes`}
            >
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" className="d-icon"
                  viewBox="0 0 15 15">
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                </svg>
              </span>
              Employee
            </NavLink>
          </li>

          <li className="dropdown">
            <NavLink
              className="pt-2"
              eventkey="/"
              to={AdminRoute?.Common?.Myorglist}
            >
              <span>
                <svg
                  src="/images/note.png"
                  className="d-icon"
                  viewBox="0 0 50 50"
                >
                  <path d="M 14 4 C 8.4886661 4 4 8.4886661 4 14 L 4 36 C 4 41.511334 8.4886661 46 14 46 L 36 46 C 41.511334 46 46 41.511334 46 36 L 46 14 C 46 8.4886661 41.511334 4 36 4 L 14 4 z M 14 6 L 36 6 C 40.430666 6 44 9.5693339 44 14 L 44 16 L 6 16 L 6 14 C 6 9.5693339 9.5693339 6 14 6 z M 6 18 L 44 18 L 44 26 L 6 26 L 6 18 z M 8 20 L 8 22 L 10 22 L 10 20 L 8 20 z M 12 20 L 12 22 L 14 22 L 14 20 L 12 20 z M 16 20 L 16 22 L 18 22 L 18 20 L 16 20 z M 20 20 L 20 22 L 22 22 L 22 20 L 20 20 z M 24 20 L 24 22 L 26 22 L 26 20 L 24 20 z M 28 20 L 28 22 L 30 22 L 30 20 L 28 20 z M 32 20 L 32 22 L 34 22 L 34 20 L 32 20 z M 36 20 L 36 22 L 38 22 L 38 20 L 36 20 z M 40 20 L 40 22 L 42 22 L 42 20 L 40 20 z M 6 28 L 44 28 L 44 35 L 6 35 L 6 28 z M 6.2011719 37 L 43.798828 37 C 43.288508 40.932523 40.075535 44 36 44 L 14 44 C 9.9244647 44 6.7114915 40.932523 6.2011719 37 z"></path>
                </svg>
              </span>
              My Organization
            </NavLink>
          </li>

          {/* {(featureListContext.CurrentPlan?.find(item => item.features === "Session")?.is_available !== 1 && checkDate(new Date(featureListContext.CurrentPlan?.find(item => item.features === "Session")?.endDate))) ?
            <></>
            : */}
          <li className="dropdown">
            <NavLink
              className="pt-2"
              eventkey="link-2"
              to={AdminRoute?.Common?.MySession}
            >
              <span>
                <img src="/images/session.png" className="d-icon" alt="logo" />
              </span>
              My Sessions
            </NavLink>
          </li>
          {/* // } */}

          {/* {(featureListContext.CurrentPlan?.find(item => item.features === "Groups")?.is_available !== 1 && checkDate(new Date(featureListContext.CurrentPlan?.find(item => item.features === "Groups")?.endDate))) ?
            <></>
            : */}
          <li className="dropdown">
            <NavLink
              className="pt-2"
              eventkey="link-3"
              to={AdminRoute?.Common?.Groupchat}
            >
              <span>
                <img src="/images/group.png" className="d-icon" alt="logo" />
              </span>
              Groups
            </NavLink>
          </li>

          {/* {(featureListContext.CurrentPlan?.find(item => item.features === "Therapist")?.is_available !== 1 && checkDate(new Date(featureListContext.CurrentPlan?.find(item => item.features === "Therapist")?.endDate))) ?
            <></>
            : */}
          <li className="dropdown">
            <NavLink
              className="pt-2"
              eventkey="link-4"
              to={AdminRoute?.Common?.Aitherapist}
            >
              <span>
                <img src="/images/ai.png" className="d-icon" alt="logo" />
              </span>
              Al Therapist
            </NavLink>
          </li>
          {/* } */}

          {/* {(featureListContext.CurrentPlan?.find(item => item.features === "Playground")?.is_available !== 1 && checkDate(new Date(featureListContext.CurrentPlan?.find(item => item.features === "Playground")?.endDate))) ?
            <></>
            : 
            <li className="dropdown">
              <NavLink
                className="pt-2"
                eventkey="/playground"
                to={AdminRoute?.Common?.Playground}
              >
                <span>
                  <img src="/images/play.png" className="d-icon" alt="logo" />
                </span>
                Playground
              </NavLink>
            </li>
         }  */}

          <li className="dropdown">
            <button className="pt-2" onClick={() => Logoutfun()}>
              <span>
                <img src="/images/logout.png" className="d-icon" alt="logo" />
              </span>
              Logout
            </button>
          </li>
        </aside>
      </div>
    </div>
  );
}

export default Sidebar;
