import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { NavLink } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Pagination } from "react-bootstrap";

const Employee = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedByOrg, setSelectedByOrg] = useState("");
  const [ActiveEmployeeList, setActiveEmployeeList] = useState([]);
  const [InactiveEmployeeList, setInactiveEmployeeList] = useState([]);
  const [tab, setTab] = useState("active");
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const [MyorgList, setMyorgList] = useState([]);
  const [LoadingMyorgList, setLoadingMyorgList] = useState(false);

  useEffect(() => {
    MyorgListdata();
  }, []);

  const MyorgListdata = () => {
    setLoadingMyorgList(true);
    try {
      API.Auth.MyOrg().then((response) => {
        if (response.data.status === 1) {
          // console.log("myorg", response?.data?.data_all);
          setMyorgList(response?.data?.data_all);
        }
        setLoadingMyorgList(false);
      });
    } catch (error) {
      setLoadingMyorgList(false);
      console.log(error);
    }
  };

  const handleEmployeeDetails = (val) => {
    // console.log("val===>", val);
    navigate(`../${AdminRoute?.Common?.EmployeeDetails}/${val?.client?._id}`);
  };

  // const getActiveEmployeeList = () => {
  //   setLoading(true);
  //   try {
  //     API.EmployeeTab.ActiveEmployee({ UserId: UserData?.userdata?._id }).then(
  //       (response) => {
  //         // console.log("setActiveEmployeeList", response?.data?.data_all);
  //         setActiveEmployeeList(response?.data?.data_all);
  //         setLoading(false);
  //       }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // const getInactiveEmployeeList = () => {
  //   setLoading(true);
  //   try {
  //     API.EmployeeTab.InActiveEmployee({
  //       UserId: UserData?.userdata?._id,
  //     }).then((response) => {
  //       // console.log("setInactiveEmployeeList", response?.data?.data_all);
  //       setInactiveEmployeeList(response?.data?.data_all);
  //       setLoading(false);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  const handleSelect = (key) => {
    if (key === "active") {
      // getActiveEmployeeList();
      setTab(key);
    }
    if (key === "inactive") {
      // getInactiveEmployeeList();
      setTab(key);
    }
  };

  useEffect(() => {
    // getActiveEmployeeList();
    // getInactiveEmployeeList();
  }, []);

  // ===================================

  const [namePage, setnamePage] = useState("");



  const [ActiveEmployeeOrLeadersList, setActiveEmployeeOrLeadersList] =
    useState([]);

  const [InActiveEmployeeOrLeadersList, setInActiveEmployeeOrLeadersList] =
    useState([]);

  const MyLeaderList = (text) => {
    console.log(text);
    //  setLoadingMyorgList(true);
    try {
      API.Leaders.LeaderEmployee({
        UserId: UserData?.userdata?._id,
        isLead: text ? text : selectedOption,
        org_name: selectedByOrg,
      }).then((response) => {
        if (response.data.status === 1) {
          // console.log("Leadeers", response?.data?.data_all);
          setActiveEmployeeOrLeadersList(response?.data?.data_all);
        }
        // setLoadingMyorgList(false);
      });
    } catch (error) {
      // setLoadingMyorgList(false);
      console.log(error);
    }

    // ===== Inactive List =======

    try {
      API.Leaders.LeaderEmployeeInactive({
        isLead: text,
        org_name: selectedByOrg,
      }).then((response) => {
        if (response.data.status === 1) {
          // console.log("Leadeers inactive", response?.data?.data_all);
          setInActiveEmployeeOrLeadersList(response?.data?.data_all);
        }
        // setLoadingMyorgList(false);
      });
    } catch (error) {
      // setLoadingMyorgList(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (params?.isLead === "yes") {
      MyLeaderList("yes");
      setnamePage("Leaders");
    }
    if (params?.isLead === "no") {
      MyLeaderList("no");
      setnamePage("Employees");
    }
  }, []);

  const handleOptionChange = (e) => {
    console.log("========>", e.target.value);
    // filterFun(e.target.value);
    setSelectedOption(e.target.value);

    if (e.target.value === "yes") {
      MyLeaderList("yes");
      setnamePage("Leaders");
    }
    if (e.target.value === "no") {
      MyLeaderList("no");
      setnamePage("Employees");
    }
  };

  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className="w-100 h-head">
                  <h2 className="heading  mt-1 mb-1 pt-0">{namePage}</h2>
                </div>
                <Header />
              </nav>
            </div>

            <div className="row mt-2">
              <div className="col">
                <h6 className="fw-bold">
                  <span className="fw-normal">Your</span> Assigned {namePage}:
                  {"  "}
                  {tab === "active" ? ActiveEmployeeOrLeadersList?.length : null }
                  {}
                  {tab !== "active" ? InActiveEmployeeOrLeadersList?.length : null}
                </h6>
                <p>Here is an overview of your clients</p>
              </div>
              <div className="col px-0 employee-dropdown">
                {/* ****** Filter Icon ******** */}
                {/* <Dropdown className="float-end">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div className="icon-div me-0">
                      <i className="fa fa-filter"></i>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <span
                      className="cursor-pointer"
                      onClick={() => filterFun("no")}
                    >
                      Employee
                    </span>
                    <br></br>
                    <span
                      className="cursor-pointer"
                      onClick={() => filterFun("yes")}
                    >
                      Leader
                    </span>
                    <br></br>
                  </Dropdown.Menu>
                </Dropdown> */}

                <div className="w-50 mt-2 sort-filter position-absolute">
                  <div className="col px-0 employee-dropdown d-flex justify-content-end align-items-center">
                    {/* <div className='icon-div me-0'><i className="fa fa-filter"></i></div> */}
                    <select
                      id="dropdown-basic"
                      className="form-control form-select me-2 px-4 search-select w-100 mb-2 mb-md-0 bg-white"
                      aria-label="Default select example"
                      value={selectedByOrg}
                      onChange={(e) => setSelectedByOrg(e.target.value)}
                    >
                      <option selected value="">
                        Select Organization
                      </option>
                      {MyorgList.map((val, i) => (
                        <React.Fragment key={i}>
                          <option value={val.org_name}>{val.org_name}</option>
                        </React.Fragment>
                      ))}
                    </select>

                    <select
                      id="dropdown-basic"
                      className="form-control form-select me-2 px-4 search-select w-100 mb-2 mb-md-0 bg-white"
                      aria-label="Default select example"
                      value={selectedOption}
                      // onClick={() => filterFun("no")}
                      // onChange={(e) => setSelectedOption(e.target.value)}
                      onChange={(e) => handleOptionChange(e)}
                    >
                      <option selected value="">
                        Select Employee Type
                      </option>
                      <option value="no">Employee</option>
                      <option value="yes">Leader</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-2 list-item employee-details-v-height">
              <div className="sessions-tabs mt-2">
                <Tabs
                  defaultActiveKey="active"
                  id="uncontrolled-tab-example"
                  className="mb-3 px-0"
                  onSelect={handleSelect}
                >
                  {/* ======= New Active tab for employees and  leaders ==== */}

                  <Tab eventKey="active" title="Active">
                    <div className="employee-list employee-list-height">
                      <div className="d-flex justify-content-around">
                        <ul className="auto-grid px-0 mt-2">
                          {loading ? (
                            <div className="loader-wrap">
                              <div className="loader_orange"></div>
                            </div>
                          ) : ActiveEmployeeOrLeadersList?.length === 0 ? (
                            <div
                              className="alert alert-warning my-3"
                              role="alert"
                            >
                              Data not found
                            </div>
                          ) : (
                            ActiveEmployeeOrLeadersList?.length &&
                            ActiveEmployeeOrLeadersList?.map((val, i) => {
                              return (
                                <li key={i}>
                                  <div className="active-employee inner-div">
                                    <div
                                      className="employee-box"
                                      onClick={() => handleEmployeeDetails(val)}
                                    >
                                      <div className="employee-box-inner">
                                        <div className="text-div-sessions px-0 text-start">
                                          {/* <div className="slot-div-date small-text">
                                            Client
                                          </div> */}
                                          <div className="slot-div-name text-dark">
                                            {val?.client?.fullname}
                                          </div>

                                          {/* <div className="slot-div-date small-text">
                                            Name
                                          </div> */}
                                          <div className="slot-div-name text-dark">
                                            {val?.client?.org_name}
                                          </div>
                                        </div>
                                        <div className="clock">
                                          <i className="fa fa-chevron-right"></i>
                                        </div>
                                      </div>
                                      <div className="employee-box-inner mt-3">
                                        {/* <div className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-book me-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                                          </svg>
                                          Notes:{" "}
                                          <span className="text-dark fw-bold">
                                            03
                                          </span>
                                        </div> */}
                                        {/* <div className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-book me-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                                          </svg>
                                          Activity:{" "}
                                          <span className="text-dark fw-bold">
                                            03
                                          </span>
                                        </div> */}
                                        <div className="active-badge">
                                          <span className="badge rounded-pill bg-success px-3">
                                            Active
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                    </div>
                  </Tab>
                  {/* ====== Old Active tab ========= */}
                  {/* <Tab eventKey="active" title="Active">
                    <div className="employee-list">
                      <div className="d-flex justify-content-around">
                        <ul className="auto-grid px-0 mt-2">
                          {loading ? (
                            <div className="loader-wrap">
                              <div className="loader_orange"></div>
                            </div>
                          ) : ActiveEmployeeList?.length === 0 ? (
                            <div
                              className="alert alert-warning my-3"
                              role="alert"
                            >
                              Data not found
                            </div>
                          ) : (
                            ActiveEmployeeList?.length &&
                            ActiveEmployeeList?.map((val, i) => {
                              return (
                                <li key={i}>
                                  <div className="active-employee inner-div">
                                    <div
                                      className="employee-box"
                                      onClick={() => handleEmployeeDetails(val)}
                                    >
                                      <div className="employee-box-inner">
                                        <div className="text-div-sessions px-0">
                                          <div className="slot-div-date small-text">
                                            Client
                                          </div>
                                          <div className="slot-div-name text-dark">
                                            {val?.client[0]?.fullname}
                                          </div>
                                        </div>
                                        <div className="clock">
                                          <i className="fa fa-chevron-right"></i>
                                        </div>
                                      </div>
                                      <div className="employee-box-inner mt-3">
                                        <div className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-book me-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                                          </svg>
                                          Notes:{" "}
                                          <span className="text-dark fw-bold">
                                            03
                                          </span>
                                        </div>
                                        <div className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-book me-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                                          </svg>
                                          Activity:{" "}
                                          <span className="text-dark fw-bold">
                                            03
                                          </span>
                                        </div>
                                        <div className="active-badge">
                                          <span className="badge rounded-pill bg-success px-3">
                                            Active
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                    </div>
                  </Tab> */}

                  {/* ===========Old active tab ends ============================= */}

                  {/* <Tab eventKey="approval" title="Approval Awaiting">
                    <div className="employee-list">
                      <div className="d-flex justify-content-around">
                        <ul className="auto-grid px-0 mt-2">
                          <li>
                            <div className="approval-employee inner-div">
                              <div className="employee-box">
                                <div className="employee-box-inner employee-box-inner-first p-3 d-block">
                                  <div className="text-div-sessions px-0 text-start">
                                    <div className="slot-div-name text-dark">
                                      Akshay{" "}
                                      <span className="fw-normal">Mahajan</span>
                                    </div>
                                    <div className="slot-div-date small-text">
                                      Client
                                    </div>
                                  </div>
                                  <div className="clock text-end small-text">
                                    Today, 4:00pm
                                  </div>
                                </div>
                                <div className="employee-box-inner mt-1 row">
                                  <div className="col-6">
                                    <p className="small-text">Date</p>
                                    <p className="text-dark fw-bold">
                                      2 August, 2023
                                    </p>
                                  </div>
                                  <div className="col-6">
                                    <p className="small-text">Time</p>
                                    <p className="text-dark fw-bold">2:00 PM</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <button
                                      type="submit"
                                      className="create-new_notes button_common notes-btn transparent-btn btn btn-primary bg-white mb-0 mt-2"
                                    >
                                      <a className="" href="#">
                                        Refer
                                      </a>
                                    </button>
                                  </div>
                                  <div className="col-6">
                                    <button
                                      type="submit"
                                      className="create-new_notes notes-btn btn btn-primary mb-0 mt-2 button_common approve-btn"
                                    >
                                      <a className="" href="#">
                                        Approve
                                      </a>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab> */}

                  <Tab eventKey="inactive" title="Inactive">
                    <div className="employee-list employee-list-height">
                      <div className="d-flex justify-content-around">
                        <ul className="auto-grid px-0 mt-2">
                          {loading ? (
                            <div className="loader-wrap">
                              <div className="loader_orange"></div>
                            </div>
                          ) : InActiveEmployeeOrLeadersList?.length === 0 ? (
                            <div
                              className="alert alert-warning my-3"
                              role="alert"
                            >
                              Data not found
                            </div>
                          ) : (
                            InActiveEmployeeOrLeadersList?.length &&
                            InActiveEmployeeOrLeadersList?.map((val, i) => {
                              return (
                                <li key={i} className="nocursor-pointer">
                                  <div className="inner-div nocursor-pointer">
                                    <div
                                      className="employee-box nocursor-pointer"
                                      // onClick={() => handleEmployeeDetails(val)}
                                    >
                                      <div className="employee-box-inner nocursor-pointer">
                                        <div className="text-div-sessions px-0 text-start">
                                          {/* <div className="slot-div-date small-text">
                                            Client
                                          </div> */}
                                          <div className="slot-div-name text-dark">
                                            {val?.fullname}
                                            {/* <span className="fw-normal">
                                              {" "}
                                              {val?.fullname}
                                            </span> */}
                                          </div>
                                          <div className="slot-div-name text-dark">
                                            {val?.org_name}
                                            {/* <span className="fw-normal">
                                              {" "}
                                              {val?.fullname}
                                            </span> */}
                                          </div>
                                        </div>

                                        {/* ========= Arrow ======== */}
                                        {/* <div className="clock">
                                          <i className="fa fa-chevron-right"></i>
                                        </div> */}
                                      </div>
                                      <div className="employee-box-inner mt-3 nocursor-pointer">
                                        {/* <div className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-book me-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                                          </svg>
                                          Notes:{" "}
                                          <span className="text-dark fw-bold">
                                            03
                                          </span>
                                        </div>
                                        <div className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-book me-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                                          </svg>
                                          Activity:{" "}
                                          <span className="text-dark fw-bold">
                                            03
                                          </span>
                                        </div> */}
                                        <div className="active-badge nocursor-pointer">
                                          <span className="badge rounded-pill bg-secondary px-3 nocursor-pointer">
                                            Inactive
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>

            {/* <div className="col-md-12 mb-2 list-item organization-details-v-height">
              <div className="sessions-tabs mt-2">
                <Tabs
                  defaultActiveKey="active"
                  id="uncontrolled-tab-example"
                  className="mb-3 px-0"
                  onSelect={handleSelect}
                >
                  <Tab eventKey="active" title="Active">
                    <div className="organizations-list mt-4">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Employee ID</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Organization Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Register Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {LoadingCmsemployee ? (
                            <figure>
                              <div className="dot white"></div>
                              <div className="dot"></div>
                            </figure>
                          ) : currentItems.length === 0 ? (
                            <div
                              className="alert alert-warning digital-notes-alert"
                              role="alert"
                            >
                              Data Not Found
                            </div>
                          ) : (
                            currentItems &&
                            currentItems.map((val, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val?.fullname}</td>
                                <td>{val?.org_name}</td>
                                <td>{val?.email}</td>
                                <td>
                                  {moment(val?.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  <div className="active px-3 py-2 rounded-pill text-white green text-center">
                                    Active
                                  </div>
                                </td>
                                <td>
                                  <NavLink
                                    to={`${AdminRoute?.Common?.EmployeesDetails}/${val?._id}`}
                                  >
                                    <button className="details bg-white px-3 py-2 mx-2 rounded-pill border border-dark">
                                      View Details
                                    </button>
                                  </NavLink>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Tab>

                  <Tab eventKey="inactive" title="Inactive">
                    <div className="organizations-list mt-4">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Employee ID</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Organization Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Register Date</th>

                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {LoadingCmsemployeeinactive ? (
                            <figure>
                              <div className="dot white"></div>
                              <div className="dot"></div>
                            </figure>
                          ) : currentItems.length === 0 ? (
                            <div
                              className="alert alert-warning digital-notes-alert"
                              role="alert"
                            >
                              Data Not Found
                            </div>
                          ) : (
                            currentItems &&
                            currentItems.map((val, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val?.fullname}</td>
                                <td>{val?.org_name}</td>
                                <td>{val?.email}</td>

                                <td>
                                  {moment(val?.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  <div className="active px-3 py-2 rounded-pill text-white gray text-center">
                                    Inactive
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Tab>
                </Tabs>
                <div className="d-flex justify-content-end">
                  <div>
                    <Pagination>
                      <Pagination.First
                        onClick={() => {
                          setCurrentPage(1);
                        }}
                      />
                      <Pagination.Prev
                        onClick={() => {
                          setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
                        }}
                      />
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                      />
                      <Pagination.Last
                        onClick={() => {
                          setCurrentPage(totalPages);
                        }}
                      />
                    </Pagination>
                    <p className="mx-2">
                      Showing {currentPage} of {totalPages}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Employee;
