/* eslint-disable */
import React, { useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
// import Accordion from 'react-bootstrap/Accordion';
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import Form from "react-bootstrap/Form";
import AdminRoute from "../../Route/RouteDetails";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import commoan from "../../environment/Config";
import moment from "moment";

const CreateNotes = () => {
  const navigate = useNavigate();
  // date time
  const CurrentTime = moment().format("LT"); // htmlFor current Time
  const CurrentDate = moment().format("L"); // htmlFor current Date
  const initialValues = {
    Title: "",
    Description: "",
  };
  const [CreateNotes, setCreateNotes] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [Theamlist, setTheamlist] = useState([]);
  const [image, setimage] = useState("");
  const [image_id, setimage_id] = useState("");
  const [loadingTheam, setLoadingTheam] = useState(false);

  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));

  const CreateNoteValidate = (value) => {
    const errors = {};
    var titleregx = /^[a-zA-Z]+ [a-zA-Z]+$/;

    // if (!value?.image) {
    //   errors.image = "Theam is required";
    // }

    if (!value?.Title) {
      errors.Title = "Title is required";
    }
    // else if (!titleregx.test(value?.title)) {
    //   errors.Title = "Enter valid title";
    // }

    if (!value?.Description) {
      errors.Description = "Description is required";
    }
    // else if (!titleregx.test(value?.Description)) {
    //   errors.Description = "Enter valid Description";
    // }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreateNotes({
      ...CreateNotes,
      [name]: value,
    });
  };
  // api call for theam list
  const GetTheamlist = () => {
    setLoadingTheam(true)
    let data = "";
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${commoan.baseUrl}themenotes/themelist`,
      headers: {},
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        setTheamlist(response?.data?.data_all);
        handleTheamimage(response.data.data_all[0]);
        setLoadingTheam(false)
      })
      .catch((error) => {
        console.log(error);
        setLoadingTheam(false)
      });
  };

  useEffect(() => {
    GetTheamlist();
  }, []);

  // function to add teams in api
  const handleTheamimage = (val) => {
    setimage(`${commoan.baseUrl}` + `images/` + val?.backgroundimagename);
    setimage_id(val?._id);
  };

  //api call for create digital notes
  const CreateDigitalNotes = (e) => {
    e.preventDefault();
    setFormErrors(CreateNoteValidate(CreateNotes));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      let data = JSON.stringify({
        title: CreateNotes.Title,
        description: CreateNotes?.Description,
        theme: image_id,
        created_by: UserData?.userdata?._id,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${commoan.baseUrl}notes/createnotes`,
        headers: {
          "Content-Type": "application/json",
          Authorization: UserData?.token
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.message === "Successfully created...") {
            // toast.success(response?.data?.message);
            navigate(`../${AdminRoute?.Common?.DigitalNotes}`);
          } else {
            // toast.error(response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error);
          // toast.error(error?.data?.message);
        });
    }
    // else{
    //   toast.error("Enter Title and Description");
    // }
  }, [formErrors])

  //JSX
  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <h2 className="heading">
                  <i
                    className="fa fa-chevron-left cursor_pointer pe-2"
                    aria-hidden="true"
                    onClick={() => navigate(-1)}
                  ></i>{" "}
                  Welcome<span className=""> {UserData?.userdata?.name}!</span>
                </h2>
                <Header />
              </nav>
            </div>{" "}
            {/* Header*/}
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="back-arrow">
                  <i
                    className="fa fa-chevron-left cursor_pointer"
                    aria-hidden="true"
                    onClick={() => navigate(-1)}
                  ></i>
                </div>
              </div>
            </div> */}
            <div className="col-lg-9 col-md-12 mb-2">
              <p className="mt-0 mb-2 text-muted">
                Journaling is one of the best way to monitor your feelings. Pour
                them all out with our digital notes.
              </p>
              <div
                className="dashboard-theme-notes mb-3"
                style={{
                  backgroundImage: image
                    ? `url(${image})`
                    : `url(https://images.unsplash.com/photo-1538645731800-4640c639bba7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YnJvd24lMjBiYWNrZ3JvdW5kfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60)`,
                }}
              >
                {/* <h1 className="heading">Enter Title here</h1> */}
                <div className="row">
                  <div className="col-lg-12 containers">
                    <input
                      type="text"
                      className="transparent-input w-100 p-0"
                      // id="Title"
                      autoComplete="off"
                      maxLength="40"
                      name="Title"
                      value={CreateNotes?.Title}
                      placeholder="Enter Title Here"
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
                <h3 className="secheading py-2 create-note-time">
                  {CurrentDate},{CurrentTime}
                </h3>
                <Form className="position-relative mt-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {/* <Form.Control
                      type="text"
                      as="textarea"
                      placeholder="Start typing here..."
                      rows={15}
                    /> */}
                    <div className="mb-3 notes-description">
                      <textarea
                        className="form-control"
                        // id="Description"
                        name="Description"
                        value={CreateNotes?.Description}
                        rows={16}
                        placeholder="Start typing here..."
                        onChange={handleChange}
                        maxLength="1000"
                      />
                      <span className="text-danger">{formErrors.Title ? formErrors.Title : formErrors.Description}</span>
                    </div>
                  </Form.Group>
                  {/* <NavLink to={AdminRoute?.Common?.DigitalNotes}> */}
                  <Button
                    type="submit"
                    className="button_common notes-btn btn mb-3 mt-2 btn-primary"
                    onClick={(e) => CreateDigitalNotes(e)}
                  >
                    Save Note
                  </Button>
                  {/* </NavLink> */}
                </Form>
              </div>
            </div>
            {/* col-lg-9 */}
            <div className="col-lg-3 col-md-12 mb-2 themesdropdown">
              <div className="w-100">
                <div className="row">
                  <label className="dropdown">
                    <div className="dd-button secheading p-3 pb-1">
                      Select Note Theme
                      <span className="dropdown-themes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          fill="currentColor"
                          className="bi bi-chevron-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                      </span>
                    </div>
                    <input type="checkbox" className="dd-input" id="test" />
                    <div className="dd-menu mt-0 px-3">
                      <p className="mt-0 mb-3 text-muted">
                        Choose a theme as per your feelings to pour your heart
                        into{" "}
                      </p>
                      <div className="wrapper-themes mt-3 mb-2">
                        {loadingTheam ?
                          <div className="alert alert-warning" role="alert">
                            Loading...
                          </div>
                          :
                          Theamlist &&
                          Theamlist.map((val) => {
                            return (
                              <div className="box">
                                <div className="card-body">
                                  <div className="playground-img-div mb-0">
                                    <img
                                      src={
                                        `${commoan.baseUrl}` +
                                        `images/` +
                                        val?.backgroundimagename
                                      }
                                      className="img-fluid select-note-theme-img"
                                      onClick={() => handleTheamimage(val)}
                                    />
                                  </div>
                                  <div className="support-div-text">
                                    <h3 className="card-title py-1">
                                      {val?.themeName}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {/* <div className="box">
                          <div className="card-body">
                            <div className="playground-img-div mb-2 theme2">
                              <span className="img"></span>
                            </div>
                            <div className="support-div-text">
                              <h3 className="card-title">Vintage</h3>
                            </div>
                          </div>
                        </div>
                        <div className="box">
                          <div className="card-body">
                            <div className="playground-img-div mb-2 theme3">
                              <span className="img"></span>
                            </div>
                            <div className="support-div-text">
                              <h3 className="card-title">Sage Peace</h3>
                            </div>
                          </div>
                        </div>
                        <div className="box">
                          <div className="card-body">
                            <div className="playground-img-div mb-2 theme4">
                              <span className="img"></span>
                            </div>
                            <div className="support-div-text">
                              <h3 className="card-title">Calm</h3>
                            </div>
                          </div>
                        </div>
                        <div className="box">
                          <div className="card-body">
                            <div className="playground-img-div mb-2 theme5">
                              <span className="img"></span>
                            </div>
                            <div className="support-div-text">
                              <h3 className="card-title">Organizer</h3>
                            </div>
                          </div>
                        </div>
                        <div className="box">
                          <div className="card-body">
                            <div className="playground-img-div mb-2 theme6">
                              <span className="img"></span>
                            </div>
                            <div className="support-div-text">
                              <h3 className="card-title">Dark Theme</h3>
                            </div>
                          </div>
                        
                        </div> */}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            {/* col-lg- */}
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CreateNotes;
