import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Button from 'react-bootstrap/Button';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import commoan from "../../environment/Config";

const Group = () => {
  const [InfoList, setInfoList] = useState([]);
  const [loading, setLoading] = useState(false);


  const navigate = useNavigate();
  const settings = {
    infinite: false,
    slidesToShow: 5,
    lazyLoad: true,
    dots: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 5,
          infinite: true,
          arrow: true,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 5,
          initialSlide: 5
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}`
      );
    }
  };

  const getInfoList = () => {
    setLoading(true)
    try {
      API.Auth.InfoInfoListAPI({
        helpus: "helpus"
      }).then((response) => {
        if (response.data.status === 1) {
          setInfoList(response?.data?.data_all);
        }
        setLoading(false)
      });
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  useEffect(() => {
    getInfoList();
  }, []);
  //jsx
  return (

    <div className="container-fluid dashboard m-auto">
      <div className="row d-flex justify-content-center text-center inner mt-4">
        <div className="logo-div mt-5">
          <NavLink to="/">
            <img src='/images/logo.png' className="logo" alt="logo" />
          </NavLink>
        </div>
        <p className="text-center mt-3 mb-4 font-bold">Help us serve you better by selecting a Theme that suits you best</p>
        <div className="col-md-12">
          {loading && <div className="container_loader"></div>}
          <Slider {...settings}>
            {InfoList?.length && InfoList.map((val) => {
              return (
                <div className="inner-div bg-transparent h-100" key={val.id}>
                  <img
                    className="d-block w-100"
                    src={
                      `${commoan.baseUrl}` +
                      `images/` +
                      val?.backgroundimagename
                    }

                    alt={val.caption}
                  />
                  <div className="card-body bg-transparent">
                    <h3 className="card-title group-card-title">{val.header}</h3>
                    <p className="card-text">{val.description}</p>
                  </div>

                </div>
              )
            })}
          </Slider>
          <Button type="submit" className="button_common" onClick={() => navigate(AdminRoute?.Common?.Plan)}>
            Next
          </Button> 
        </div>

      </div>
    </div >

  )
}
export default Group;