import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import userImage from "../../Assets/Images/userimg.png";
import sessions from "../../Assets/Images/sessions.png";
import { formatDateTime } from "../Common/Functions";
import Dropdown from "react-bootstrap/Dropdown";
import plusIcon from "../../Assets/Images/Icon feather-plus.png";
import Modal from "react-modal";
// import io from "socket.io-client";
import moment from "moment";
import baseApi from "../../Api/config";
import notesLogo from "../../Assets/Images/Group 22440.png";
import UserImage from "../../Assets/Images/icons/Icon awesome-user-circle.png";
import { socket } from "../../socket";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#FFF",
    borderRadius: "20px",
    paddingTop: "20px",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0px 0px 24px -10px #bdbdbd",
  },
};

// const socket = io(process.env.REACT_APP_BASE_URL_SOCKET, {
//   path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
// });

const EmployeeDetails = () => {
  // const location = useLocation();
  // const socket =
  //   location.pathname.includes("EmployeeDetails") &&
  //   io(process.env.REACT_APP_BASE_URL_SOCKET, {
  //     path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
  //   });
  const navigate = useNavigate();
  const params = useParams();
  const [room] = useState("messageroom");
  const [currMessage, setCurrMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [rows, setRows] = useState(1);
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [Session_History, setSession_History] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ListLoading, setListLoading] = useState(true);
  const chatHistoryRef = useRef(null);
  const [Sessionloading, setSessionloading] = useState(false);
  const [DigitalNotesLoading, setDigitalNotesLoading] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [tab, setTab] = useState("session-history");

  const handleSelect = (key) => {
    if (key === "session-history") {
      getSessionHistory_List();
      setTab(key);
    }
    if (key === "digital-notes") {
      getDigitalNotes_List();
      setTab(key);
    }
    if (key === "activity") {
      MediaListFun();
      setTab(key);
    }
    if (key === "chats") {
      getAllChats();
      setTab(key);
    }
  };

  const handleSessionDetails = (val) => {
    navigate(`../${AdminRoute?.Common?.SessionDetails}?id=${val._id}`);
  };

  // ************* Chats Starts **********************

  const getAllChats = () => {
    // setLoading(true);
    API.MyThreapist.ChatList()
      .then((response) => {
        if (response.data.status === 1) {
          setMessageList(response?.data?.data_all);
          console.log("Chat Listing", response?.data?.data_all);
          //   setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        // setLoading(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // console.log(currMessage);
      setRows(1);
      sendMessage(e);
      e.preventDefault();
    } else if (e.key === "Enter" && e.shiftKey) {
      if (rows < 6) {
        setRows(rows + 1);
      }
      const cursorPosition = e.target.selectionStart;
      const newInputValue =
        currMessage.substring(0, cursorPosition) +
        "\n" +
        currMessage.substring(cursorPosition);
      setCurrMessage(newInputValue);
      e.preventDefault();
    }
  };

  const sendMessage = async () => {
    // console.log(
    //   "send Message",
    //   /*UserData?.data_theropist[0].theropistId*/ "therapist id"
    // );
    if (currMessage !== "") {
      const messageData = {
        author: `${UserData?.userdata?.fullname}`,
        created_id: UserData?.userdata?._id,
        receiver_id: params?.EmpId,
        message: currMessage,
        type: "individual",
        createdAt: new Date(),
        time: new Date(),
        // type: "all",
      };
      await socket.emit(room, messageData);
    }
    setCurrMessage("");
  };

  useEffect(() => {
    if (!socket || !room) return;
    socket.connect();

    const handleData = (data) => {
      setMessageList((list) => [...list, data]);
    };

    socket.on(room, handleData);

    return () => {
      socket.off(room, handleData); // Remove the listener using the same function reference
      socket.disconnect();
    };
  }, [room, socket]);

  // useEffect(() => {
  //   socket.on(room, (data) => {
  //     console.log(data);
  //     setMessageList((list) => [...list, data]);
  //   });
  //   return () => {
  //     // socket.removeListener(room);
  //     socket.off(room);
  //   };
  // }, [socket]);

  // useEffect(() => {
  //   if (location.pathname.includes("https://wellnesscoach.rytlife.com/#/EmployeeDetails/")) {
  //     socket.on(room, (data) => {
  //       console.log(data);
  //       setMessageList((list) => [...list, data]);
  //     });
  //     return () => {
  //       socket.removeListener(room);
  //     };
  //   }
  // }, [location.pathname, socket]);

  useEffect(() => {
    // Scroll to the bottom of the chat history when component updates
    chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
  }, [messageList]); // Trigger effect when messageList changes

  // ************* Chats Ends **********************

  // ************** User Details Start *******************

  const [UserDetails, setUserDetails] = useState([]);

  const EmployeeDetails = () => {
    try {
      API.EmployeeTab.EmployeeProfileDetails({
        data: {
          _id: params?.EmpId,
        },
      }).then((response) => {
        console.log("Employee Details", response?.data?.data);
        if (response?.data?.status === 1) {
          setUserDetails(response?.data?.data);
        }
        // setLoading(false);
      });
    } catch (error) {
      // setApiError(error.message);
      console.log(error);
      // setLoading(false);
    }
  };

  // ************** User Details Ends *******************

  // ************* Session History Listing Starts*****************

  const getSessionHistory_List = () => {
    setListLoading(true);
    try {
      API.EmployeeTab.EmployeeSessionHistoryList({
        ClientId: params?.EmpId,
        TherapistID: UserData?.userdata?._id,
      }).then((response) => {
        // console.log("Session_History", response?.data?.data_all?.reverse());
        setSession_History(response?.data?.data_all);
        setListLoading(false);
      });
    } catch (error) {
      console.log(error);
      setListLoading(false);
    }
  };

  // ************* Session History Listing Ends*****************

  // ************* Digital Notes Listing Starts*************
  const [DigitalNotes, setDigital_Notes] = useState([]);

  const getDigitalNotes_List = () => {
    setListLoading(true);
    try {
      API.EmployeeTab.EmployeeDigitalNotesList({
        UserId: params?.EmpId,
      }).then((response) => {
        // console.log("ClientActiveList", response?.data?.data_all);
        setDigital_Notes(response?.data?.data_all);
        setListLoading(false);
      });
    } catch (error) {
      console.log(error);
      setListLoading(false);
    }
  };

  const [NotesDetails, setNotesDetails] = useState([]);
  const [NoteId, setNoteId] = useState("");

  const GetDigitalNotesDetails = (ID) => {
    setDigitalNotesLoading(true);
    console.log(ID);
    setNoteId(ID);
    try {
      API.EmployeeTab.EmployeeNotesDetailbyId({
        NoteId: ID,
      }).then((response) => {
        // console.log("Note Deatils", response?.data);
        if (response?.data?.status === 1) {
          setNotesDetails(response?.data?.data_all[0]);
        }
        setDigitalNotesLoading(false);
      });
    } catch (error) {
      console.log(error);
      setDigitalNotesLoading(false);
    }
  };

  // Digital notes response ========================
  const [DNotesResponse, setDNotesResponse] = useState("");
  const [responselist, setresponselist] = useState([]);

  const handleSaveResponse = (e) => {
    e.preventDefault();

    // console.log("===> response", {
    //   theropistid: UserData?.userdata?._id,
    //   theropistname: UserData?.userdata?. ,
    //   responce: DNotesResponse,
    //   update_id: NoteId,
    // });

    setLoading(true);

    try {
      API.Notes.UpdateNotes({
        data: {
          response: {
            theropistid: UserData?.userdata?._id,
            theropistname: UserData?.userdata?.fullname,
            responce: DNotesResponse,
          },
        },
        update_id: NoteId,
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          //   toast.success("update successfull");
          getResponsesList();
          setDNotesResponse("");
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getResponsesList = () => {
    try {
      API.Notes.NoteResponseList({
        NoteId: NoteId,
      }).then((response) => {
        // console.log("Response list====>", response?.data?.data_all[0]);
        if (response?.data?.status === 1) {
          setresponselist(response?.data?.data_all[0]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ************* Digital Notes Listing Ends*************

  // **************** Upload multimedia and upload pdf start ******************

  const [MediaList, setMediaList] = useState([]);

  const handleUpload = (e) => {
    // console.log(e.target.files[0].name);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then((result) => CreateMediafun(JSON.parse(result).data));
    } catch (err) {
      console.log(err.res);
    }
  };
  const CreateMediafun = (media) => {
    // console.log("Create media function", media);
    let mediaType = media?.filename.split(".").pop();

    try {
      API.EmployeeTab.CreateMediaIndividual({
        data: {
          mediafile: media?.filename,
          type: mediaType === "pdf" ? "pdf" : "mediafile",
          created_by: UserData?.userdata?._id,
          send_to: params?.EmpId,
          originalfilename: media?.originalname,
        },
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          // handleClickMenu(menuVisible);
          MediaListFun();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const MediaListFun = () => {
    setListLoading(true);
    try {
      API.MyThreapist.meadialistindividual({
        Userid: params?.EmpId,
        // Userid: 123456789,
      }).then((response) => {
        // console.log("media List  ===>", response?.data);
        setMediaList(response?.data?.data_all);
        setListLoading(false);
      });
    } catch (error) {
      console.log(error);
      setListLoading(false);
    }
  };

  // ********************************************************

  const [SummaryDetails, setSummaryDetails] = useState([]);

  const getSessionSummary = (SessionId) => {
    setSessionloading(true);
    try {
      API.Session.SessionDetails({
        SessionId: SessionId,
      }).then((response) => {
        // console.log("summary details", response?.data?.data_all);
        if (response?.data?.status === 1) {
          setSummaryDetails(response?.data?.data_all[0]);
        }
        setSessionloading(false);
      });
    } catch (error) {
      console.log(error);
      setSessionloading(false);
    }
  };

  // ************ Common UseEffect Starts *******************

  useEffect(() => {
    getDigitalNotes_List();
    getSessionHistory_List();
    getAllChats();
    EmployeeDetails();
    MediaListFun();
    getResponsesList();
  }, []);

  // ************ Common UseEffect Ends *******************

  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className="w-100 h-head">
                  <h2 className="heading  mt-1 mb-1 pt-0">
                    <i
                      className="fa fa-chevron-left cursor_pointer pe-2"
                      aria-hidden="true"
                      onClick={() => navigate(-1)}
                    ></i>{" "}
                    Employee
                  </h2>
                </div>
                <Header />
              </nav>
            </div>

            <span className="my-checklist-modal">
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <button onClick={closeModal} className="close-btn ">
                  <img src={plusIcon} />{" "}
                </button>
                <div>
                  <input
                    type="text"
                    placeholder="Employee Profile"
                    className="my-task-add"
                    disabled
                  />

                  <div className="row mt-4">
                    <div className="col d-flex">
                      <div className="userimg">
                        <img
                          style={{ height: "40px", marginRight: "10px" }}
                          src={userImage}
                        />
                      </div>
                      <div className="user-details">
                        <h6 className="fw-bold mb-0">
                          {" "}
                          {UserDetails?.name}
                          <span className="fw-normal"></span>
                        </h6>
                        <p className="mt-1">
                          Here are all the indivisual’s personal details.
                        </p>
                      </div>
                    </div>
                  </div>

                  <p>Name: {UserDetails?.fullname}</p>
                  {/* <p>Last Name: Not Available</p> */}
                  <p>Date of birth: {moment(UserDetails?.dob).format("LL")}</p>
                  <p>E- Mail Id: {UserDetails?.email}</p>
                  <p>Mobile No.: +91 {UserDetails?.phone}</p>
                  <p>Designation: {UserDetails?.designation}</p>
                  {/* <p>Wellness score : 24/100</p> */}
                  <p>Organization: {UserDetails?.org_name}</p>
                </div>
              </Modal>
              {/* **************** View Modal Starts ************************** */}
            </span>

            <div className="row mt-2">
              <div className="col d-flex">
                <div className="userimg">
                  <img
                    style={{ height: "40px", marginRight: "10px" }}
                    src={userImage}
                  />
                </div>
                <div className="user-details">
                  <h6 className="fw-bold mb-0">
                    <span className="secheading my-0">
                      {UserDetails.fullname}
                    </span>
                  </h6>
                  <h6 className="fw-bold mb-0">
                    <span>{UserDetails?.org_name}</span>
                  </h6>

                  <p className="mt-1">
                    Here are all the details of your client
                  </p>
                </div>
              </div>
              <div className="col px-0">
                <Button
                  className="button_common m-0 mb-2 text-white view-profile-btn float-end"
                  onClick={openModal}
                >
                  View Profile
                </Button>
              </div>
            </div>

            <div className="col-md-12 mb-2 list-item employee-details-v-height">
              <div className="sessions-tabs mt-2">
                <Tabs
                  defaultActiveKey="session-history"
                  id="uncontrolled-tab-example"
                  className="mb-3 px-0"
                  onSelect={handleSelect}
                >
                  <Tab eventKey="session-history" title="Session History">
                    <div className="session-history-box row">
                      <div className="col-md-5 col-lg-4">
                        <div className="session-history-tab-box">
                          <div className="session-history-tab-box-inner p-3">
                            <div className="session-history-inner">
                              {ListLoading ? (
                                <div className="loader-wrap">
                                  <div className="loader_orange"></div>
                                </div>
                              ) : Session_History?.length === 0 ? (
                                <div
                                  className="alert alert-warning digital-notes-alert"
                                  role="alert"
                                >
                                  Data Not Found
                                </div>
                              ) : (
                                Session_History?.length &&
                                Session_History?.map((val, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="session-history-box-inner d-flex justify-content-between px-3 py-2 mb-3 activetab"
                                      onClick={() =>
                                        getSessionSummary(val?._id)
                                      }

                                      // onClick={() => alert("ok")}
                                    >
                                      <div className="px-0">
                                        <div className="slot-div-date small-text">
                                          Date
                                        </div>
                                        <div className="slot-div-name text-dark fw-normal">
                                          {moment(val?.date).format("LL")}
                                        </div>
                                      </div>
                                      <div className="px-0 d-flex align-items-center">
                                        <div className="time-box">
                                          <div className="slot-div-date small-text">
                                            Time
                                          </div>
                                          <div className="slot-div-name text-dark fw-normal">
                                            {/* {moment(val?.date).format("LT")} */}
                                            {val?.timestart}
                                          </div>
                                        </div>
                                        <i className="fa fa-chevron-right ms-5"></i>
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                              {/* <div className="session-history-box-inner d-flex justify-content-between px-3 py-2 mb-3 activetab">
                                <div className="px-0">
                                  <div className="slot-div-date small-text">
                                    Date
                                  </div>
                                  <div className="slot-div-name text-dark fw-normal">
                                    31 July, 2024
                                  </div>
                                </div>
                                <div className="px-0 d-flex align-items-center">
                                  <div className="time-box">
                                    <div className="slot-div-date small-text">
                                      Time
                                    </div>
                                    <div className="slot-div-name text-dark fw-normal">
                                      31 July, 2024
                                    </div>
                                  </div>
                                  <i className="fa fa-chevron-right ms-5"></i>
                                </div>
                              </div> */}

                              {/* <div className="session-history-box-inner d-flex justify-content-between px-3 py-2 mb-3">
                                <div className="px-0">
                                  <div className="slot-div-date small-text">
                                    Date
                                  </div>
                                  <div className="slot-div-name text-dark fw-normal">
                                    31 July, 2024
                                  </div>
                                </div>
                                <div className="px-0 d-flex align-items-center">
                                  <div className="time-box">
                                    <div className="slot-div-date small-text">
                                      Time
                                    </div>
                                    <div className="slot-div-name text-dark fw-normal">
                                      31 July, 2024
                                    </div>
                                  </div>
                                  <i className="fa fa-chevron-right ms-5"></i>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ********* Session History Summary Details ******************** */}

                      <div className="col-md-7 col-lg-8">
                        <div className="session-history-details-box p-3">
                          <div className="session-history-details-wrap">
                            {Sessionloading ? (
                              <figure>
                                <div className="dot white"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                              </figure>
                            ) : (
                              <div className="row">
                                <div className="col-12 d-flex">
                                  <div className="userimg">
                                    <img
                                      style={{
                                        height: "40px",
                                        marginRight: "10px",
                                      }}
                                      src={sessions}
                                    />
                                  </div>
                                  <div className="user-details">
                                    <h6 className="fw-bold mb-0 gloock-font d-color">
                                      {moment(SummaryDetails?.date).format(
                                        "LL"
                                      )}{" "}
                                      |{" "}
                                      {/* {moment(SummaryDetails?.date).format("LT")} */}
                                      {SummaryDetails?.timestart}
                                    </h6>
                                    <p className="mt-1">
                                      Here are all the session details
                                    </p>
                                  </div>
                                </div>
                                <div className="col-12 mt-3">
                                  <h6 className="d-color fw-bold">
                                    Session Summary:
                                  </h6>
                                  <p className="mb-3">
                                    {SummaryDetails?.summary}
                                  </p>

                                  <h6 className="d-color fw-bold">
                                    Observations:
                                  </h6>
                                  <p className="mb-3">
                                    {SummaryDetails?.observation}
                                  </p>

                                  <h6 className="d-color fw-bold">
                                    Interventions:
                                  </h6>
                                  <p className="mb-3">
                                    {SummaryDetails?.intervention}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* *********************************************************************** */}
                    </div>
                  </Tab>

                  <Tab eventKey="digital-notes" title="Digital Notes">
                    <div className="session-history-box row">
                      <div className="col-md-5 col-lg-4">
                        <div className="session-history-tab-box">
                          <div className="session-history-tab-box-inner p-3">
                            <div className="session-history-inner">
                              {ListLoading ? (
                                <div className="loader-wrap">
                                  <div className="loader_orange"></div>
                                </div>
                              ) : DigitalNotes?.length === 0 ? (
                                <div
                                  className="alert alert-warning digital-notes-alert"
                                  role="alert"
                                >
                                  Data Not Found
                                </div>
                              ) : (
                                DigitalNotes?.length &&
                                DigitalNotes?.map((val, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="session-history-box-inner d-flex justify-content-between align-items-center px-3 py-2 mb-3 bg-light-grey"
                                      onClick={() =>
                                        GetDigitalNotesDetails(val?._id)
                                      }
                                    >
                                      <div className="px-0">
                                        <div className="slot-div-name text-dark fw-normal">
                                          {val?.title}
                                        </div>
                                        <div className="slot-div-date small-text">
                                          {moment(val?.createdAt).format("LLL")}
                                        </div>
                                      </div>
                                      <div className="px-0 d-flex align-items-center">
                                        <i className="fa fa-chevron-right ms-5"></i>
                                      </div>
                                      {val?.response ? (
                                        <p className="position-absolute green-dot"></p>
                                      ) : (
                                        <p className="position-absolute red-dot"></p>
                                      )}
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7 col-lg-8">
                        <div className="session-history-details-box p-3">
                          <div className="session-history-details-wrap">
                            {DigitalNotesLoading ? (
                              <figure>
                                <div className="dot white"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                              </figure>
                            ) : (
                              <div className="row">
                                <div className="col-12 d-flex">
                                  {/* <div className="userimg">
                                <img
                                  style={{
                                    height: "40px",
                                    marginRight: "10px",
                                  }}
                                  src={sessions}
                                />
                              </div>
                              <div className="user-details">
                                <h6 className="fw-bold mb-0 gloock-font d-color">
                                  31 July, 2023 | 11:00 AM
                                </h6>
                                <p className="mt-1">
                                  Here are all the session details
                                </p>
                              </div> */}
                                </div>
                                <div className="col-12 mt-3">
                                  <h6 className="d-color fw-bold">Title:</h6>
                                  <p className="mb-3">{NotesDetails?.title}</p>

                                  <h6 className="d-color fw-bold">
                                    Description:
                                  </h6>
                                  <p className="mb-3">
                                    {NotesDetails?.description}
                                  </p>

                                  {/* ---------- */}
                                  {NotesDetails?.response || responselist ? (
                                    <h6 className="d-color fw-bold">
                                      Response:
                                    </h6>
                                  ) : (
                                    <></>
                                  )}

                                  {/* ======== Response ================================= */}
                                  {NoteId ? (
                                    <div
                                      className="dashboard-container"
                                      style={{
                                        maxHeight: "calc(100% - 13%)",
                                        padding: "14px",
                                      }}
                                    >
                                      {NotesDetails?.response ||
                                      responselist ? (
                                        <>
                                          {" "}
                                          <p className="mb-3">
                                            {NotesDetails?.response?.responce}
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <div className="digital-note-cont pb-4 ">
                                            <>
                                              <textarea
                                                name="DNotesResponse"
                                                className="respo_inp px-3"
                                                value={DNotesResponse}
                                                placeholder="Add Response"
                                                onChange={(e) =>
                                                  setDNotesResponse(
                                                    e.target.value
                                                  )
                                                }
                                              ></textarea>
                                            </>
                                          </div>
                                          <>
                                            <div className="d-flex justify-content-end">
                                              <button
                                                className="btn_pr rounded-pill my-3"
                                                // onClick={() => handleRespoClick()} /// this for read only response view
                                                onClick={(e) =>
                                                  handleSaveResponse(e)
                                                }
                                              >
                                                Submit
                                              </button>
                                            </div>
                                            {loading ? (
                                              <div className="loader_orange"></div>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        </>
                                      )}

                                      {/* {!responselist?.length ? (
                                    <div
                                      className="alert alert-warning"
                                      role="alert"
                                    >
                                      Response not Available{" "}
                                    </div>
                                  ) : (
                                    responselist?.length &&
                                    responselist.map((val, i) => {
                                      return (
                                        <div
                                          className="col-lg-12 col-md-12 mb-3"
                                          key={i}
                                        >
                                          <div className="response-box p-3">
                                            <div className="d-flex justify-content-between">
                                              <p className="fw-bold">
                                                Response{" "}
                                              </p>
                                              <p className="text-muted">
                                                {moment().format("ll")}
                                              </p>
                                            </div>

                                            <div className="responce-by">
                                              <i class="fa fa-user me-1"></i>{" "}
                                              Meenakshi Narayan
                                            </div>

                                            <p className="responce-details">
                                              {val?.responce}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })
                                  )} */}

                                      {responselist ? (
                                        <>
                                          <div className="response-cont my-2">
                                            <div className="repsonse-head d-flex justify-content-between my-2 align-items-center">
                                              <div>
                                                <img
                                                  src=""
                                                  style={{ height: "18px" }}
                                                />
                                                {/* <label className="respo-head-lbl">
                                              Response
                                            </label> */}
                                                <img
                                                  src=""
                                                  style={{ height: "18px" }}
                                                />
                                              </div>
                                              <div>
                                                <label>
                                                  {moment(
                                                    responselist?.updatedAt
                                                  ).format("LLL")}
                                                </label>
                                              </div>
                                            </div>
                                            <p
                                              style={{
                                                fontSize: "14px",
                                                margin: 0,
                                              }}
                                            >
                                              {responselist?.response?.responce}
                                            </p>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {/* ============================================================= */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="activity" title="Activity">
                    <div className="employee-list employee-activity activity-tab">
                      <div className="chat-history">
                        {/* <div className="text-center"><span className="badge-text mb-3">Today</span></div>*/}
                        <div className="row row-flex row-flex-wrapgit add">
                          {ListLoading ? (
                            <div className="loader-wrap">
                              <div className="loader_orange"></div>
                            </div>
                          ) : MediaList?.length === 0 ? (
                            <div
                              className="alert alert-warning my-3"
                              role="alert"
                            >
                              Data not found
                            </div>
                          ) : (
                            MediaList?.map((val) => (
                              <div
                                className="col-xl-3 col-lg-6 col-md-6 mb-3"
                                key={val?._id}
                              >
                                <div className="panel panel-default flex-col h-100">
                                  <div className="drop_box h-100">
                                    <a
                                      href={`${baseApi.baseurl}images/${val?.mediafile}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <div
                                        className="d-flex pdf-div cursor_pointer"
                                        // onClick={() => handleDownload(val?.mediafile)}
                                      >
                                        <span
                                          className={
                                            val?.type === "pdf"
                                              ? "pdf-icon"
                                              : "media-icon"
                                          }
                                        ></span>
                                        <span className="pdf-text">
                                          {" "}
                                          Download
                                        </span>
                                      </div>
                                    </a>

                                    {val?.type === "pdf" ? (
                                      <h6>{val?.mediafile?.substr(34)}</h6>
                                    ) : (
                                      <h6></h6>
                                    )}
                                    <p>Media Type: {val?.type}</p>
                                    <p>Media Name: {val?.originalfilename}</p>
                                    <p>{moment(val?.createdAt).format("LL")}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>

                      <Dropdown className="float-end">
                        <Dropdown.Menu>
                          <p className="mb-2">
                            <i className="fa fa-plus"></i>
                            <input
                              type="file"
                              id="uploadpdf"
                              accept="application/pdf"
                              className="custom-file-input cursor-pointer"
                              title=" "
                              onChange={(e) => handleUpload(e)}
                            />
                            <label htmlFor="uploadpdf">Upload pdf</label>
                          </p>

                          <p className="mb-0">
                            <i className="fa fa-plus"></i>
                            <input
                              type="file"
                              id="uploadmedia"
                              className="custom-file-input cursor-pointer"
                              accept="image/png, image/gif, image/jpeg, image/jpg"
                              title=" "
                              onChange={(e) => handleUpload(e)}
                            />
                            <label htmlFor="uploadmedia">
                              Upload Multimedia
                            </label>
                          </p>
                        </Dropdown.Menu>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="icon-div me-0">
                            <i className="fa fa-plus"></i>
                          </div>
                        </Dropdown.Toggle>
                      </Dropdown>
                    </div>
                  </Tab>

                  <Tab eventKey="chats" title="Chats">
                    <div className="employee-list">
                      <div className="d-flex justify-content-around">
                        <div className="chat chat-box-wrap p-3">
                          <div
                            className="chat-history chat-history-group"
                            ref={chatHistoryRef}
                          >
                            {/* <div className="text-center"><span className="badge-text mb-3">Today</span></div>*/}
                            <ul>
                              {/*
                                          <li className="receive-message">
                                             <div className="message my-message">
                                                <h6><span> <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" />
                                                   </span><span className="ml-piv">Uma Joshi</span>
                                                </h6>
                                                <p>Lorem ipsum dolor sit exercitation ullamco</p>
                                                <div className="message-data-time message-data align-right">10:10 AM, Today</div>
                                             </div>
                                          </li>
                                          <li className="">
                                             <div className="message my-message">
                                                <h6><span> <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" />
                                                   </span><span className="ml-piv">Uma Joshi</span>
                                                </h6>
                                                <p>Lorem ipsum dolor sit exercitation ullamco</p>
                                                <div className="message-data-time message-data align-right">10:10 AM, Today</div>
                                             </div>
                                          </li>
                                          */}
                              {messageList &&
                                messageList?.map((messageContent, i) => {
                                  return (
                                    <div>
                                      {messageContent?.created_id ===
                                        params?.EmpId ||
                                      messageContent?.receiver_id ===
                                        params?.EmpId ? (
                                        <li
                                          key={messageContent?._id}
                                          className={
                                            UserData?.userdata?._id ===
                                            messageContent?.created_id
                                              ? "send-message"
                                              : "receive-message "
                                          }
                                          index={i}
                                        >
                                          <div className="message other-message">
                                            <h6>
                                              <span>
                                                <img
                                                  className="leader-user-img "
                                                  // src={UserImage}
                                                  src={
                                                    UserData?.userdata?._id ===
                                                    messageContent?.created_id
                                                      ? UserData?.userdata
                                                          ?.image
                                                        ? UserData?.userdata
                                                            ?.image
                                                        : UserImage
                                                      : UserDetails?.image
                                                      ? UserDetails?.image
                                                      : UserImage
                                                  }
                                                  alt="avatar"
                                                />
                                              </span>
                                              <span className="ml-piv">
                                                {messageContent.author
                                                  ? messageContent.author
                                                  : messageContent?.created_user
                                                      ?.length &&
                                                    messageContent
                                                      ?.created_user[0]
                                                      ?.fullname}
                                              </span>
                                            </h6>
                                            <p>{messageContent.message}</p>
                                            <div className="message-data-time message-data align-right">
                                              {messageContent?.createdAt
                                                ? formatDateTime(
                                                    messageContent?.createdAt
                                                  )
                                                : formatDateTime(new Date())}
                                            </div>
                                          </div>
                                        </li>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  );
                                })}
                            </ul>
                          </div>
                          {/* end chat-history */}
                          <div className="send-box">
                            <input
                              className="chat-input-text"
                              type="text"
                              id="uploadFile"
                              accept="image/png, image/gif, image/jpeg"
                              placeholder="Write a messege"
                              tabIndex="1"
                              title=" "
                              value={currMessage}
                              onChange={(event) =>
                                setCurrMessage(event.target.value)
                              }
                              onKeyDown={handleKeyPress}
                              autoComplete="off"
                            />
                            <div className="send-box-icon bg-orange">
                              <Button
                                className="btn btn--send"
                                title="Send message"
                                tabIndex="0"
                                onClick={sendMessage}
                              >
                                <svg
                                  className="btn__icon"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <line x1="22" y1="2" x2="11" y2="13"></line>
                                  <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                </svg>
                              </Button>
                            </div>
                          </div>{" "}
                          {/* end chat-message */}
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
