import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { ArrowLeft } from "react-bootstrap-icons";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import baseApi from "../../Api/config";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import userImg from "../../Assets/Images/icons/userIMG.jpg";
// import { toast } from "react-toastify";

const Register = () => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const email = JSON.parse(queryParams.get("email"));
  // const mobile_no = JSON.parse(queryParams.get("mobile_no"));
  // const [loading, setLoading] = useState(false);

  // const initialValues = {
  //   firstName: "",
  //   lastName: "",
  //   birthDate: "",
  //   email: "",
  //   PhoneNumber: "",
  // };
  // const [formData, setFormData] = useState(initialValues);
  // const [formErrors, setFormErrors] = useState({});
  // const [isSubmit, setIsSubmit] = useState(false);
  // const [error, setError] = useState("");

  // useEffect(() => {
  //   if (email) {
  //     setFormData({
  //       ...formData,
  //       email: email,
  //     });
  //   }
  //   if (mobile_no) {
  //     setFormData({
  //       ...formData,
  //       PhoneNumber: mobile_no,
  //     });
  //   }
  // }, []);

  // const RegisterValidate = (values) => {
  //   const errors = {};
  //   const checkMobile = /^[789]\d{9}$/;
  //   const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;

  //   if (!values?.firstName) {
  //     errors.firstName = "First name is required";
  //   }
  //   if (!values?.lastName) {
  //     errors.lastName = "Last name is required";
  //   }
  //   if (!values?.email) {
  //     errors.email = "Email is required";
  //   } else if (!emailRegrex.test(values?.email)) {
  //     errors.email = "Enter valid Email";
  //   }
  //   if (!values?.birthDate) {
  //     errors.birthDate = "Birth date is required";
  //   }
  //   if (!values.PhoneNumber) {
  //     errors.PhoneNumber = "Phone number is required";
  //   } else if (!checkMobile.test(values?.PhoneNumber)) {
  //     errors.PhoneNumber = "Enter valid Phone number";
  //   }
  //   return errors;
  // };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  // useEffect(() => {
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {
  //     setLoading(true);
  //     try {
  //       API.Auth.Register({
  //         data: {
  //           name: formData?.firstName,
  //           last_name: formData?.lastName,
  //           dob: formData?.birthDate,
  //           email: formData?.email,
  //           phone: formData?.PhoneNumber,
  //           role: "Wellnessclient",
  //         },
  //       })
  //         .then((response) => {
  //           if (response?.data?.status === 1) {
  //             // localStorage.setItem("EmailId", response?.data?.data?.email);
  //             // localStorage.setItem("Mobileno", response?.data?.data?.phone);
  //             navigate(
  //               `../${AdminRoute?.Auth?.VerifyOtp}/2?email=${JSON.stringify(
  //                 formData?.email
  //               )}&mobile_no=${JSON.stringify(formData?.PhoneNumber)}`
  //             );
  //             // toast.success(response?.data?.message);
  //             setError("");
  //           } else {
  //             // toast.error(response?.data?.message);
  //             setError(response?.data?.message);
  //           }
  //           setLoading(false);
  //         })
  //         .catch((error) => {
  //           console.log("Error", error.message);
  //           setLoading(false);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //       setLoading(false);
  //     }
  //   }
  // }, [formErrors]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setFormErrors(RegisterValidate(formData));
  //   setIsSubmit(true);
  // };

  // const disableFutureDate = () => {
  //   // const today = new Date().toISOString().split('T')[0];
  //   // return today;
  //   const currentDate = new Date();
  //   const maxDate = new Date(
  //     currentDate.getFullYear() - 18,
  //     currentDate.getMonth(),
  //     currentDate.getDate()
  //   );
  //   return maxDate.toISOString().split("T")[0];
  // };

  // ======================================================

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailLoc = JSON.parse(queryParams.get("email"));
  const mobile_noLoc = JSON.parse(queryParams.get("mobile_no"));
  const [spinner, setSpinner] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");

  // const goBackFun = () => {
  //   navigate(-1);
  //   localStorage.removeItem("Mobileno");
  //   localStorage.removeItem("EmailId");
  //   localStorage.removeItem("ProfileImage");
  // };

  const initialValues = {
    Name: "",
    Email: emailLoc,
    PhoneNumber: mobile_noLoc,
    // Age: "",
    birthDate: "",
    Gender: "",
    Surname: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // ==========Upload image api===========

  const handleUpload = (e) => {
    // console.log(e.target.files[0].name);
    setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setSpinner(false)
        );
    } catch (err) {
      // console.log(err.res);
      setSpinner(false);
    }
  };

  const addImage = (image) => {
    setProfileImage(`${baseApi.baseurl}images/${image}`);
  };

  const RegisterValidate = (values) => {
    // console.log("===> ok", values);
    const errors = {};
    const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
    const nameRegex = /^[A-Za-z\s-]+$/;
    const ageRegex = /^\d+$/;
    const minNumber = 1;
    const maxNumber = 120;

    if (!values?.Name) {
      errors.Name = "Please enter First Name!";
    } else if (!nameRegex.test(values?.Name)) {
      errors.Name = "Please enter valid First Name!";
    }
    if (!values?.Surname) {
      errors.Surname = "Please enter Last Name!";
    } else if (!nameRegex.test(values?.Surname)) {
      errors.Surname = "Please enter valid Last Name!";
    }
    if (!values?.PhoneNumber) {
      errors.PhoneNumber = "Please enter mobile number!";
    }
    if (!values?.Email) {
      errors.Email = "Please enter e-mail!";
    } else if (!emailRegrex.test(values?.Email)) {
      errors.Email = "Please enter valid e-mail!";
    }
    // if (!values?.Age) {
    //   errors.Age = "Please enter age!";
    // } else if (!ageRegex.test(values?.Age)) {
    //   errors.Age = "Please enter valid age!";
    // } else if (values?.Age < minNumber || values?.Age > maxNumber) {
    //   errors.Age = "Please enter valid age!";
    // }

    if (!values?.birthDate) {
      errors.birthDate = "Birth date is required";
    }

    if (!values?.Gender) {
      errors.Gender = "Please select gender!";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      localStorage.setItem("RegUserData", JSON.stringify(formData));
      navigate(`../${AdminRoute?.Auth?.Register2}`);
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
  };

  useEffect(() => {
    // console.log("======ProfileImage>", ProfileImage);
    // setFormData({ ...formData["ProfileImage"], ProfileImage });
    localStorage.setItem("ProfileImage", JSON.stringify(ProfileImage));
  }, [ProfileImage]);

  const disableFutureDate = () => {
    // const today = new Date().toISOString().split('T')[0];
    // return today;
    const currentDate = new Date();
    const maxDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return maxDate.toISOString().split("T")[0];
  };
  return (
    <>
      <div className="container">
        <div className="row main-container">
          <div className="col-md-6 col-sm-12 left-text">
            {/* <div className="logo-div">
              <NavLink to="/">
                <img src="/images/logo.png" className="logo" alt="logo" />{" "}
              </NavLink>
            </div> */}
            <h1 className="heading">
              Begin your transformative with our online therapy.{" "}
            </h1>
            {/* <p className="subheading">Mental Health journey</p> */}
            <p>
              Navigate your path to well-being with our dedicated psychologists
              by your side, every step of the way.
            </p>
          </div>
          {/* col-md-6 */}
          <div className="col-md-6 col-sm-12">
            <div className="register register1">
              <h2 className="heading mb-0">
                {" "}
                <NavLink to="/">
                  {/* <ArrowLeft className="left-arrow" size={16} /> */}
                  <i className="fa fa-chevron-left left-arrow"></i>
                </NavLink>
                Register
              </h2>
              <form>
                <div className=" d-flex justify-content-center">
                  {spinner ? (
                    <>
                      <span className="custom-drag-box">
                        <div className="m-5">
                          <div className="container_loader "></div>
                        </div>
                        <input
                          type="file"
                          id="uploadFile"
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                          title=" "
                          onChange={(e) => handleUpload(e)}
                        />
                      </span>
                    </>
                  ) : ProfileImage ? (
                    <>
                      <span className="custom-drag-box">
                        <div className="d-flex flex-column align-items-center w-100 h-100">
                          <i
                            className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                            aria-hidden="true"
                          ></i>
                          <div
                            className="form-upload-photo-preview2"
                            style={{
                              backgroundImage: `url(${ProfileImage})`,
                            }}
                          ></div>
                          <span className="custom-drag-box-text d-none">
                            Image Uploaded
                          </span>
                          <input
                            type="file"
                            id="uploadFile"
                            accept="image/png, image/gif, image/jpeg, image/jpg"
                            title=" "
                            onChange={(e) => handleUpload(e)}
                          />
                        </div>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="custom-drag-box">
                        <img className="reg-user-img" src={userImg} />
                        <input
                          type="file"
                          id="uploadFile"
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                          title=" "
                          onChange={(e) => handleUpload(e)}
                        />
                      </span>
                    </>
                  )}
                </div>

                <div className="row">
                  <div className="col-12 col-lg-6">
                    <label>
                      <span className="text-danger fw-bold">*</span> First Name:
                    </label>
                    <input
                      type="text"
                      name="Name"
                      placeholder="Enter First Name"
                      value={formData.Name}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    />
                    <p className="text-danger">{formErrors?.Name}</p>
                  </div>

                  <div className="col-12 col-lg-6">
                    <label>
                      <span className="text-danger fw-bold">*</span> Last Name:
                    </label>
                    <input
                      type="text"
                      name="Surname"
                      placeholder="Enter Last Name"
                      value={formData.Surname}
                      onChange={(e) => handleChange(e)}
                      autoComplete="off"
                      required
                    />
                    <p className="text-danger">{formErrors?.Surname}</p>
                  </div>

                  <div className="col-12 col-lg-6">
                    <label>
                      <span className="text-danger fw-bold">*</span> E-Mail ID:
                    </label>
                    <input
                      type="email"
                      name="Email"
                      defaultValue={emailLoc}
                      placeholder="Enter your E-Mail ID"
                      onChange={(e) => handleChange(e)}
                      autoComplete="off"
                    />
                    <p className="text-danger">{formErrors?.Email}</p>
                  </div>

                  <div className="col-12 col-lg-6">
                    <label>
                      <span className="text-danger fw-bold">*</span> Mobile
                      Number:
                    </label>

                    <input
                      type="text"
                      name="PhoneNumber"
                      value={formData.PhoneNumber}
                      autoComplete="off"
                      placeholder="Enter Mobile Number"
                      onChange={(e) => handleChange(e)}
                      required
                    />
                    <p className="text-danger">{formErrors?.PhoneNumber}</p>
                    {/* {error && <p className="text-danger">{error}</p>} */}
                  </div>

                  {/* <div className="col-12 col-lg-6">
                    <label>
                      <span className="text-danger fw-bold">*</span> Age:
                    </label>

                    <input
                      type="text"
                      name="Age"
                      value={formData.Age}
                      autoComplete="off"
                      placeholder="Enter Age"
                      onChange={(e) => handleChange(e)}
                      required
                    />
                    <p className="text-danger">{formErrors?.Age}</p>
                  </div> */}

                  <div className="col-12 col-lg-6">
                    <label>
                      <span className="text-danger fw-bold">*</span> Birth Date:
                    </label>
                    <input
                      type="date"
                      placeholder="Enter BirthDate"
                      name="birthDate"
                      value={formData.birthDate}
                      onChange={handleChange}
                      autoComplete="off"
                      max={disableFutureDate()}
                      required
                    />
                    <p className="text-danger">{formErrors?.birthDate}</p>
                  </div>

                  <div className="col-12 col-lg-6">
                    <label>
                      <span className="text-danger fw-bold">*</span> Gender:
                    </label>

                    <select
                      name="Gender"
                      className="gender-select"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">Select Your Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="other">Other</option>
                    </select>

                    <p className="text-danger">{formErrors?.Gender}</p>
                    {/* {error && <p className="text-danger">{error}</p>} */}
                  </div>

                  {/* <div className="col-12 col-lg-6">
                    <label>
                      <span className="text-danger fw-bold">*</span> BirthDate:
                    </label>
                    <input
                      type="date"
                      placeholder="Enter BirthDate"
                      name="birthDate"
                      value={formData.birthDate}
                      onChange={handleChange}
                      autoComplete="off"
                      max={disableFutureDate()}
                      required
                    />
                    <p className="text-danger">{formErrors?.birthDate}</p>
                  </div> */}
                </div>

                <button
                  className="button_common"
                  onClick={(e) => handleSubmit(e)}
                >
                  Next
                </button>
              </form>
            </div>
          </div>
          {/* col-md-6 */}
        </div>
      </div>
      {/* end of container */}
    </>
  );
};

export default Register;
