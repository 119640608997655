import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import SuccessPop from "../Common/modal/SuccessPop";
import API from "../../Api/Api";

const VerifyOtpMail = () => {
  const navigate = useNavigate();
  const path = useParams();
  const Email = localStorage.getItem("EmailId");
  const AuthToken = localStorage.getItem("AuthTokenWelnesscoach");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [counter, setCounter] = useState(30);
  const [ApiError, setApiError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopUp] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Code to execute after 30 seconds
    }, 30000);

    const interval = setInterval(() => {
      if (counter) {
        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [counter]);

  function handleChange(event, index) {
    const { value } = event.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });

      if (value.length === 1 && index < 3) {
        inputRefs[index + 1].current.focus();
      } else if (value.length === 0 && index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  }
  const newotp = otp.join("");

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    setApiError("");

    if (path.path == 2) {
      setLoading(true);
      try {
        API.Auth.VerifyViaMail({
          data: {
            email: Email,
            otp: newotp,
            role: "Wellnessclient",
          },
          tokenRequired: AuthToken,
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            setApiError(response?.data?.message);
            localStorage.setItem(
              "AuthTokenWelnesscoach",
              response?.data?.token
            );
            localStorage.setItem(
              "WellnessCoachData",
              JSON.stringify(response?.data)
            );
            // ***** 22 april change ************
            navigate(`../${AdminRoute?.Auth.Login}`);
            // ****************************************
            // ******* old change **************
            // navigate(`../${AdminRoute?.Common?.Appointment_scheduler}/1`);
            // ******************************************
            // set localstorage data
          } else {
            setApiError(response?.data?.message);
            // toast.error(response?.data?.message);
          }
          setLoading(false);
        });
      } catch (error) {
        setApiError(error?.message);
        console.log(error);
        setLoading(false);
      }
    }
    if (path.path == 1) {
      setLoading(true);
      try {
        API.Auth.VerifyViaMail({
          data: {
            email: Email,
            otp: newotp,
            role: "Wellnessclient",
          },
          tokenRequired: AuthToken,
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            setApiError(response?.data?.message);
            localStorage.setItem(
              "AuthTokenWelnesscoach",
              response?.data?.token
            );
            localStorage.setItem(
              "WellnessCoachData",
              JSON.stringify(response?.data)
            );
            setShowPopUp(true);
            setTimeout(() => {
              setShowPopUp(false);
              navigate(`../${AdminRoute?.Common?.Dashboard}`);
            }, 2000);
          } else {
            setApiError(response?.data?.message);
            // toast.error(response?.data?.message);
          }
          setLoading(false);
        });
      } catch (error) {
        setApiError(error?.message);
        console.log(error);
        setLoading(false);
      }
    }
  };

  const handleResendOtp = (e) => {
    e.preventDefault();
    setApiError("");
    setIsSubmit(true);
    setLoading(true);
    try {
      API.Auth.LoginAPIEmail({
        data: {
          email: Email,
          role: "Wellnessclient",
          resend: "true",
        },
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          setApiError(response?.data?.message);
          // toast.success("Resent OTP");
        } else {
          setApiError(response?.data?.message);
        }
        setLoading(false);
      });
    } catch (error) {
      setApiError(error?.message);
      console.log(error);
      setLoading(false);
    }
  };

  // =====Email ID hide======

  const maskEmail = (email) => {
    const [username, domain] = email.split("@");
    const usernameLength = username.length;
    const maskedUsername =
      username.charAt(0) +
      "*".repeat(usernameLength - 2) +
      username.charAt(usernameLength - 1);
    return maskedUsername + "@" + domain;
  };

  useEffect(() => {
    maskEmail(Email);
  }, []);

  return (
    <>
      {showPopup ? (
        <SuccessPop
          text={
            path.path === 1 ? "Login Successful" : "Registration Successful"
          }
        />
      ) : (
        <div className="Otp">
          <div className="container login-page">
            <div className="row main-container">
              <div className="col-md-6 col-sm-12 left-text">
                <div className="logo-div">
                  <NavLink to="/">
                    <img src="/images/logo.png" className="logo" alt="logo" />{" "}
                  </NavLink>
                </div>
                <h1 className="heading">Reclaim control over your</h1>
                <p className="subheading">Mental Health journey</p>
                <p>
                  through our online therapy programs, guided by our
                  compassionate team of psychologists.
                </p>
              </div>
              {/* col-md-6 */}

              <div className="col-md-6 col-sm-12">
                <div className="otp-div">
                  <h2 className="heading">Verify via E-mail OTP</h2>
                  <p>
                    <span className="text-danger fw-bold">*</span> Enter 4 digit
                    OTP sent on the registered{" "}
                    <b> &quot;{maskEmail(Email)}&quot; </b> Email ID
                  </p>
                  {/* {inType === "email" ? (
                <p>
                  * Enter 4 digit OTP sent on the registered &quot;{mobile}&quot; Email
                  ID
                </p>
              ) : (
                <p>
                  * Enter 4 digit OTP sent on the registered &quot;{mobile}&quot;
                  mobile no.
                </p>
              )} */}
                  {/* <p>* Enter 4 digit OTP sent on the registered “8XXXXXXXO mobile no.</p> */}
                  {/* <div className="otp-container text-center">
              <input type="text" maxLength="1" className="otp-input" value="" />
              
            </div> */}
                  <div
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        value={digit}
                        id={`otp-${index}`}
                        name={`otp-${index}`}
                        onChange={(event) => handleChange(event, index)}
                        maxLength={1}
                        ref={inputRefs[index]}
                        className="form-control custom-input-text-primary otp-input mb-0"
                      />
                    ))}
                  </div>
                  <p className="text-danger">{ApiError}</p>{" "}
                  <button
                    className="button_common"
                    disabled={isSubmit}
                    onClick={(e) => handleVerifyOtp(e)}
                  >
                    Verify and Proceed
                  </button>
                  {loading ? <div className="loader_orange"></div> : <></>}
                  {/* {counter === 0 ? (
                <p
                  className="orange-small-link text-center cursor-pointer text-decoration-underline"
                  // data-bs-target="#otpResentModal"
                  // data-bs-toggle="modal"
                  onClick={(e) => handleResendOtp(e)}
                >
                  Resend OTP
                </p>
              ) : (
                <h3 className="text-center text-color-secondary">
                  {counter == 30
                    ? `00:30`
                    : counter >= 10
                    ? `00:${counter}`
                    : `00:0${counter}`}
                </h3>
              )} */}
                  <p
                    className="orange-small-link text-center text-decoration-underline cursor_pointer resend-otp"
                    onClick={(e) => handleResendOtp(e)}
                  >
                    Resend OTP
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyOtpMail;
