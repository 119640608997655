// import { io } from "socket.io-client";

// // "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.REACT_APP_BASE_URL_SOCKET;
// // for production
// export const socket = io(URL, {
//   // autoConnect: false,
//   path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
// });

import { io } from "socket.io-client";

// Define the backend server URL
// const URL = "http://localhost:4210";
// const URL = process.env.REACT_APP_BASE_URL_SOCKET;
// const pathValue = process.env.WDS_SOCKET_HOST;


// Create a Socket.IO connection
export const socket = io(process.env.REACT_APP_BASE_URL_SOCKET, {
  path: `/socket.io`, // This matches the path used in your backend
});

// Example event listeners
socket.on("connect", () => {
  console.log("Connected to Socket.IO server");
});

socket.on("disconnect", () => {
  console.log("Disconnected from Socket.IO server");
});

// Custom event listener example
socket.on("chatmessage", (data) => {
  console.log("Received chat message:", data);
});
