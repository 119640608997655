// import Page from "../Components/Page";
import React from "react";
import RouteName from "./RouteDetails";
import Login from "./../Components/Auth/Login";
import VerifyOtp from "../Components/Auth/VerifyOtp";
import Register from "../Components/Auth/Register";
import Register2 from "../Components/Auth/Register2";
import MyChecklist from "../Components/MyChecklist/MyChecklist";
import Aitherapist from "../Components/AITherapist/Aitherapist";
import Group from "../Components/AfterSignin/Group";
import Plan from "../Components/AfterSignin/Plan";
import Theam from "../Components/AfterSignin/Theam";
import Therapist from "../Components/AfterSignin/Therapist";
import Appointment_scheduler from "../Components/AfterSignin/Appointment_scheduler";
import DisplayCalender from "../Components/AfterSignin/DisplayCalender";
import Join_Group from "../Components/AfterSignin/Join_Group";
import Dashboard from "../Components/Dashboard/Dashboard";
import DigitalNotes from "../Components/DigitalNotes/DigitalNotes";
import CreateNotes from "../Components/DigitalNotes/CreateNotes";
import Employee from "../Components/Employee/Employee";
import EmployeeDetails from "../Components/Employee/EmployeeDetails";
import MySession from "../Components/MySession/MySession";
import SessionDetails from "../Components/MySession/SessionDetails";
import JoinGroup from "../Components/Groups/JoinGroup";
import Groupchat from "../Components/Groups/Groupchat";
import JoinGroupsession from "../Components/Groups/JoinGroupsession";
import Playground from "../Components/PlayGround/Playground";
import Profile from "../Components/Dashboard/Profile";
import MyReferrals from "../Components/Dashboard/MyReferrals";
import Payment from "../Components/Dashboard/Payment";
import Settings from "../Components/Dashboard/Settings";
import UserProfile from "../Components/Dashboard/UserProfile";
import VerifyOtpMail from "../Components/Auth/VerifyOtpMail";
import ProtectedRoute from "./ProtectedRoute";
import PersonalityTests from "../Components/Dashboard/PersonalityTests";
import WllbeingTest from "../Components/Dashboard/WllbeingTest";
import LineChart from "../Components/Dashboard/LineChart";
import MyTherapist from "../Components/MyTherapist/MyTherapist";
import MakePayment from "../Components/Common/modal/MakePayment";
import VideoCall from "../Components/VideoCall/VideoCall";
import MyOrg from "../Components/Auth/Myorg";
import Profileedit from "../Components/Dashboard/Profileedit";
import Myorglist from "../Components/Myorg/Myorglist";
import MembersSelection from "../Components/Groups/MembersSelection";
import AddEditSessionDetails from "../Components/MySession/AddEditSessionDetails";
// import Registration1 from "../Components/Auth/Registration1";
// import Registration2 from "../Components/Auth/Registration2";
//routes
const AdminRoute = [
  {
    path: RouteName.Auth.Login,
    element: <Login />,
    exact: true,
  },
  {
    path: `${RouteName.Auth.VerifyOtp}/:path`,
    // 1: login
    // 2: registration
    element: <VerifyOtp />,
    exact: true,
  },

  {
    path: `${RouteName.Auth.verifyOtpMail}/:path`,
    element: <VerifyOtpMail />,
    exact: true,
  },

  {
    path: RouteName.Auth.Register,
    element: <Register />,
    exact: true,
  },

  {
    path: RouteName.Auth.Register2,
    element: <Register2 />,
    exact: true,
  },

  // {
  //   path: RouteName.Auth.MyOrg,
  //   element: <MyOrg />,
  //   exact: true,
  // },
  {
    path: RouteName.Common.MyChecklist,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <MyChecklist />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.Aitherapist,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Aitherapist />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.Group,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Group />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.Plan,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Plan />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.Theam,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Theam />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.Therapist,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Therapist />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Common.Appointment_scheduler}/:path`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Appointment_scheduler />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.DisplayCalender,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <DisplayCalender />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.Join_Group,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Join_Group />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.Dashboard,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Dashboard />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.DigitalNotes,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <DigitalNotes />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.CreateNotes,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <CreateNotes />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Common.Employee}/:isLead`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Employee />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Common.EmployeeDetails}/:EmpId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <EmployeeDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.MySession,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <MySession />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Common.AddEditSessionDetails}/:SessionId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <AddEditSessionDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.SessionDetails,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <SessionDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.Groupchat,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Groupchat />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.JoinGroup,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <JoinGroup />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.JoinGroupsession,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <JoinGroupsession />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.Playground,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Playground />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.Profile,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Profile />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.MyReferrals,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <MyReferrals />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.Payment,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Payment />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.Settings,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Settings />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.UserProfile,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <UserProfile />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.PersonalityTests,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <PersonalityTests />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.WllbeingTest,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <WllbeingTest />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Common.MembersSelection}/:GroupId/:orgname`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <MembersSelection />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.LineChart,
    element: <LineChart />,
    exact: true,
  },
  {
    path: RouteName.Common.MyTherapist,
    element: <MyTherapist />,
    exact: true,
  },
  {
    path: `${RouteName.Common.MakePayment}/:FeatureName`,
    element: <MakePayment />,
    exact: true,
  },
  {
    path: `${RouteName.Common.VideoCall}/:SessionId`,
    element: <VideoCall />,
    exact: true,
  },
  {
    path: `${RouteName.Common.Profileedit}`,
    element: <Profileedit />,
    exact: true,
  },

  {
    path: `${RouteName.Common.Myorglist}`,
    element: <Myorglist />,
    exact: true,
  },

  /////////////////////////////////////////
  //   {
  //     path: RouteName.Library.PrimaryLibrary,
  //     element: (
  //       <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
  //         <LibraryPrimary />
  //       </ProtectedRoute>
  //     ),
  //     exact: true,
  //   },

  // {
  //   path: `${RouteName.Library.SubFolder}/:mainfoldername/:mainfolderid`,
  //   element: (
  //     <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
  //       <SubFolder />
  //     </ProtectedRoute>
  //   ),
  //   exact: true,
  // },
];

export default AdminRoute;
