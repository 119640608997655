import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";


export default function WellBeingtable(props) {
  const {
    allResourceData,
    setAllResourceData,
    selectedAnswers,
    setSelectedAnswers,
    loadingList
  } = props;
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));

  const handleAnswer = (e, ele, eleIndex, questionIndex, questionId, answerText) => {
    if (e.target.checked) {
      setSelectedAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: answerText,
      }));
    } else {
      setSelectedAnswers(delete selectedAnswers[questionId])
    }


    // get selected key
    let key1 = Object.keys(allResourceData.subsurvay[eleIndex].answers[questionIndex])[0];
    setAllResourceData({
      ...allResourceData, // Copy the original object
      subsurvay: allResourceData.subsurvay.map((question, index) => {
        if (eleIndex === index) {
          return {
            ...question, // Copy the original question object
            answers: question.answers.map((answer, i) => {
              const key = Object.keys(answer)[0];
              answer[key] = false; // set all false
              // Check a condition before updating the specific property
              if (i === questionIndex) {
                return {
                  ...answer,
                  [key1]: e.target.checked, // Update to the desired value
                };
              } else {
                return answer // if not change return all same
              }
            }),
            created_by: UserData?.userdata?._id
          };
        }
        return question;
      }),
    });
  }


  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th className="f-th b-0 b-l">
              During the past month,how often did you feel...
            </th>
            <th className="b-l">Never</th>
            <th>Once or twice </th>
            <th>About once a week</th>
            <th>About 2 or 3 times a week</th>
            <th>Almost Everyday</th>
            <th>Everyday</th>
          </tr>
        </thead>
        <tbody>
          {loadingList ?
            <div className="container_loader"></div>
            :
            allResourceData.length === 0 ?
              <div className="alert alert-warning" role="alert">
                Data Not Found
              </div>
              : allResourceData &&
              allResourceData?.subsurvay?.map((ele, index) => {
                return (
                  <tr key={index}>
                    <td className="bold f-th b-0 b-l">{index + 1}. {ele.question}</td>
                    {ele.answers.map((val, i) => {
                      const isChecked = selectedAnswers[ele._id] === Object.keys(val)[0];
                      return (
                        <td className="custom-control custom-checkbox-table b-l">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck1"
                            name={Object.keys(val)[0]}
                            checked={isChecked}
                            onChange={(e) => handleAnswer(e, ele, index, i, ele._id, Object.keys(val)[0])}
                          />
                        </td>
                      )
                    })
                    }
                  </tr>
                );
              })}
        </tbody>
      </Table>
    </>
  );
}
