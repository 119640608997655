import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import { useNavigate, useParams } from "react-router-dom";
import DefaultImage from "../../Assets/Images/icons/Icon awesome-user-circle.png";

const MembersSelection = () => {
  // const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const [ClientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const [SkipbuttonStatus, setSkipbuttonStatus] = useState(true);
  const [NextbuttonStatus, setNextbuttonStatus] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   try {
  //     API.EmployeeTab.ActiveEmployee({ isAuthorised: "1" }).then((response) => {
  //       // console.log("ClientActiveList", response?.data?.data_all);
  //       setClientList(response?.data?.data_all);
  //       setLoading(false);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // }, []);

  useEffect(() => {
    setLoading(true);
    try {
      API.Group.SelectGroupMemberList({ orgname: atob(params.orgname) }).then(
        (response) => {
          // console.log("ClientActiveList", response?.data);
          setClientList(response?.data?.data);
          setLoading(false);
        }
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);
  

  const selectMembersGroup = (e, value) => {
    // console.log("=======>", e.target.checked);

    if (e.target.checked === true) {
      try {
        API.Group.SelectGroupMembers({
          data: {
            userId: value?._id,
            groupId: params?.GroupId,
            userName: value?.fullname
          },
        }).then((response) => {
          // console.log(response?.data);
          setSkipbuttonStatus(false);
          setNextbuttonStatus(true);
        });
      } catch (error) {
        console.log(error);
      }
    }
    //    else if (e.target.checked === false) {
    //     try {
    //       API.Group.DeSelectGroupMembers({
    //         data: {
    //           userId: value?.client[0]?._id,
    //           groupId: params?.GroupId,
    //         },
    //       }).then((response) => {
    //         console.log(response?.data);
    //       });
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
  };

  //jsx
  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <div className="w-100 h-head">
                  <h2 className="heading  mt-1 mb-1">Members Selection</h2>
                  <Button
                    type="submit"
                    className="create-new_notes button_common notes-btn transparent-btn"
                    onClick={() =>
                      navigate(`../${AdminRoute?.Common.Groupchat}`)
                    }
                  >
                    Next
                  </Button>
                  <Button
                    type="submit"
                    className="create-new_notes button_common notes-btn transparent-btn"
                    onClick={() =>
                      navigate(`../${AdminRoute?.Common.Groupchat}`)
                    }
                    // disabled={SkipbuttonStatus}
                  >
                    Skip
                  </Button>
                </div>
                <Header />
              </nav>
            </div>

            <div className="col-12">
              <div className="row">
                <div className="col-12 people-list">
                  <div className="search position-relative">
                    <input
                      type="text"
                      placeholder="Search"
                      // onChange={(e) => handleSearchText(e.target.value)}
                    />
                    <i className="fa fa-search position-absolute"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-2 list-item member-selection-v-height">
              <div className="sessions-tabs mt-2 my-checklist-wrap">
                <div className="employee-list">
                  <div className="row mt-2 gx-3">
                    {loading ? (
                      <div className="loader-wrap">
                        <div className="loader_orange"></div>
                      </div>
                    ) : ClientList?.length === 0 ? (
                      <div className="alert alert-warning my-3" role="alert">
                        Data not found
                      </div>
                    ) : (
                      ClientList?.length &&
                      ClientList.map((val, i) => {
                        return (
                          <>
                            {" "}
                            <div className="col-12 col-md-4" key={i}>
                              <div className="member-selection-card cursor-pointer p-3 d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <p className="group-member-icon d-flex justify-content-center align-items-center">
                                    <img
                                      className="member-icon"
                                      src={DefaultImage}
                                      alt="avatar"
                                    />
                                  </p>
                                  <p className="group-member-name ms-3">
                                    {val?.fullname}
                                  </p>
                                </div>
                                <div>
                                  <label className="check_container position-relative">
                                    <input
                                      type="checkbox"
                                      name={val?.fullname}
                                      value={val?._id}
                                      onChange={(e) =>
                                        selectMembersGroup(e, val)
                                      }
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MembersSelection;
